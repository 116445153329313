import httpRequest from "../util/httpRequest"

export default class AdminDao {

    static dashboardData = async() =>{
        return new Promise(
            await httpRequest.set('v1/admin/dashboardData', 'GET')
        )
    }

    static login = async (body) => {
        return new Promise(
            await httpRequest.set('v1/admin/login', 'POST', JSON.stringify(body))
        )
    }

    static getAll = async () => {
        return new Promise(
            await httpRequest.set('v1/admins', 'GET')
        )
    }

    static getById = async (id) => {
        return new Promise(
            await httpRequest.set(`v1/admin/${id}`, 'GET')
        )
    }

    static logs = async () => {
        return new Promise(
            await httpRequest.set('v1/admins/logs', 'GET')
        )
    }

    static create = async (admin) => {
        return new Promise(
            await httpRequest.set('v1/admin/create', 'POST', JSON.stringify(admin))
        )
    }

    static deleteAdmin = async (adminId) => {
        return new Promise(
            await httpRequest.set(`v1/admin/${adminId}/disable`, 'POST')
        )
    }

    static update = async (adminId, admin) => {
        return new Promise(
            await httpRequest.set(`v1/admin/${adminId}`, 'PUT', JSON.stringify(admin))
        )
    }

    static changeOtherAdminPass = async (id, body) => {
        return new Promise(
            await httpRequest.set(`/v1/admin/${id}/change_password`, 'PUT', JSON.stringify(body))
        )
    }
}
