import AppContainer from "../reusables/AppContainer";
import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Select, {components} from "react-select";
import {FaAngleDown, FaCaretDown, FaCaretUp, FaFilter, FaSearch} from "react-icons/fa";
import Palette from "../Palette";
import {AiOutlineClose} from "react-icons/ai";
import {MdSort} from "react-icons/md";
import InputBase from "@material-ui/core/InputBase";
import {Table} from "react-bootstrap";
import Button from "@material-ui/core/Button";
import styles from "../styles";
import Form from "react-bootstrap/Form";
import EditRateModal from "../reusables/EditRateModal";
import DateRangePicker from "../reusables/DateRangePicker";
import OrderCard from "../reusables/OrderCard";
import ReceiptModal from "../reusables/ReceiptModal";
import ComplaintModal from "../reusables/ComplaintModal";
import CurrencyDAO from "../daos/CurrencyDAO";
import OrderDAO from "../daos/OrderDAO";
import util from "../util/util";
import ORDER_STEPS from "../assets/values/ORDER_STEPS";
import _ from "lodash";
import moment from "moment";
import ConfirmationModal from "../reusables/ConfirmationModal";
import DefaultTextInput from "../reusables/DefaultTextInput";

export default function OrdersPage(props) {
    const [filteredRange, setFilteredRange] = useState({});
    const [isAmountRangeCustom, setAmountRangeCustom] = useState(false);
    const [isOrderApiInvoked, setOrderApiInvoked] = useState(false);

    const amountRangeOptions = [
        {
            label: '< 1000',
            value: {max: 999}
        },
        {
            label: '1000 - 2000',
            value: {min: 1000, max: 1999}
        },
        {
            label: '> 2000',
            value: {min: 2000}
        },
        {
            label: 'Custom',
            value: {}
        }
    ]


    const [orderIdOptions, setOrderIdOptions] = useState([]);
    const [orderPurposeOptions, setOrderPurposeOptions] = useState([]);
    const [senderOptions, setSenderOptions] = useState([]);
    const [recipientOptions, setRecipientOptions] = useState([]);

    const [isReceiptModalShown, setReceiptModalShown] = useState(false);

    const [selectedOrderCurrentlyEdited, setSelectedOrderCurrentlyEdited] = useState({});

    const [selectedOrder, setSelectedOrder] = useState({})

    const [unconfirmedOrders, setUnconfirmedOrders] = useState([])
    const [needConfirmationOrders, setNeedConfirmationOrders] = useState([])
    const [processingOrders, setProcessingOrders] = useState([])
    const [isConfirmationModalShown, setConfirmationModalShown] = useState(false);
    const [filters, setFilters] = useState([]);

    const tableStyles = {
        td: {
            borderWidth: 0,
            padding: 0,
            paddingLeft: 20,
            verticalAlign: 'center',
            wordWrap: "break-word"
        }
    };

    useEffect(() => {
        filters.map(filter => {
            if (filter.type === 'Amount Range' && filter.value.label === 'Custom') {
                setAmountRangeCustom(true)
            }
        })

        if (!isOrderApiInvoked) getUnconfirmedOrders()
    }, [filters])

    const getUnconfirmedOrders = async () => {
        setOrderApiInvoked(true);

        try {
            let result = await OrderDAO.getAllUnconfirmed();

            const orderTemp = [];
            const orderIdTemp = [];
            const orderPurposeTemp = [];
            const senderTemp = [];
            const recipientTemp = [];
            result.map(order => {
                if (orderIdTemp.length === 0) orderIdTemp.push({
                    value: order.order_number,
                    label: order.order_number
                });
                else {
                    let found = false;

                    orderIdTemp.map(orderId => {
                        if (JSON.stringify(orderId) === JSON.stringify({
                            value: order.order_number,
                            label: order.order_number
                        })) found = true;
                    })

                    if (!found) orderIdTemp.push({value: order.order_number, label: order.order_number});
                }

                if (order.order_purpose) {
                    if (orderPurposeTemp.length === 0) orderPurposeTemp.push({
                        value: order.order_purpose,
                        label: order.order_purpose
                    });
                    else {
                        let found = false;

                        orderPurposeTemp.map(orderPurpose => {
                            if (JSON.stringify(orderPurpose) === JSON.stringify({
                                value: order.order_purpose,
                                label: order.order_purpose
                            })) found = true;
                        })

                        if (!found) orderPurposeTemp.push({value: order.order_purpose, label: order.order_purpose});
                    }
                }

                if (senderTemp.length === 0) senderTemp.push({
                    value: order.customer.primary_name,
                    label: order.customer.primary_name
                });
                else {
                    let found = false;

                    senderTemp.map(sender => {
                        if (JSON.stringify(sender) === JSON.stringify({
                            value: order.customer.primary_name,
                            label: order.customer.primary_name
                        })) found = true;
                    })

                    if (!found) senderTemp.push({
                        value: order.customer.primary_name,
                        label: order.customer.primary_name
                    });
                }

                if (recipientTemp.length === 0) recipientTemp.push({
                    value: order.recipient.name,
                    label: order.recipient.name
                });
                else {
                    let found = false;

                    recipientTemp.map(recipient => {
                        if (JSON.stringify(recipient) === JSON.stringify({
                            value: order.recipient.name,
                            label: order.recipient.name
                        })) found = true;
                    })

                    if (!found) recipientTemp.push({value: order.recipient.name, label: order.recipient.name});
                }

                orderTemp.push({
                    ...order,
                    recipient_name: order.recipient.name
                })
            })

            setOrderIdOptions(orderIdTemp)
            setOrderPurposeOptions(orderPurposeTemp)
            setRecipientOptions(recipientTemp);
            setSenderOptions(senderTemp);
            setUnconfirmedOrders(orderTemp)
        } catch (e) {
            console.log(e)
        }

    }

    const getAllNeedConfirmation = async () => {
        try {
            const orderIdTemp = [];
            const recipientTemp = [];
            const orderTemp = [];


            let result = await OrderDAO.getAllNeedConfirmation();

            result.map(order => {
                if (orderIdTemp.length === 0) orderIdTemp.push({
                    value: order.order_number,
                    label: order.order_number
                });
                else {
                    let found = false;

                    orderIdTemp.map(orderId => {
                        if (JSON.stringify(orderId) === JSON.stringify({
                            value: order.order_number,
                            label: order.order_number
                        })) found = true;
                    })

                    if (!found) orderIdTemp.push({value: order.order_number, label: order.order_number});
                }

                if (recipientTemp.length === 0) recipientTemp.push({
                    value: order.recipient.name,
                    label: order.recipient.name
                });
                else {
                    let found = false;

                    recipientTemp.map(recipient => {
                        if (JSON.stringify(recipient) === JSON.stringify({
                            value: order.recipient.name,
                            label: order.recipient.name
                        })) found = true;
                    })

                    if (!found) recipientTemp.push({value: order.recipient.name, label: order.recipient.name});
                }

                orderTemp.push({
                    ...order,
                    sender_name: order.customer.primary_name,
                    recipient_name: order.recipient.name
                })
            })

            setOrderIdOptions(orderIdTemp)
            setRecipientOptions(recipientTemp);
            setNeedConfirmationOrders(orderTemp)
        } catch (e) {
            console.log(e)
        }

    }

    const getAllProcessing = async () => {
        const orderIdTemp = [];
        const recipientTemp = [];
        const orderTemp = [];

        try {
            let result = await OrderDAO.getAllProcessing();

            result.map(order => {
                if (orderIdTemp.length === 0) orderIdTemp.push({
                    value: order.order_number,
                    label: order.order_number
                });
                else {
                    let found = false;

                    orderIdTemp.map(orderId => {
                        if (JSON.stringify(orderId) === JSON.stringify({
                            value: order.order_number,
                            label: order.order_number
                        })) found = true;
                    })

                    if (!found) orderIdTemp.push({value: order.order_number, label: order.order_number});
                }

                if (recipientTemp.length === 0) recipientTemp.push({
                    value: order.recipient.name,
                    label: order.recipient.name
                });
                else {
                    let found = false;

                    recipientTemp.map(recipient => {
                        if (JSON.stringify(recipient) === JSON.stringify({
                            value: order.recipient.name,
                            label: order.recipient.name
                        })) found = true;
                    })

                    if (!found) recipientTemp.push({value: order.recipient.name, label: order.recipient.name});
                }

                orderTemp.push({
                    ...order,
                    sender_name: order.customer.primary_name,
                    recipient_name: order.recipient.name
                })
            })

            setOrderIdOptions(orderIdTemp)
            setRecipientOptions(recipientTemp);
            setProcessingOrders(orderTemp)
        } catch (e) {
            console.log(e)
        }

    }

    const rejectOrder = async (orderNumber) => {
        try {
            let result = null;

            if (selectedMenu === MENU.PAYMENT_CONFIRMATION) {
                try {
                    result = await OrderDAO.rejectPayment(orderNumber)

                    console.log(result)
                } catch (e) {
                    alert('An error occurred')
                }
            } else {
                try {
                    result = await OrderDAO.rejectOrder(orderNumber);

                    console.log(result)
                } catch (e) {
                    alert('An error occurred')
                }
            }

            if (selectedMenu === MENU.UNCONFIRMED_ORDERS) getUnconfirmedOrders()
            else if (selectedMenu === MENU.PAYMENT_CONFIRMATION) getAllNeedConfirmation()
            else if (selectedMenu === MENU.PROCESSING_ORDER) getAllProcessing();
        } catch (e) {
            console.log(e)
        }
    }


    const acceptOrder = async (orderNumber) => {
        try {
            let result = await OrderDAO.acceptOrder(orderNumber);
            getUnconfirmedOrders()
        } catch (e) {
            console.log(e)
        }
    }

    const MENU = {
        UNCONFIRMED_ORDERS: 'CURRENT_ORDERS',
        PAYMENT_CONFIRMATION: 'PAYMENT_CONFIRMATION',
        PROCESSING_ORDER: 'PROCESSING_ORDER'
    };

    const DropdownIndicator = props => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <FaAngleDown color={'black'}/>
                </components.DropdownIndicator>
            )
        );
    };

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isFilterExpanded, setFilterExpanded] = useState(false)
    const [hoveredMenu, setMenuHovered] = useState(null);
    const [selectedMenu, setSelectedMenu] = useState(MENU.UNCONFIRMED_ORDERS);
    const [isDateRangeHovered, setDateRangeHovered] = useState(false);
    const [isDateRangePickerShown, setDateRangePickerShown] = useState(false);
    const [isEditRateModalShown, setEditRateModalShown] = useState(false);

    const deleteFilter = (filter) => {
        let deletedIdx = -1;

        filters.map((aFilter, idx) => {
            if (aFilter === filter) {
                if (aFilter.type === 'Amount Range') setAmountRangeCustom(false)
                deletedIdx = idx;
            }
        })

        const filtersTemp = [...filters];

        filtersTemp.splice(deletedIdx, 1);

        setFilters(filtersTemp);
    }

    const addFilter = (filter) => {
        let found = false;

        filters.map((aFilter, idx) => {
            console.log(aFilter)

            if (aFilter.type === filter.type && (aFilter.value === filter.value || filter.value.label === 'Custom')) {
                if (filter.value.label !== 'Custom') {
                    const filtersTemp = [...filters];
                    filtersTemp[idx] = filter;
                    setFilters(filtersTemp)
                }

                found = true;
            }
        })

        if (!found) {
            const filtersTemp = [...filters];
            filtersTemp.push(filter);
            setFilters(filtersTemp);
        }
    }

    const [searchKeyword, setSearchKeyword] = useState('');

    const filterUnconfirmedOrders = (datum, key) => {
        if (!datum.details.toLowerCase().includes(searchKeyword.toLowerCase())) return;

        console.log(datum)

        const unconfirmedOrderRow = (
            <tr key={key}>
                <td style={{
                    border: '1px solid #cccccc',
                    backgroundColor: '#fcfcfc'
                }}>
                    <div>
                        <table style={{color: 'grey'}}>
                            <tr>
                                <td style={tableStyles.td}>ID:</td>
                                <td style={tableStyles.td}>{datum.order_number}</td>
                            </tr>

                            <tr>
                                <td style={tableStyles.td}>Date:</td>
                                <td style={tableStyles.td}>{moment(datum.created_at).format('DD/MM/YY HH:mm')}</td>
                            </tr>

                            <tr>
                                <td style={tableStyles.td}>Type:</td>
                                <td style={tableStyles.td}>{datum.type}</td>
                            </tr>

                            <tr>
                                <td style={tableStyles.td}>Purpose:</td>
                                <td style={tableStyles.td}>{datum.order_purpose ? datum.order_purpose : "-"}</td>
                            </tr>

                            <tr>
                                <td style={tableStyles.td}>Details:</td>
                                <td style={tableStyles.td}>{datum.details ? datum.details : "-"}</td>
                            </tr>
                        </table>
                    </div>
                </td>

                <td style={{
                    border: '1px solid #cccccc',
                    backgroundColor: '#fcfcfc',
                }}>
                    <div>
                        <table style={{color: 'grey', tableLayout : "fixed", maxWidth : "250px"}}>
                            <tr>
                                <td style={tableStyles.td}>Name:</td>
                                <td style={tableStyles.td}>{datum.customer.primary_name}</td>
                            </tr>

                            <tr>
                                <td style={tableStyles.td}>Phone number:</td>
                                <td style={tableStyles.td}>{datum.customer.phone_number}</td>
                            </tr>

                            <tr>
                                <td style={tableStyles.td}>Email:</td>
                                <td style={tableStyles.td}>{datum.customer.email}</td>
                            </tr>

                            <tr>
                                <td style={tableStyles.td}>CIF number:</td>
                                <td style={tableStyles.td}>{datum.customer.cif_number}</td>
                            </tr>
                        </table>
                    </div>
                </td>

                <td style={{
                    border: '1px solid #cccccc',
                    backgroundColor: '#fcfcfc'
                }}>
                    <div>
                        <table style={{color: 'grey'}}>
                            <tr>
                                <td style={tableStyles.td}>Name:</td>
                                <td style={tableStyles.td}>{datum.recipient.name}</td>
                            </tr>

                            <tr>
                                <td style={tableStyles.td}>Phone number:</td>
                                <td style={tableStyles.td}>{datum.recipient.telephone_number}</td>
                            </tr>

                            <tr>
                                <td style={tableStyles.td}>Email:</td>
                                <td style={tableStyles.td}>{datum.recipient.email ? datum.recipient.email : "-"}</td>
                            </tr>

                            <tr>
                                <td style={tableStyles.td}>Received:</td>
                                <td style={tableStyles.td}>{datum.destination_currency} {util.thousandSeparator(datum.destination_amount)}</td>
                            </tr>

                            <tr>
                                <td style={tableStyles.td}>Bank name:</td>
                                <td style={tableStyles.td}>{datum.recipient.bank_name ? datum.recipient.bank_name : "-"}</td>
                            </tr>

                            <tr>
                                <td style={tableStyles.td}>Bank branch:</td>
                                <td style={tableStyles.td}>{datum.recipient.bank_branch_name ? datum.recipient.bank_branch_name : "-"}</td>
                            </tr>

                            <tr>
                                <td style={tableStyles.td}>Swift code:</td>
                                <td style={tableStyles.td}>{datum.recipient.swift_code ? datum.recipient.swift_code : "-"}</td>
                            </tr>
                        </table>
                    </div>
                </td>

                <td style={{
                    border: '1px solid #cccccc',
                    backgroundColor: '#fcfcfc'
                }}>
                    <div>
                        <table style={{color: 'grey'}}>
                            <tr>
                                <td style={tableStyles.td}>Transfer Amount:</td>
                                <td style={tableStyles.td}>{datum.source_currency} {util.thousandSeparator(datum.transfer_amount)}</td>
                            </tr>

                            <tr>
                                <td style={tableStyles.td}>Exchange Rate:</td>
                                <td style={tableStyles.td}>{datum.destination_currency} 1
                                    = {datum.source_currency} {util.thousandSeparator(1 / datum.currency_rate) !== "0" ? util.thousandSeparator(1 / datum.currency_rate) : 1 / datum.currency_rate}</td>
                            </tr>

                            <tr>
                                <td style={tableStyles.td}>Service Fee:</td>
                                <td style={tableStyles.td}>{datum.source_currency} {util.thousandSeparator(datum.rupiah_fee)}</td>
                            </tr>

                            <tr>
                                <td style={tableStyles.td}>Payment:</td>
                                <td style={tableStyles.td}>{datum.source_currency} {util.thousandSeparator(datum.source_amount)}</td>
                            </tr>
                        </table>
                    </div>
                </td>

                <td style={{
                    borderWidth: 0,
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <Button
                        onClick={() => {
                            acceptOrder(datum.order_number)
                        }}
                        style={{...styles.button}}>Approve</Button>
                    <Button
                        onClick={() => {
                            setSelectedOrder(datum)
                            setConfirmationModalShown(true)
                        }}
                        style={{
                            ...styles.button,
                            backgroundColor: 'white',
                            color: Palette.COLOR_PRIMARY,
                            border: '1px #dbdbdb solid',
                            marginTop: 10
                        }}>Reject</Button>
                    <Button
                        onClick={() => {
                            setSelectedOrderCurrentlyEdited(datum)
                            setEditRateModalShown(true)
                        }}
                        style={{
                            ...styles.button,
                            backgroundColor: 'white',
                            color: Palette.COLOR_PRIMARY,
                            border: '1px #dbdbdb solid',
                            marginTop: 10
                        }}>Edit Rate</Button>
                </td>
            </tr>
        )

        if (filters.length !== 0) {
            let show = true;
            let filtersResult = {};

            for (let idx = 0; idx < filters.length; idx++) {
                const filter = filters[idx];

                if (filtersResult[filter.type]) {
                    filtersResult[filter.type].filters.push(filter.value)
                } else {
                    filtersResult = {
                        ...filtersResult,
                        [filter.type]: {
                            filters: [filter.value],
                            found: false
                        }
                    }
                }

                // if (filterType === 'Date' && !moment(datum.created_at).isBetween(filter.value.startDate, filter.value.endDate, 'date', '[]')) {
                //     show = false;
                //     break;
                // } else if (filterType === 'Sender' && filter.value !== datum.sender_name) {
                //     show = false;
                //     break;
                // } else if (filterType === 'Recipient' && filter.value !== datum.recipient_name) {
                //     show = false;
                //     break;
                // } else if (filterType === 'Order ID' && filter.value !== datum.order_number) {
                //     show = false;
                //     break;
                // } else if (filterType === 'Order Purpose' && filter.value !== datum.order_purpose) {
                //     show = false;
                //     break;
                // } else if (filterType === 'Amount Range') {
                //     if (filter.value.value.min && (datum.transfer_amount < filter.value.value.min)) show = false
                //
                //     if (filter.value.value.max && (datum.transfer_amount > filter.value.value.max)) show = false
                //
                //     break;
                // }
            }

            Object.keys(filtersResult).map(key => {
                filtersResult[key].filters.map(filter => {
                    if (key === 'Date' && moment(datum.created_at).isBetween(filter.startDate, filter.endDate, 'date', '[]')) {
                        filtersResult[key].found = true
                    } else if (key === 'Sender' && filter === datum.customer.primary_name) {
                        filtersResult[key].found = true
                    } else if (key === 'Recipient' && filter === datum.recipient.name) {
                        filtersResult[key].found = true
                    } else if (key === 'Order ID' && filter === datum.order_number) {
                        filtersResult[key].found = true
                    } else if (key === 'Order Purpose' && filter === datum.order_purpose) {
                        filtersResult[key].found = true
                    } else if (key === 'Amount Range') {
                        if (!filter.value.min && filter.value.max && datum.destination_amount <= filter.value.max) {
                            filtersResult[key].found = true
                        } else if (filter.value.min && !filter.value.max && filter.value.min <= datum.destination_amount) {
                            filtersResult[key].found = true
                        } else if (filter.value.min <= datum.destination_amount && datum.destination_amount <= filter.value.max) {
                            filtersResult[key].found = true
                        }
                    }
                })
            })

            Object.keys(filtersResult).map(key => {
                if (!filtersResult[key].found) show = false
            })


            if (show) return unconfirmedOrderRow
        } else return unconfirmedOrderRow;
    }

    const sorterFields = [
        {
            key: "created_at",
            label: "Order Date"
        },
        {
            key: "sender_name",
            label: "Sender Name"
        },
        {
            key: "recipient_name",
            label: "Recipient Name"
        },
        {
            key: "transfer_amount",
            label: "Transfer Amount"
        }
    ]

    const sorterFieldsNoSender = [
        {
            key: "created_at",
            label: "Order Date"
        },
        {
            key: "recipient_name",
            label: "Recipient Name"
        },
        {
            key: "transfer_amount",
            label: "Transfer Amount"
        }
    ]

    const formattedSorterFields = []
    let fields = [];

    if (selectedMenu === MENU.UNCONFIRMED_ORDERS) {
        fields = sorterFields;
    } else {
        fields = sorterFieldsNoSender;
    }

    fields.forEach((obj, key) => {
        formattedSorterFields.push({
            value: key * 2,
            sorter: obj.key,
            label: obj.label + " ASC",
            order: 1,
        })
        formattedSorterFields.push({
            value: key * 2 + 1,
            sorter: obj.key,
            label: obj.label + " DESC",
            order: -1,
        })
    })

    const [chosenSorterField, setChosenSorterField] = useState(null)
    const [chosenSorterFieldLabel, setChosenSorterFieldLabel] = useState(null)
    const [chosenSorterFieldOrder, setChosenSorterFieldOrder] = useState(1)

    const sortData = (arr) => {


        if (chosenSorterField === null) {
            return arr
        }

        let tempArr = arr.map((obj) => {
            if (typeof obj[chosenSorterField] === "string" || obj[chosenSorterField] instanceof String) {
                let tempObj = {...obj}
                tempObj[chosenSorterField] = tempObj[chosenSorterField]
                return tempObj
            } else {
                return obj
            }
        })

        let orderedBy = _.orderBy(tempArr, [chosenSorterField], [chosenSorterFieldOrder === 1 ? 'asc' : 'desc'])

        return orderedBy

    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize)
    }, [selectedMenu])

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    }

    const sortBtn = (
        <Col xs={12} md={6} style={{marginTop: windowWidth < 768 ? 30 : 15}}>
            <a href={'#'}
               style={{
                   color: Palette.COLOR_PRIMARY,
                   fontWeight: '600',
                   opacity: !isFilterExpanded ? 1 : 0,
                   height: !isFilterExpanded ? '100%' : 0
               }}
               onClick={(e) => {
                   e.preventDefault();
                   setFilterExpanded(true)
               }}>
                Sort and filter

                <FaCaretDown color={Palette.COLOR_PRIMARY} style={{marginLeft: 10}}/>
            </a>

            <div style={{
                color: Palette.COLOR_PRIMARY,
                height: isFilterExpanded ? '100%' : 0,
                opacity: isFilterExpanded ? 1 : 0,
                transition: 'opacity .15s'
            }}>
                <Row>
                    <Col xs={5} lg={4}>
                        <MdSort size={22} style={{marginRight: 15}}/>

                        <span style={{fontWeight: '600', fontSize: '1.05em'}}>Sort By</span>
                    </Col>

                    <Col style={{paddingLeft: 0}} xs={7} lg={4}>
                        <Select
                            // defaultValue={optionsArr[0]}
                            value={null}
                            options={formattedSorterFields}
                            onChange={(obj, key) => {
                                setChosenSorterFieldOrder(obj.order)
                                setChosenSorterField(obj.sorter)
                                setChosenSorterFieldLabel(obj.label)
                            }}
                            styles={styles.select}
                            components={{DropdownIndicator, IndicatorSeparator: () => null}}
                        />
                    </Col>
                </Row>
            </div>
        </Col>
    );

    function getExtension(path) {
        const basename = path.split(/[\\/]/).pop(),  // extract file name from full path ...
            // (supports `\\` and `/` separators)
            pos = basename.lastIndexOf(".");       // get last position of `.`

        if (basename === "" || pos < 1)            // if file name is empty or ...
            return "";                             //  `.` not found (-1) or comes first (0)

        return basename.slice(pos + 1);            // extract extension ignoring `.`
    }

    const filterConfirmationOrders = (obj, key) => {
        if (!obj.order_number.toLowerCase().includes(searchKeyword.toLowerCase())) return;

        const unconfirmedOrdersCard = (
            <OrderCard
                reject
                onViewReceipt={() => {
                    if(getExtension(obj.receipt_image_url) === 'pdf') window.open(obj.receipt_image_url, '_blank')
                    else {
                        setReceiptModalShown(true)
                        setSelectedOrder(obj)
                    }
                }}
                negative={obj.current_order_step === 'PAYMENT_REJECTED' || obj.current_order_step === 'TRANSACTION_FAILED'}
                viewReceipt={obj.current_order_step === "WAITING_APPROVAL"}
                windowWidth={windowWidth}
                order={obj}
                status={obj.current_order_step === "WAITING_PAYMENT" ? `Waiting for Customer's Payment` :  obj.current_order_step === "WAITING_APPROVAL" ? `Waiting for Admin Approval` : `Payment rejected, waiting for customer to reupload receipt`}
                name={'Order #' + obj.order_number}
                onSubmit={async () => {
                    if (obj.current_order_step === "WAITING_APPROVAL") {
                        let result = await OrderDAO.acceptPayment(obj.order_number)
                        getAllNeedConfirmation()
                    } else {
                        alert('Payment cannot be approved because receipt has not been uploaded')
                    }
                }}
                onReject={() => {
                    setSelectedOrder(obj)
                    setConfirmationModalShown(true)
                }}
                amount={obj.destination_currency + " " + util.thousandSeparator(obj.destination_amount)}/>
        )

        if (filters.length !== 0) {
            let show = true;
            let filtersResult = {};

            for (let idx = 0; idx < filters.length; idx++) {
                const filter = filters[idx];

                if (filtersResult[filter.type]) {
                    filtersResult[filter.type].filters.push(filter.value)
                } else {
                    filtersResult = {
                        ...filtersResult,
                        [filter.type]: {
                            filters: [filter.value],
                            found: false
                        }
                    }
                }

                // // const filterType = filter.type;
                //
                // if (filterType === 'Date' && !moment(obj.created_at).isBetween(filter.value.startDate, filter.value.endDate, 'date', '[]')) {
                //     show = false;
                //     break;
                // } else if (filterType === 'Recipient' && filter.value !== obj.recipient_name) {
                //     show = false;
                //     break;
                // } else if (filterType === 'Order ID' && filter.value !== obj.order_number) {
                //     show = false;
                //     break;
                // } else if (filterType === 'Amount Range') {
                //     console.log(filter)
                //
                //     if (filter.value.value.min && (obj.destination_amount < filter.value.value.min)) show = false
                //
                //     if (filter.value.value.max && (obj.destination_amount > filter.value.value.max)) show = false
                //
                //     break;
                // }
            }

            Object.keys(filtersResult).map(key => {
                filtersResult[key].filters.map(filter => {
                    if (key === 'Date' && moment(obj.created_at).isBetween(filter.startDate, filter.endDate, 'date', '[]')) {
                        filtersResult[key].found = true
                    } else if (key === 'Recipient' && filter === obj.recipient.name) {
                        filtersResult[key].found = true
                    } else if (key === 'Order ID' && filter === obj.order_number) {
                        filtersResult[key].found = true
                    } else if (key === 'Amount Range') {
                        if (!filter.value.min && filter.value.max && obj.destination_amount <= filter.value.max) {
                            filtersResult[key].found = true
                        } else if (filter.value.min && !filter.value.max && filter.value.min <= obj.destination_amount) {
                            filtersResult[key].found = true
                        } else if (filter.value.min <= obj.destination_amount && obj.destination_amount <= filter.value.max) {
                            filtersResult[key].found = true
                        }
                    }
                })
            })

            Object.keys(filtersResult).map(key => {
                if (!filtersResult[key].found) show = false
            })

            if (show) return unconfirmedOrdersCard
        } else return unconfirmedOrdersCard;
    }

    const filterProcessingOrder = (obj, key) => {
        if (!obj.order_number.toLowerCase().includes(searchKeyword.toLowerCase())) return;

        const processingOrderCard = (
            <OrderCard
                reject
                windowWidth={windowWidth}
                status={ORDER_STEPS[obj.current_order_step]}
                negative={obj.current_order_step === 'PAYMENT_REJECTED' || obj.current_order_step === 'TRANSACTION_FAILED'}
                order={obj}
                statusBtn={'Finish Order'}
                name={'Order #' + obj.order_number}

                onReject={() => {
                    setSelectedOrder(obj)
                    setConfirmationModalShown(true)
                }}

                onSubmit={async () => {
                    let result = await OrderDAO.processOrder(obj.order_number)

                    getAllProcessing()
                }}

                amount={obj.destination_currency + " " + util.thousandSeparator(obj.destination_amount)}/>
        )

        if (filters.length !== 0) {
            let show = true;
            let filtersResult = {};

            for (let idx = 0; idx < filters.length; idx++) {
                const filter = filters[idx];

                if (filtersResult[filter.type]) {
                    filtersResult[filter.type].filters.push(filter.value)
                } else {
                    filtersResult = {
                        ...filtersResult,
                        [filter.type]: {
                            filters: [filter.value],
                            found: false
                        }
                    }
                }
                // const filterType = filter.type;
                //
                // if (filterType === 'Date' && !moment(obj.created_at).isBetween(filter.value.startDate, filter.value.endDate, 'date', '[]')) {
                //     show = false;
                //     break;
                // } else if (filterType === 'Recipient' && filter.value !== obj.recipient_name) {
                //     show = false;
                //     break;
                // } else if (filterType === 'Order ID' && filter.value !== obj.order_number) {
                //     show = false;
                //     break;
                // } else if (filterType === 'Amount Range') {
                //     if (filter.value.value.min && (obj.destination_amount < filter.value.value.min)) show = false
                //
                //     if (filter.value.value.max && (obj.destination_amount > filter.value.value.max)) show = false
                //
                //     break;
                // }
            }

            Object.keys(filtersResult).map(key => {
                filtersResult[key].filters.map(filter => {
                    if (key === 'Date' && moment(obj.created_at).isBetween(filter.startDate, filter.endDate, 'date', '[]')) {
                        filtersResult[key].found = true
                    } else if (key === 'Recipient' && filter === obj.recipient.name) {
                        filtersResult[key].found = true
                    } else if (key === 'Order ID' && filter === obj.order_number) {
                        filtersResult[key].found = true
                    } else if (key === 'Amount Range') {
                        if (!filter.value.min && filter.value.max && obj.destination_amount <= filter.value.max) {
                            filtersResult[key].found = true
                        } else if (filter.value.min && !filter.value.max && filter.value.min <= obj.destination_amount) {
                            filtersResult[key].found = true
                        } else if (filter.value.min <= obj.destination_amount && obj.destination_amount <= filter.value.max) {
                            filtersResult[key].found = true
                        }
                    }
                })
            })

            Object.keys(filtersResult).map(key => {
                if (!filtersResult[key].found) show = false
            })

            if (show) return processingOrderCard
        } else return processingOrderCard;
    }

    return (
        <AppContainer active={'Orders'} activeSubMenu={'Process Orders'}>
            <ConfirmationModal show={isConfirmationModalShown}
                               noDelete
                               onConfirm={() => {
                                   setConfirmationModalShown(false)
                                   rejectOrder(selectedOrder.order_number)
                               }}
                               onCancel={() => setConfirmationModalShown(false)}
                               title={`Are you sure you want to reject this ${selectedMenu === MENU.PAYMENT_CONFIRMATION ? 'payment' : 'order'}?`}/>

            <EditRateModal
                selectedOrder={selectedOrderCurrentlyEdited}
                show={isEditRateModalShown}
                onClose={() => {
                    getUnconfirmedOrders()
                    setEditRateModalShown(false)
                }}/>

            <DateRangePicker show={isDateRangePickerShown} onClose={() => setDateRangePickerShown(false)}
                             onDateChanged={(dateRange) => {
                                 addFilter({type: 'Date', value: dateRange[0]})
                             }}/>

            <ReceiptModal
                order={selectedOrder}
                show={isReceiptModalShown} onClose={() => setReceiptModalShown(false)}
                windowWidth={windowWidth}/>

            <Container style={{paddingBottom: 100}} fluid>
                <Row style={{marginBottom: 40}}>
                    <Col
                        xs={12}
                        md={4}
                        onClick={() => {
                            getUnconfirmedOrders()
                            setFilters([]);
                            setChosenSorterField(null)
                            setSelectedMenu(MENU.UNCONFIRMED_ORDERS)
                            setAmountRangeCustom(false)
                            setFilteredRange({})
                        }}
                        onMouseOver={() => setMenuHovered(MENU.UNCONFIRMED_ORDERS)}
                        onMouseLeave={() => setMenuHovered(null)}
                        style={{
                            marginTop: 20,
                            textAlign: 'center',
                            fontFamily: 'Roboto',
                            fontWeight: selectedMenu === MENU.UNCONFIRMED_ORDERS || hoveredMenu === MENU.UNCONFIRMED_ORDERS ? '600' : '500',
                            color: selectedMenu === MENU.UNCONFIRMED_ORDERS || hoveredMenu === MENU.UNCONFIRMED_ORDERS ? Palette.COLOR_PRIMARY : '#737373',
                            fontSize: '1.4em',
                            cursor: 'pointer'
                        }}>
                        Unconfirmed Orders
                        <div style={{
                            width: '100%',
                            height: 1,
                            backgroundColor: selectedMenu === MENU.UNCONFIRMED_ORDERS || hoveredMenu === MENU.UNCONFIRMED_ORDERS ? Palette.COLOR_PRIMARY : '#737373',
                            marginTop: 5
                        }}/>
                    </Col>

                    <Col
                        xs={12}
                        md={4}
                        onClick={() => {
                            getAllNeedConfirmation()
                            setFilters([]);
                            setChosenSorterField(null)
                            setSelectedMenu(MENU.PAYMENT_CONFIRMATION)
                            setAmountRangeCustom(false)
                            setFilteredRange({})
                        }}
                        onMouseOver={() => setMenuHovered(MENU.PAYMENT_CONFIRMATION)}
                        onMouseLeave={() => setMenuHovered(null)}
                        style={{
                            marginTop: 20,
                            textAlign: 'center',
                            fontFamily: 'Roboto',
                            fontWeight: selectedMenu === MENU.PAYMENT_CONFIRMATION || hoveredMenu === MENU.PAYMENT_CONFIRMATION ? '600' : '500',
                            color: selectedMenu === MENU.PAYMENT_CONFIRMATION || hoveredMenu === MENU.PAYMENT_CONFIRMATION ? Palette.COLOR_PRIMARY : '#737373',
                            fontSize: '1.4em',
                            cursor: 'pointer'
                        }}>
                        Payment Confirmation

                        <div style={{
                            width: '100%',
                            height: 1,
                            backgroundColor: selectedMenu === MENU.PAYMENT_CONFIRMATION || hoveredMenu === MENU.PAYMENT_CONFIRMATION ? Palette.COLOR_PRIMARY : '#737373',
                            marginTop: 5
                        }}/>
                    </Col>

                    <Col
                        xs={12}
                        md={4}
                        onClick={() => {
                            getAllProcessing()
                            setFilters([]);
                            setChosenSorterField(null)
                            setSelectedMenu(MENU.PROCESSING_ORDER)
                            setAmountRangeCustom(false)
                            setFilteredRange({})
                        }}
                        onMouseOver={() => setMenuHovered(MENU.PROCESSING_ORDER)}
                        onMouseLeave={() => setMenuHovered(null)}
                        style={{
                            marginTop: 20,
                            textAlign: 'center',
                            fontFamily: 'Roboto',
                            fontWeight: selectedMenu === MENU.PROCESSING_ORDER || hoveredMenu === MENU.PROCESSING_ORDER ? '600' : '500',
                            color: selectedMenu === MENU.PROCESSING_ORDER || hoveredMenu === MENU.PROCESSING_ORDER ? Palette.COLOR_PRIMARY : '#737373',
                            fontSize: '1.4em',
                            cursor: 'pointer'
                        }}>
                        Processing Order

                        <div style={{
                            width: '100%',
                            height: 1,
                            backgroundColor: selectedMenu === MENU.PROCESSING_ORDER || hoveredMenu === MENU.PROCESSING_ORDER ? Palette.COLOR_PRIMARY : '#737373',
                            marginTop: 5
                        }}/>
                    </Col>
                </Row>

                <Container fluid={selectedMenu === MENU.UNCONFIRMED_ORDERS}>
                    <Row>
                        {
                            windowWidth < 768 ? null : sortBtn
                        }
                        <Col style={{
                            display: windowWidth < 768 ? 'block' : 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'flex-start'
                        }}>
                            <div style={{
                                border: '1px #d4d4d4 solid',
                                borderRadius: 10,
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <FaSearch color={Palette.COLOR_PRIMARY} style={{marginRight: 20, marginLeft: 15}}/>

                                <InputBase
                                    style={{fontSize: '.9em'}}
                                    placeholder="Type order details here..."
                                    onChange={(e) => setSearchKeyword(e.target.value)}
                                />
                            </div>
                        </Col>

                        {
                            windowWidth < 768 ? sortBtn : null
                        }
                    </Row>


                    <div style={{
                        height: isFilterExpanded ? '100%' : 0,
                        opacity: isFilterExpanded ? 1 : 0,
                        transition: 'opacity .15s'
                    }}>
                        <Row style={{marginTop: 20}}>
                            <Col xs={12} md={6}>

                                <div style={{color: Palette.COLOR_PRIMARY}}>
                                    <Row>
                                        <Col xs={5} lg={4}>
                                            <FaFilter size={14} style={{marginRight: 23}}/>

                                            <span style={{fontWeight: '600', fontSize: '1.05em'}}>Filter By</span>
                                        </Col>

                                        <Col>
                                            <Row>
                                                <Col md={12} lg={6} style={{paddingLeft: 0, marginBottom: 10}}>
                                                    <div
                                                        onMouseOver={() => setDateRangeHovered(true)}
                                                        onMouseLeave={() => setDateRangeHovered(false)}
                                                        onClick={() => setDateRangePickerShown(true)}
                                                        style={{
                                                            border: `1px ${isDateRangeHovered ? '#bababa' : '#bababa'} solid`,
                                                            width: '100%',
                                                            paddingTop: 6,
                                                            paddingBottom: 6,
                                                            borderRadius: 4,
                                                            color: '#4f4f4f',
                                                            paddingLeft: 10,
                                                            display: 'flex', alignItems: 'center',
                                                            paddingRight: 9
                                                        }}>
                                                    <span style={{flex: 1, color: 'grey', cursor: 'default'}}>
                                                        Date Range
                                                    </span>

                                                        <FaAngleDown color={'black'}/>
                                                    </div>
                                                </Col>

                                                <Col md={12} lg={6} style={{paddingLeft: 0, marginBottom: 10}}>
                                                    <Select
                                                        placeholder={'Order ID'}
                                                        onChange={(option) => addFilter({
                                                            type: 'Order ID',
                                                            value: option.value
                                                        })}
                                                        value={null}
                                                        options={orderIdOptions}
                                                        tableStyles={tableStyles.select}
                                                        components={{DropdownIndicator, IndicatorSeparator: () => null}}
                                                    />
                                                </Col>

                                                {windowWidth >= 992 && selectedMenu === MENU.UNCONFIRMED_ORDERS ?
                                                    <Col md={12} lg={6} style={{paddingLeft: 0, marginBottom: 10}}>
                                                        <Select
                                                            placeholder={'Order Purpose'}
                                                            onChange={(option) => addFilter({
                                                                type: 'Order Purpose',
                                                                value: option.value
                                                            })}
                                                            value={null}
                                                            options={orderPurposeOptions}
                                                            tableStyles={tableStyles.select}
                                                            components={{
                                                                DropdownIndicator,
                                                                IndicatorSeparator: () => null
                                                            }}
                                                        />
                                                    </Col> : null
                                                }
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>

                            <Col>
                                <Row>
                                    {windowWidth < 768 ? <Col xs={5}/> : null}
                                    <Col>
                                        <Row>
                                            {
                                                selectedMenu === MENU.UNCONFIRMED_ORDERS ?
                                                    <Col md={6} lg={4} style={{paddingLeft: 0, marginBottom: 10}}>
                                                        <Select
                                                            placeholder={'Sender Name'}
                                                            onChange={(option) => addFilter({
                                                                type: 'Sender',
                                                                value: option.value
                                                            })}
                                                            value={null}
                                                            options={senderOptions}
                                                            tableStyles={tableStyles.select}
                                                            components={{
                                                                DropdownIndicator,
                                                                IndicatorSeparator: () => null
                                                            }}
                                                        />
                                                    </Col> : null
                                            }


                                            <Col md={6} lg={4} style={{paddingLeft: 0, marginBottom: 10}}>
                                                <Select
                                                    placeholder={'Recipient Name'}
                                                    onChange={(option) => addFilter({
                                                        type: 'Recipient',
                                                        value: option.value
                                                    })}
                                                    value={null}
                                                    options={recipientOptions}
                                                    tableStyles={tableStyles.select}
                                                    components={{DropdownIndicator, IndicatorSeparator: () => null}}
                                                />
                                            </Col>

                                            <Col md={6} lg={4} style={{paddingLeft: 0, marginBottom: 10}}>
                                                <Select
                                                    placeholder={'Amount Range'}
                                                    onChange={(option) => addFilter({
                                                        type: 'Amount Range',
                                                        value: option
                                                    })}
                                                    value={null}
                                                    options={amountRangeOptions}
                                                    tableStyles={tableStyles.select}
                                                    components={{DropdownIndicator, IndicatorSeparator: () => null}}
                                                />

                                                {isAmountRangeCustom ?
                                                    <DefaultTextInput
                                                        style={{marginTop: 12}}
                                                        placeholder={'Minimum Amount'}
                                                        errors={[]}
                                                        value={filteredRange.min}
                                                        onChange={(event) => {
                                                            const filtersTemp = [...filters];

                                                            filtersTemp.map((filter, idx) => {
                                                                if (filter.type === 'Amount Range' && filter.value.label === 'Custom') {
                                                                    filtersTemp[idx].value.value.min = parseFloat(event.target.value.replace(/,/g, ''));
                                                                }
                                                            })

                                                            setFilters(filtersTemp);

                                                            const filteredRangeTemp = {...filteredRange};

                                                            if (event.target.value.length === 1 && event.target.value === "0") {
                                                                filteredRangeTemp.min = '';
                                                            } else {
                                                                filteredRangeTemp.min = event.target.value.replace(/\D/g, '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                                            }

                                                            setFilteredRange(filteredRangeTemp)
                                                        }}
                                                    /> : null
                                                }

                                                {isAmountRangeCustom ?
                                                    <DefaultTextInput
                                                        style={{marginTop: -10}}
                                                        placeholder={'Maximum Amount'}
                                                        errors={[]}
                                                        value={filteredRange.max}
                                                        onChange={(event) => {
                                                            const filtersTemp = [...filters];

                                                            filtersTemp.map((filter, idx) => {
                                                                if (filter.type === 'Amount Range' && filter.value.label === 'Custom') {
                                                                    filtersTemp[idx].value.value.max = parseFloat(event.target.value.replace(/,/g, ''));
                                                                }
                                                            })

                                                            setFilters(filtersTemp);

                                                            const filteredRangeTemp = {...filteredRange};

                                                            if (event.target.value.length === 1 && event.target.value === "0") {
                                                                filteredRangeTemp.max = '';
                                                            } else {
                                                                filteredRangeTemp.max = event.target.value.replace(/\D/g, '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                                            }

                                                            setFilteredRange(filteredRangeTemp)
                                                        }}
                                                    /> : null
                                                }
                                            </Col>

                                            {windowWidth < 992 && selectedMenu === MENU.UNCONFIRMED_ORDERS ?
                                                <Col md={6} lg={4} style={{paddingLeft: 0, marginBottom: 10}}>
                                                    <Select
                                                        placeholder={'Order Purpose'}
                                                        onChange={(option) => addFilter({
                                                            type: 'Order Purpose',
                                                            value: option.value
                                                        })}
                                                        value={null}
                                                        options={orderPurposeOptions}
                                                        tableStyles={tableStyles.select}
                                                        components={{
                                                            DropdownIndicator,
                                                            IndicatorSeparator: () => null
                                                        }}
                                                    />
                                                </Col> : null
                                            }
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row style={{marginTop: 10}}>
                            <Col style={{display: 'flex', flexWrap: 'wrap'}} xs={12} md={8}>
                                {
                                    chosenSorterField ?
                                        <div style={{
                                            borderRadius: 50,
                                            backgroundColor: '#e6e7e8',
                                            paddingLeft: 20,
                                            paddingTop: 8,
                                            paddingBottom: 8,
                                            display: 'flex',
                                            alignItems: 'center',
                                            paddingRight: 10,
                                            marginRight: 10
                                        }}>
                                            {chosenSorterFieldLabel}
                                            <AiOutlineClose
                                                onClick={() => {
                                                    setChosenSorterFieldLabel("")
                                                    setChosenSorterField(null)
                                                    setChosenSorterFieldOrder(1)
                                                }}
                                                style={{
                                                    marginTop: 3, marginLeft: 10, cursor: 'pointer'
                                                }}
                                            />
                                        </div>
                                        :
                                        null
                                }

                                {
                                    filters.map(
                                        filter => {
                                            return (
                                                <div style={{
                                                    borderRadius: 50,
                                                    backgroundColor: '#e6e7e8',
                                                    paddingLeft: 20,
                                                    paddingTop: 8,
                                                    paddingBottom: 8,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    paddingRight: 10,
                                                    marginRight: 10,
                                                    marginTop: 10
                                                }}>
                                                    {filter.type}: {filter.type === 'Customer' || filter.type === 'Amount Range' || filter.type === 'Status' ? filter.value.label : filter.type === 'Date' ? moment(filter.value.startDate).format('DD/MM/YYYY') + ' - ' + moment(filter.value.endDate).format('DD/MM/YYYY') : filter.value}
                                                    <AiOutlineClose
                                                        onClick={() => deleteFilter(filter)}
                                                        style={{marginTop: 3, marginLeft: 10, cursor: 'pointer'}}/>
                                                </div>
                                            )
                                        }
                                    )
                                }
                            </Col>

                            <Col style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
                                <a href={'#'}
                                   style={{color: Palette.COLOR_PRIMARY, fontWeight: '600'}}
                                   onClick={(e) => {
                                       e.preventDefault();
                                       setFilterExpanded(false)
                                   }}>
                                    Collapse

                                    <FaCaretUp color={Palette.COLOR_PRIMARY} style={{marginLeft: 10}}/>
                                </a>
                            </Col>
                        </Row>

                        <Row style={{
                            width: '100%',
                            height: 1,
                            backgroundColor: '#878787',
                            marginLeft: 0,
                            marginTop: 15,
                            marginBottom: 30
                        }}/>
                    </div>

                    {selectedMenu === MENU.UNCONFIRMED_ORDERS ?
                        <Row>
                            <Col>
                                <Table style={{
                                    fontSize : "0.85em"
                                }}>
                                    <thead>
                                    <tr style={{textAlign: 'center'}}>
                                        <th style={{
                                            fontFamily: 'Roboto',
                                            fontWeight: '500',
                                            fontSize: '.9em',
                                            border: '1px solid #cccccc',
                                            color: 'grey',
                                        }}>
                                            ORDER
                                        </th>

                                        <th style={{
                                            fontFamily: 'Roboto',
                                            fontWeight: '500',
                                            fontSize: '.9em',
                                            border: '1px solid #cccccc',
                                            color: 'grey',
                                        }}>
                                            SENDER
                                        </th>

                                        <th style={{
                                            fontFamily: 'Roboto',
                                            fontWeight: '500',
                                            fontSize: '.9em',
                                            border: '1px solid #cccccc',
                                            color: 'grey',
                                        }}>
                                            RECIPIENT
                                        </th>

                                        <th style={{
                                            fontFamily: 'Roboto',
                                            fontWeight: '500',
                                            fontSize: '.9em',
                                            border: '1px solid #cccccc',
                                            color: 'grey',
                                        }}>
                                            PAYMENT
                                        </th>
                                        <th style={{borderWidth: 0}}>
                                        </th>
                                    </tr>
                                    </thead>

                                    <tbody>

                                    {
                                        sortData(unconfirmedOrders).map(
                                            (datum, key) => {
                                                return filterUnconfirmedOrders(datum, key)
                                            }
                                        )
                                    }


                                    </tbody>
                                </Table>
                            </Col>
                        </Row> :
                        selectedMenu === MENU.PAYMENT_CONFIRMATION ?
                            <>
                                {
                                    sortData(needConfirmationOrders).map((obj, key) => {
                                        return filterConfirmationOrders(obj, key)
                                    })
                                }
                                {/*<OrderCard*/}
                                {/*    reject*/}
                                {/*    onViewReceipt={() => setReceiptModalShown(true)}*/}
                                {/*    viewReceipt*/}
                                {/*    windowWidth={windowWidth}*/}
                                {/*    status={'Order paid – waiting for admin approval'}*/}
                                {/*    name={'Order # 1010101'} amount={'SGD 5,000'}/>*/}

                                {/*<OrderCard*/}
                                {/*    reject*/}
                                {/*    onViewReceipt={() => setReceiptModalShown(true)}*/}
                                {/*    viewReceipt*/}
                                {/*    windowWidth={windowWidth}*/}
                                {/*    status={'Order paid – waiting for admin approval'}*/}
                                {/*    name={'Order # 1010102'} amount={'MYR 500,000'}/>*/}

                                {/*<OrderCard*/}
                                {/*    reject*/}
                                {/*    onViewReceipt={() => setReceiptModalShown(true)}*/}
                                {/*    viewReceipt*/}
                                {/*    windowWidth={windowWidth}*/}
                                {/*    detail*/}
                                {/*    status={'Order paid – waiting for admin approval'}*/}
                                {/*    name={'Order # 1010102'} amount={'SGD 1,495'}/>*/}
                            </> :
                            selectedMenu === MENU.PROCESSING_ORDER ?
                                <>
                                    {
                                        sortData(processingOrders).map((obj, key) => {
                                            return filterProcessingOrder(obj, key)
                                        })
                                    }

                                    {/*<OrderCard*/}
                                    {/*    onShowComplaint={() => setComplaintModalShown(true)}*/}
                                    {/*    complaint*/}
                                    {/*    reject*/}
                                    {/*    windowWidth={windowWidth}*/}
                                    {/*    status={'Order is being processed'}*/}
                                    {/*    statusBtn={'Transaction successful'}*/}
                                    {/*    name={'Order # 1010101'} amount={'SGD 5,000'}/>*/}

                                    {/*<OrderCard*/}
                                    {/*    reject*/}
                                    {/*    windowWidth={windowWidth}*/}
                                    {/*    status={'Payment approved'}*/}
                                    {/*    statusBtn={'Order is being processed'}*/}
                                    {/*    name={'Order # 1010101'} amount={'SGD 5,000'}/>*/}

                                    {/*<OrderCard*/}
                                    {/*    reject*/}
                                    {/*    windowWidth={windowWidth}*/}
                                    {/*    status={'Payment approved'}*/}
                                    {/*    statusBtn={'Order is being processed'}*/}
                                    {/*    name={'Order # 1010101'} amount={'SGD 5,000'}/>*/}

                                    {/*<OrderCard*/}
                                    {/*    reject*/}
                                    {/*    windowWidth={windowWidth}*/}
                                    {/*    status={'Payment approved'}*/}
                                    {/*    statusBtn={'Order is being processed'}*/}
                                    {/*    name={'Order # 1010101'} amount={'SGD 5,000'}/>*/}
                                </> : null
                    }
                </Container>
            </Container>
        </AppContainer>
    )
}
