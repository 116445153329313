import AppContainer from "../reusables/AppContainer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import {Col} from "react-bootstrap";
import styles from "../styles";
import Button from "@material-ui/core/Button";
import React, {useEffect, useState} from "react";
import DefaultTextInput from "../reusables/DefaultTextInput";
import DefaultSelectInput from "../reusables/DefaultSelectInput";
import Palette from "../Palette";
import AdminDao from "../daos/AdminDao";
import {useHistory} from "react-router-dom";
import CustomerDao from "../daos/CustomerDao";
import moment from "moment";

export default function ManageAdmin(props) {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const isUpdate = props.match.params.action === 'update';

    const history = useHistory()

    const [errors, setErrors] = useState({});

    const [id, setId] = useState(null);
    const [email, setEmail] = useState("")
    const [name, setName] = useState("")
    const [password, setPassword] = useState("")
    const [privilegeLevel, setPrivilegeLevel] = useState(null)
    const [errorMsg, setErrorMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);

    useEffect(() => {
        if (isUpdate) {
            AdminDao.getById(props.match.params.id).then(res => {
                console.log(res)

                setId(res.id)
                setName(res.name)
                setEmail(res.email)
                setPrivilegeLevel(res.privilege_level === "7" ? 'Superadmin' : 'Admin');
            }).catch(err => console.log(err))
        }

        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    const handleResize = () => {
        console.log(window.innerWidth)
        setWindowWidth(window.innerWidth)
    }

    const onSubmit = async () => {
        const body = {
            name: name,
            email: email,
            password: password,
            privilege_level: privilegeLevel === "Superadmin" ? 7 : 6
        };

        if (!isUpdate) {
            try {


                const errorsTemp = {};
                setErrors(errorsTemp);
                setErrorMsg(null);
                setSuccessMsg(null)

                if (!name) errorsTemp["FULL NAME"] = 'Full name cannot be blank';
                if (!email) errorsTemp["EMAIL ADDRESS"] = 'Nickname cannot be blank';
                if (!password) errorsTemp["PASSWORD"] = 'Password cannot be blank';

                if (Object.keys(errorsTemp).length === 0) {

                    let result = await AdminDao.create(body);
                    console.log(result)
                    history.push("/accounts")

                }

            } catch (e) {
                if (e.code) {
                    if (e.code === 'DUPLICATE_EMAIL') {
                        const errorsTemp = {};
                        errorsTemp['EMAIL ADDRESS'] = 'Email has been used by another account';
                        setErrors(errorsTemp);
                    }
                }
                console.log(e)
            }
        } else {
            const errorsTemp = {};
            setErrors(errorsTemp);
            setErrorMsg(null);
            setSuccessMsg(null)

            if (!name) errorsTemp["FULL NAME"] = 'Full name cannot be blank';
            if (!email) errorsTemp["EMAIL ADDRESS"] = 'Nickname cannot be blank';

            if (Object.keys(errorsTemp).length === 0) {

                AdminDao.update(id, body).then(res => {
                    if (res.success) {
                        setSuccessMsg('Admin has been updated successfully!')
                    }
                }).catch(err => {
                    console.log(err)

                    if (err.code) {
                        if (err.code === 'EMAIL_ALREADY_USED') {
                            const errorsTemp = {};
                            errorsTemp['EMAIL ADDRESS'] = 'Email has been used by another account';
                            setErrors(errorsTemp);
                        }
                    }
                    setErrorMsg('An error occured');
                });

            } else {
                setErrorMsg('One or more errors occured. Please check the form again.')
            }
        }
    }

    const onKeyPress = (ev) => {
        if (ev.key === 'Enter') {
            ev.preventDefault();

            onSubmit()
        }
    }

    return (
        <AppContainer active={'Accounts'}>
            <Container style={{paddingBottom: 100}}>
                <Row>
                    <Col
                        style={{...styles.header, marginBottom: 50}}>
                        Admin Account Details
                    </Col>
                </Row>

                <DefaultTextInput
                    onKeyPress={onKeyPress}
                    field={'FULL NAME'} errors={errors}
                    value={name}
                    onChange={(e) => {
                        setName(e.target.value)
                    }}/>

                <DefaultTextInput
                    onKeyPress={onKeyPress}
                    field={'EMAIL ADDRESS'} type={'email'}
                    value={email} errors={errors} onChange={(e) => {
                    setEmail(e.target.value)
                }}/>

                {/*<DefaultSelectInput*/}
                {/*    onChange={(e) => {*/}
                {/*        console.log(e)*/}
                {/*        setPrivilegeLevel(e.value)*/}
                {/*    }}*/}
                {/*    value={privilegeLevel ? {value: privilegeLevel, label: privilegeLevel} : null}*/}
                {/*    field={'ACCOUNT ROLE'} options={["Admin", "Superadmin"]}/>*/}

                {isUpdate ?
                    <>
                        <DefaultTextInput
                            onKeyPress={onKeyPress}
                            disabled field={'PASSWORD'} type={'password'} value={'123456789'}
                            errors={errors} onChange={(e) => {
                            setPassword(e.target.value)
                        }}/>

                        <Row style={{marginBottom: 50}}>
                            <Col style={{display: 'flex', justifyContent: 'center'}}>
                                <div style={{
                                    width: 'inherit',
                                    maxWidth: 580,
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                }}>
                                    <a href={`/admin/change-password/${id}`}
                                       style={{textDecorationLine: 'none'}}>
                                        <div style={{
                                            cursor: 'pointer',
                                            backgroundColor: '#dce1e5',
                                            color: '#3f9e59',
                                            paddingLeft: 30,
                                            paddingRight: 30,
                                            paddingTop: 2,
                                            paddingBottom: 2,
                                            borderRadius: 50
                                        }}>
                                            Change Password
                                        </div>
                                    </a>
                                </div>
                            </Col>
                        </Row>
                    </> :
                    <DefaultTextInput
                        onChange={(e) => {
                            setPassword(e.target.value)
                        }}
                        field={'PASSWORD'} type={'password'} value={password} errors={errors}/>
                }


                <div style={{fontSize: '80%', textAlign: 'center', color: errorMsg ? 'red' : 'green', width: '100%'}}>
                    {errorMsg ? errorMsg : successMsg ? successMsg : null}
                </div>

                <Row style={{
                    marginBottom: 5,
                    marginTop: 20,
                    marginLeft: windowWidth < 430 ? 30 : 25,
                    marginRight: windowWidth < 430 ? 30 : 25,
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    {/*{isUpdate ?*/}
                    {/*    <Button style={{*/}
                    {/*        order: windowWidth < 430 ? 1 : 0, ...styles.button,*/}
                    {/*        height: 50,*/}
                    {/*        width: 170,*/}
                    {/*        fontSize: '1em',*/}
                    {/*        color: Palette.COLOR_PRIMARY,*/}
                    {/*        backgroundColor: '#e6e6e6',*/}
                    {/*        marginRight: windowWidth >= 460 ? 50 : windowWidth >= 430 ? 10 : windowWidth < 430 ? 0 : 30*/}
                    {/*    }}>*/}
                    {/*        Reject*/}
                    {/*    </Button> : null*/}
                    {/*}*/}


                    <Button
                        onClick={() => {
                            onSubmit()
                        }}
                        style={{
                            order: windowWidth < 430 ? 0 : 1, ...styles.button,
                            height: 50,
                            width: 170,
                            fontSize: '1em',
                            marginBottom: windowWidth < 430 ? 15 : 0
                        }}>
                        SAVE
                    </Button>
                </Row>
            </Container>
        </AppContainer>
    )
}
