import httpRequest from "../util/httpRequest"

export default class BankDao {
    static getAll = async () => {
        return new Promise(
            await httpRequest.set('v1/banks', 'GET')
        )
    }

    static create = async (body) => {
        return new Promise(
            await httpRequest.set('v1/banks', 'POST', JSON.stringify(body))
        )
    }

    static update = async (id, body) => {
        return new Promise(
            await httpRequest.set(`v1/bank/${id}`, 'PUT', JSON.stringify(body))
        )
    }

    static delete = async (id) => {
        return new Promise(
            await httpRequest.set(`v1/bank/${id}`, 'DELETE')
        )
    }
}
