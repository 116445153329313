import httpRequest from "../util/httpRequest"

export default class CurrencyDAO {

    static getInfo = async () => {
        return new Promise(
            await httpRequest.set('v1/account_info', 'GET')
        )
    }

    static update = async (accountInfo) => {
        return new Promise(
            await httpRequest.set('v1/account_info', 'PUT', JSON.stringify(accountInfo))
        )
    }

}
