import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import styles from "../styles";
import React, {useState} from "react";
import Palette from "../Palette";
import Button from "@material-ui/core/Button";
import moment from "moment";
import util from "../util/util";

export default function OrderCard(props) {
    const {
        windowWidth,
        statusBtn,
        name,
        negative,
        status,
        amount,
        reject,
        viewReceipt,
        // detail,
        complaintStatus,
        complaintResolved,
        onViewReceipt,
        onShowComplaint,
        noBtn,
        order,
        complaintUpdated
    } = props;

    const month = new moment(order.created_at).format("MMM")
    const date = new moment(order.created_at).format("DD")
    const time = new moment(order.created_at).format("HH:mm")

    const [isLinkHovered, setLinkHovered] = useState(false);
    const [detail, setDetail] = useState(true)

    return (
        <Row
            onClick={() => setDetail(!detail)}
            style={{color: '#737373', cursor: 'pointer'}}>
            <Col style={{
                margin: 15,
                paddingTop: 15,
                paddingBottom: 15,
                backgroundColor: '#f7f7f7',
                border: '1px #e0e0e0 solid'
            }}>
                <Row>
                    {/*{complaintUpdated &&*/}
                    {/*<Col xs={12} style={{*/}
                    {/*    display: 'flex',*/}
                    {/*    flexDirection: 'column',*/}
                    {/*    alignItems: 'flex-end',*/}
                    {/*    color: 'white',*/}
                    {/*    fontFamily: 'Roboto',*/}
                    {/*    fontWeight: 500,*/}
                    {/*    marginBottom: 10*/}
                    {/*}}>*/}
                    {/*    <span style={{backgroundColor: Palette.COLOR_PRIMARY, paddingLeft: 8, paddingRight: 8}}>Complaint Updated!</span>*/}
                    {/*</Col>*/}
                    {/*}*/}

                    <Col style={{textAlign: 'center'}} md={2} lg={1} xs={3}>
                        <div style={{fontWeight: '600', fontSize: '.9em'}}>
                            {month}
                        </div>


                        <div style={{fontSize: '2em', marginTop: -10, marginBottom: -10}}>
                            {date}
                        </div>

                        <div style={{fontWeight: '600', fontSize: '.85em'}}>
                            {time}
                        </div>
                    </Col>
                    <Col md={6} xs={9} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                        <div style={{fontWeight: '600'}}>
                            {name} {complaintStatus ?
                            <a href={'#'}
                               onMouseOver={() => setLinkHovered(true)}
                               onMouseLeave={() => setLinkHovered(false)}
                               style={{
                                   textDecorationLine: 'none',
                                   borderBottom: isLinkHovered ? complaintResolved ? `1px solid ${Palette.COLOR_PRIMARY}` : '1px solid #c9cf32' : 'none',
                                   marginLeft: 6
                               }}
                               onClick={(e) => {
                                   e.stopPropagation()
                                   e.preventDefault();
                                   onShowComplaint()
                               }}><span
                                style={{
                                    color: complaintResolved ? Palette.COLOR_PRIMARY : '#a8ad2a',
                                    fontFamily: 'Roboto',
                                    fontWeight: '500'
                                }}>{complaintStatus}</span>
                            </a> : null}

                            {complaintUpdated &&
                            <span style={{
                                backgroundColor: Palette.COLOR_PRIMARY,
                                paddingLeft: 12,
                                paddingRight: 12,
                                color: 'white',
                                fontFamily: 'Roboto',
                                fontWeight: 500,
                                marginLeft: 6,
                                borderRadius: 20,
                                fontSize: 13,
                                paddingTop: 2,
                                paddingBottom: 2
                            }}>
                                Complaint Updated!
                            </span>
                            }
                        </div>

                        <div style={{color: negative ? 'red' : 'green'}}>
                            {status}
                        </div>
                    </Col>

                    {detail ?
                        <Col xs={12}>
                            <div style={{
                                width: '100%',
                                height: 1,
                                backgroundColor: '#878787',
                                marginTop: 15,
                                marginBottom: 15
                            }}/>

                            <Row>
                                <Col style={{fontWeight: '600', color: '#969696'}} md={12} lg={6}>
                                    <div style={{marginBottom: 5}}>Recipient Details</div>
                                    <Row style={{fontWeight: '400', marginBottom: 5, display: 'flex'}}>
                                        <Col>
                                            Name:
                                        </Col>
                                        <Col style={{
                                            wordBreak: 'break-all',
                                            paddingRight: 15,
                                        }}>
                                            {order.recipient?.name}
                                        </Col>
                                    </Row>

                                    <Row style={{fontWeight: '400', marginBottom: 5, display: 'flex'}}>
                                        <Col>
                                            Email:
                                        </Col>
                                        <Col style={{
                                            wordBreak: 'break-all',
                                            paddingRight: 15,
                                        }}>
                                            {order.recipient?.email ? order.recipient?.email : "-"}
                                        </Col>
                                    </Row>

                                    <Row style={{fontWeight: '400', marginBottom: 5, display: 'flex'}}>
                                        <Col>
                                            Bank:
                                        </Col>
                                        <Col style={{
                                            wordBreak: 'break-all',
                                            paddingRight: 15,
                                        }}>
                                            {order.recipient?.bank_name}
                                        </Col>
                                    </Row>

                                    <Row style={{fontWeight: '400', marginBottom: 5, display: 'flex'}}>
                                        <Col>
                                            Received Amount:
                                        </Col>
                                        <Col style={{paddingRight: 15}}>
                                            {order.destination_currency} {util.thousandSeparator(order.destination_amount)}
                                        </Col>
                                    </Row>

                                </Col>

                                <Col style={{
                                    fontWeight: '600',
                                    color: '#969696',
                                    marginTop: windowWidth < 992 ? 20 : 0
                                }}>
                                    <div style={{marginBottom: 5}}>Payment Details</div>

                                    <Row style={{fontWeight: '400', marginBottom: 5}}>
                                        <Col>
                                            Transfer Amount:
                                        </Col>

                                        <Col style={{textAlign: 'right',}}>
                                            {order.source_currency} {util.thousandSeparator(order.transfer_amount)}
                                        </Col>
                                    </Row>

                                    <Row style={{fontWeight: '400', marginBottom: 5}}>
                                        <Col>
                                            Exchange Rate:
                                        </Col>

                                        <Col style={{textAlign: 'right'}}>
                                            {order.destination_currency} 1
                                            = {order.source_currency} {util.thousandSeparator(1 / order.currency_rate) !== "0" ? util.thousandSeparator(1 / order.currency_rate) : 1 / order.currency_rate}
                                        </Col>
                                    </Row>

                                    <Row style={{fontWeight: '400', marginBottom: 5}}>
                                        <Col>
                                            Service Fee:
                                        </Col>

                                        <Col style={{textAlign: 'right'}}>
                                            {order.source_currency} {util.thousandSeparator(order.rupiah_fee)}
                                        </Col>
                                    </Row>

                                    <Row style={{fontWeight: '400', marginBottom: 5}}>
                                        <Col>
                                            Total Payment Amount:
                                        </Col>

                                        <Col style={{textAlign: 'right'}}>
                                            {order.source_currency} {util.thousandSeparator(order.source_amount)}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col> : null
                    }

                    {windowWidth < 1200 ?
                        <Col xs={12} style={{
                            width: '100%',
                            height: 1,
                            backgroundColor: '#e3e3e3',
                            marginTop: 10,
                            marginBottom: 10
                        }}/> : null}

                    <Col>
                        <div style={{fontWeight: '600', textAlign: 'right', paddingRight: 5, marginBottom: 15}}>
                            {amount}
                        </div>

                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Row style={{marginRight: 0}}>

                                {viewReceipt && windowWidth < 1200 ?
                                    <Col xs={12} style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        paddingRight: 0,
                                        marginBottom: 8
                                    }}>
                                        <Button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onViewReceipt()
                                            }}
                                            style={{
                                                ...styles.button, marginBottom: 0,
                                                fontSize: '.8em',
                                                fontWeight: '600',
                                                width: 140,
                                                backgroundColor: 'white',
                                                color: Palette.COLOR_PRIMARY,
                                                border: '1px #dbdbdb solid'
                                            }}>
                                            View Receipt
                                        </Button>
                                    </Col> :
                                    viewReceipt && windowWidth >= 1200 ?
                                        <Button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onViewReceipt()
                                            }}
                                            style={{
                                                ...styles.button, marginBottom: 0,
                                                fontSize: '.8em',
                                                fontWeight: '600',
                                                width: 140,
                                                backgroundColor: 'white',
                                                color: Palette.COLOR_PRIMARY,
                                                border: '1px #dbdbdb solid',
                                                marginRight: 10,
                                            }}>
                                            View Receipt
                                        </Button> : null}

                                {reject && windowWidth < 1200 ?
                                    <Col xs={12} style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        paddingRight: 0,
                                        marginBottom: 8
                                    }}>
                                        <Button
                                            style={{
                                                ...styles.button, marginBottom: 0,
                                                fontSize: '.8em',
                                                fontWeight: '600',
                                                width: 140,
                                                backgroundColor: 'white',
                                                color: Palette.COLOR_PRIMARY,
                                                border: '1px #dbdbdb solid'
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                props.onReject()
                                            }}
                                        >

                                            Reject
                                        </Button>
                                    </Col>
                                    : reject && windowWidth >= 1200 ?
                                        <Button
                                            style={{
                                                ...styles.button, marginBottom: 0,
                                                fontSize: '.8em',
                                                fontWeight: '600',
                                                width: 140,
                                                backgroundColor: 'white',
                                                color: Palette.COLOR_PRIMARY,
                                                border: '1px #dbdbdb solid',
                                                marginRight: 10
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                props.onReject()
                                            }}
                                        >
                                            Reject
                                        </Button>
                                        : null}

                                {windowWidth < 1200 && !noBtn ?
                                    <Col xs={12} style={{display: 'flex', justifyContent: 'flex-end', paddingRight: 0}}>
                                        <Button style={{
                                            ...styles.button, marginBottom: 0,
                                            fontSize: '.8em',
                                            fontWeight: '600',
                                            width: 140,
                                            opacity: !statusBtn && !viewReceipt ? .25 : 1,
                                        }} onClick={(e) => {
                                            e.stopPropagation()
                                            props.onSubmit()
                                        }}>
                                            {statusBtn ? statusBtn : 'Mark as Paid'}
                                        </Button>
                                    </Col> :
                                    !noBtn ?
                                        <Button style={{
                                            ...styles.button, marginBottom: 0,
                                            fontSize: '.8em',
                                            fontWeight: '600',
                                            width: statusBtn && statusBtn.length > 11 ? 210 : 140
                                        }} onClick={(e) => {
                                            e.stopPropagation()
                                            props.onSubmit()
                                        }}>
                                            {statusBtn ? statusBtn : 'Mark as Paid'}
                                        </Button> : null
                                }

                            </Row>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}
