import React from "react";
import Icon from '@ant-design/icons';
import { MailOutlined,MessageOutlined,BellOutlined,UnorderedListOutlined, NotificationOutlined   } from '@ant-design/icons';
const WelcomeCard = (props) => {

    let {unconfirmedOrders, pendingPayment, processingOrders, unresolvedComplain} = props

    return (
        <div className="gx-wel-ema gx-pt-xl-2">
            <h1 className="gx-mb-3" style={{fontFamily: 'NoirPro-Regular', fontSize: 28, color: '#262626'}}>Welcome Admin!</h1>
            <p className="gx-fs-sm gx-text-uppercase" style={{fontSize: 12, fontFamily: 'NoirPro-Light', color: '#545454'}}>You Have</p>
            <ul className="gx-list-group">
                <li>
                    <MessageOutlined style={{fontSize: 14}}/>

                    <span style={{fontFamily: 'NoirPro-Regular', fontSize: 14, color: '#545454'}}>{unconfirmedOrders ? unconfirmedOrders: 0} Unconfirmed Orders</span>
                </li>
                <li>
                    <MailOutlined  style={{fontSize: 14}}/>
                    <span style={{fontFamily: 'NoirPro-Regular', fontSize: 14, color: '#545454'}}>{pendingPayment ?pendingPayment : 0} Pending Payment Approval Order</span>
                </li>
                <li>
                    <UnorderedListOutlined  style={{fontSize: 14}}/>
                    <span style={{fontFamily: 'NoirPro-Regular', fontSize: 14, color: '#545454'}}>{processingOrders ? processingOrders : 0} Processing Orders</span>
                </li>

                <li>
                    <NotificationOutlined  style={{fontSize: 14}}/>
                    <span style={{fontFamily: 'NoirPro-Regular', fontSize: 14, color: '#545454'}}>{unresolvedComplain ? unresolvedComplain : 0} Unresolved Complaints</span>
                </li>
            </ul>
        </div>

    );
};

export default WelcomeCard;
