import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import styles from "../styles";
import AppContainer from "./AppContainer";
import React, {useEffect} from "react";
import {AiOutlineClose} from "react-icons/ai";
import Button from "@material-ui/core/Button";
import ComplainDao from "../daos/ComplainDao";

export default function ComplainModal(props) {
    const {
        show,
        onClose,
        windowWidth,
        order,
        updateData
    } = props;

    const disableScrolling = () => {
        const x = window.scrollX;
        const y = window.scrollY;
        window.onscroll = function () {
            window.scrollTo(x, y);
        };
    }

    const enableScrolling = () => {
        window.onscroll = function () {
        };
    }

    useEffect(() => {
        if (show) {
            disableScrolling()
        } else enableScrolling()
    }, [show])

    const attemptResolving = () => {
        ComplainDao.resolving(order.complaintId).then(res => {
            alert(`Complaint from order #${order.order_number} marked as resolving`)
            onClose();
            updateData();
        }).catch(err => console.log(err))
    }

    const attemptResolved = () => {
        ComplainDao.resolved(order.complaintId).then(res => {
            console.log(res)

            alert(`Complaint from order #${order.order_number} marked as resolved`)
            onClose();
            updateData();
        }).catch(err => console.log(err))
    }

    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                right: 0,
                width: '100%',
                minHeight: '100vh',
                zIndex: show ? 2 : -1,
                backgroundColor: '#00000059',
                opacity: show ? 1 : 0,
                transition: 'zIndex .2s'
            }}>
            <Container
                style={{minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Row style={{
                    backgroundColor: '#f5f5f5',
                    boxShadow: '0px 4px 5px 0px rgba(0,0,0,0.3)',
                    width: '100%',
                    maxWidth: 600,
                    borderRadius: 15,
                    paddingBottom: 40
                }}>
                    <Row style={{width: '100%', marginTop: 30, marginLeft: 15, marginRight: 15, marginBottom: 15}}>
                        <Col xs={9} style={{fontSize: '1.2em'}}>
                            Order #{order.order_number}
                        </Col>

                        <Col style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <AiOutlineClose
                                style={{cursor: 'pointer'}} size={27} onClick={onClose}/>
                        </Col>
                    </Row>

                    <Row style={{width: '100%', marginLeft: 15, marginRight: 15}}>
                        <Col xs={12} style={{marginLeft: 0, marginBottom: 30}}>
                            Complaint Message
                        </Col>
                        <Col style={{fontFamily: 'Roboto', fontWeight: '500', color: 'grey'}}>
                            {order.complaint}
                        </Col>
                    </Row>

                    <Row style={{width: '100%', marginTop: 30, marginLeft: 0}}>
                        <Col md={2}/>

                        <Col style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            paddingRight: windowWidth < 493 ? 15 : 0
                        }}>
                            {!order.resolvingAt && order.complaintStatus !== 'RESOLVED' ?
                                <Button style={{...styles.button, width: 200, backgroundColor: '#c7c15d'}}
                                        onClick={attemptResolving}>
                                    Mark as resolving
                                </Button> : null
                            }
                        </Col>

                        <Col style={{
                            display: 'flex',
                            justifyContent: windowWidth < 493 ? 'flex-end' : 'center',
                            marginTop: windowWidth < 493 ? 10 : 0
                        }}>
                            {!order.resolvedAt ?
                                <Button style={{...styles.button, width: 200}} onClick={attemptResolved}>
                                    Mark as resolved
                                </Button> : null
                            }
                        </Col>
                    </Row>
                </Row>
            </Container>
        </div>
    )
}