import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "@material-ui/core/Button";
import styles from "../styles";
import React, {useEffect, useState} from "react";
import {AiOutlineClose} from "react-icons/ai";
import BankDAO from "../daos/BankDAO";

export default function ManageBankModal(props) {
    const {
        show,
        onClose,
        refreshBankList,
        selectedBank
    } = props;

    const [bankName, setBankName] = useState(null);

    useEffect(() => {
        if (selectedBank.name) setBankName(selectedBank.name)
        else setBankName('');
    }, [selectedBank])

    const handleSubmit = () => {
        if (selectedBank.id) {
            BankDAO.update(selectedBank.id, {
                name: bankName
            }).then(res => {
                alert('Bank has been updated successfully');
                onClose();
                refreshBankList();
                console.log(res)
            }).catch(err => console.log(err))
        } else {
            BankDAO.create({
                name: bankName
            }).then(res => {
                alert('New bank has been created successfully');
                onClose();
                refreshBankList();
                console.log(res)
            }).catch(err => console.log(err))
        }
    }

    const onKeyPress = (ev) => {
        if (ev.key === 'Enter') {
            ev.preventDefault();

            handleSubmit()
        }
    }

    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                right: 0,
                width: '100%',
                minHeight: '100vh',
                zIndex: show ? 2 : -1,
                backgroundColor: '#00000059',
                opacity: show ? 1 : 0,
                transition: 'zIndex .2s'
            }}>
            <Container
                style={{minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Row style={{
                    backgroundColor: '#f5f5f5',
                    boxShadow: '0px 4px 5px 0px rgba(0,0,0,0.3)',
                    width: '100%',
                    maxWidth: 700,
                    borderRadius: 15,
                    paddingBottom: 20
                }}>
                    <Row style={{width: '100%', marginTop: 30, marginLeft: 15, marginRight: 15, marginBottom: 40}}>
                        <Col xs={9} style={{fontSize: '1.2em', fontFamily: 'Roboto', fontWeight: '400'}}>
                            {selectedBank.id ? 'Update Bank' : 'Add New Bank'}
                        </Col>

                        <Col style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <AiOutlineClose
                                style={{cursor: 'pointer'}} size={27} onClick={onClose}/>
                        </Col>
                    </Row>

                    <Row style={{width: '100%', marginLeft: 15, marginRight: 15}}>
                        <Col xs={12} sm={5} md={4} style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: 0,
                            fontFamily: 'Roboto',
                            fontWeight: '400'
                        }}>
                            Bank Name
                        </Col>
                        <Col>
                            <Form.Control
                                onKeyPress={onKeyPress}
                                onChange={(e) => setBankName(e.target.value)}
                                value={bankName}
                                style={{
                                    fontSize: "1em",
                                    borderRadius: 0,
                                    borderColor: 'black',
                                    color: 'grey',
                                    fontFamily: 'Roboto',
                                    fontWeight: '500'
                                }}/>
                        </Col>
                    </Row>

                    <Row style={{width: '100%', marginTop: 30}}>
                        <Col style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button style={{...styles.button}} onClick={handleSubmit}>
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </Row>
            </Container>
        </div>
    )
}
