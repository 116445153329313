import AppContainer from "../reusables/AppContainer";
import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import {Col} from "react-bootstrap";
import styles from "../styles";
import {FaAngleDown, FaCaretDown, FaCaretUp, FaCheck, FaFilter, FaSearch, FaTimes, FaTrashAlt} from "react-icons/fa";
import InputBase from "@material-ui/core/InputBase";
import Palette from "../Palette";
import Row from "react-bootstrap/Row";
import Select, {components} from "react-select";
import {AiOutlineClose} from "react-icons/ai";
import {MdSort} from "react-icons/md";
import OrderCard from "../reusables/OrderCard";
import Table from "react-bootstrap/Table";
import {RiPencilFill} from "react-icons/ri";
import {BsFillTrashFill} from "react-icons/bs";
import Button from "@material-ui/core/Button";
import CustomerDao from "../daos/CustomerDao";
import util from "../util/util";
import moment from "moment";
import _ from "lodash";
import {useHistory} from "react-router-dom";
import ConfirmationModal from "../reusables/ConfirmationModal";
import CURRENCIES from "../assets/values/CURRENCIES";

export default function CustomerPage() {
    const [nameOptions, setNameOptions] = useState([]);
    const [bankNameOptions, setBankNameOptions] = useState([]);
    const [currencyOptions, setCurrencyOptions] = useState([]);

    const history = useHistory();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isFilterExpanded, setFilterExpanded] = useState(false);

    const [customers, setCustomers] = useState([])

    const DropdownIndicator = props => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <FaAngleDown color={'black'}/>
                </components.DropdownIndicator>
            )
        );
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        getAll()
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    const getAll = async () => {
        let result = await CustomerDao.getAll()
        const nameTemp = [];
        const bankTemp = [];
        const currencyTemp = [];


        result.map(customer => {
            console.log(customer.id, customer.country_of_incorporation)

            if (nameTemp.length === 0) nameTemp.push({
                value: customer.primary_name,
                label: customer.primary_name
            });
            else {
                let found = false;

                nameTemp.map(obj => {
                    if (JSON.stringify(obj) === JSON.stringify({
                        value: customer.primary_name,
                        label: customer.primary_name,
                    })) found = true;
                })

                if (!found) nameTemp.push({value: customer.primary_name, label: customer.primary_name});
            }

            if (currencyTemp.length === 0) currencyTemp.push({
                value: CURRENCIES[customer.country_of_incorporation],
                label: CURRENCIES[customer.country_of_incorporation],
            });
            else {
                let found = false;

                currencyTemp.map(obj => {
                    if (JSON.stringify(obj) === JSON.stringify({
                        value: CURRENCIES[customer.country_of_incorporation],
                        label: CURRENCIES[customer.country_of_incorporation]
                    })) found = true;
                })

                if (!found) currencyTemp.push({
                    value: CURRENCIES[customer.country_of_incorporation],
                    label: CURRENCIES[customer.country_of_incorporation],
                });
            }


        })


        setNameOptions(nameTemp);
        setCurrencyOptions(currencyTemp);
        setCustomers(result)
        console.log(result)
    }

    const approve = async (id) => {
        CustomerDao.approve(id).then(res => {
            if (res.success) getAll()
        }).catch(err => console.log(err))
    }

    const reject = async (id) => {
        CustomerDao.reject(id).then(res => {
            if (res.success) getAll()
            setConfirmationModalShown(false)
        }).catch(err => console.log(err))
    }

    const del = async (id) => {
        CustomerDao.del(id).then(res => {
            if (res.success) getAll()
            setDeleteShown(false)
        }).catch(err => console.log(err))
    }

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    }

    const sorterFields = [{
        key: "id",
        label: "Customer ID"
    }, {
        key: "primary_name",
        label: "Customer Name",
    }, {
        key: "account_type",
        label: "Customer Type",
    }, {
        key: "created_at",
        label: "Registration Date",
    }, {
        key: "email",
        label: "Email Address",
    }]

    const formattedSorterFields = []

    sorterFields.forEach((obj, key) => {
        formattedSorterFields.push({
            value: key * 2,
            sorter: obj.key,
            label: obj.label + " ASC",
            order: 1,
        })
        formattedSorterFields.push({
            value: key * 2 + 1,
            sorter: obj.key,
            label: obj.label + " DESC",
            order: -1,
        })
    })

    const [chosenSorterField, setChosenSorterField] = useState(null)
    const [chosenSorterFieldLabel, setChosenSorterFieldLabel] = useState(null)
    const [chosenSorterFieldOrder, setChosenSorterFieldOrder] = useState(1)
    const [isConfirmationModalShown, setConfirmationModalShown] = useState(false);
    const [isDeleteShown, setDeleteShown] = useState(false);
    const [selectedId, setSelectedId] = useState(-1);

    const sortData = (arr) => {

        console.log(arr)

        if (chosenSorterField === null) {
            return arr
        }

        let tempArr = arr.map((obj) => {
            if (typeof obj[chosenSorterField] === "string" || obj[chosenSorterField] instanceof String) {
                let tempObj = {...obj}
                tempObj[chosenSorterField] = tempObj[chosenSorterField].toLowerCase()
                return tempObj
            } else {
                return obj
            }
        })
        console.log(tempArr)

        let orderedBy = _.orderBy(tempArr, [chosenSorterField], [chosenSorterFieldOrder === 1 ? 'asc' : 'desc'])

        console.log(orderedBy)

        return orderedBy

    }

    const sortBtn = (
        <Col xs={12} md={6} style={{marginTop: windowWidth < 768 ? 30 : 15}}>
            <a href={'#'}
               style={{
                   color: Palette.COLOR_PRIMARY,
                   fontWeight: '600',
                   opacity: !isFilterExpanded ? 1 : 0,
                   height: !isFilterExpanded ? '100%' : 0
               }}
               onClick={(e) => {
                   e.preventDefault();
                   setFilterExpanded(true)
               }}>
                Sort and filter

                <FaCaretDown color={Palette.COLOR_PRIMARY} style={{marginLeft: 10}}/>
            </a>

            <div style={{
                color: Palette.COLOR_PRIMARY,
                height: isFilterExpanded ? '100%' : 0,
                opacity: isFilterExpanded ? 1 : 0,
                transition: 'opacity .15s'
            }}>
                <Row>
                    <Col xs={5} lg={4}>
                        <MdSort size={22} style={{marginRight: 15}}/>

                        <span style={{fontWeight: '600', fontSize: '1.05em'}}>Sort By</span>
                    </Col>

                    <Col style={{paddingLeft: 0}} xs={7} lg={4}>
                        <Select
                            options={formattedSorterFields}
                            onChange={(obj, key) => {
                                console.log(obj, key)
                                setChosenSorterFieldOrder(obj.order)
                                setChosenSorterField(obj.sorter)
                                setChosenSorterFieldLabel(obj.label)
                            }}
                            styles={styles.select}
                            components={{DropdownIndicator, IndicatorSeparator: () => null}}
                        />
                    </Col>
                </Row>
            </div>
        </Col>
    );

    const deleteFilter = (filter) => {
        let deletedIdx = -1;

        filters.map((aFilter, idx) => {
            if (aFilter === filter) deletedIdx = idx;
        })

        const filtersTemp = [...filters];

        filtersTemp.splice(deletedIdx, 1);

        setFilters(filtersTemp);
    }

    const [filters, setFilters] = useState([]);
    const addFilter = (filter) => {
        let found = false;

        filters.map((aFilter, idx) => {
            if (aFilter.type === filter.type && aFilter.value === filter.value) {
                const filtersTemp = [...filters];
                filtersTemp[idx] = filter;
                found = true;

                setFilters(filtersTemp)
            }
        })

        if (!found) {
            const filtersTemp = [...filters];
            filtersTemp.push(filter);
            setFilters(filtersTemp);
        }
    }

    const [searchKeyword, setSearchKeyword] = useState('');

    const filterCustomer = (obj, key) => {
        console.log(obj)

        if (!obj.primary_name.toLowerCase().includes(searchKeyword.toLowerCase())) return;

        const customerRow = (
            <tr key={key}
                style={{backgroundColor: key % 2 === 0 ? null : '#dce1e7'}}
            >
                <td>{key + 1}</td>
                <td>{util.idFormatter(obj.id, 3, "customer")}</td>
                <td>{obj.primary_name}</td>
                <td>{util.toTitleCase(obj.account_type)}</td>
                <td>{new moment(obj.created_at).format("YYYY-MM-DD HH:mm:ss")}</td>
                <td>{obj.email}</td>
                <td style={{minWidth: 200}}>
                    <Row>
                        <Col>
                            <BsFillTrashFill color={'red'} size={20} style={{cursor: 'pointer'}} onClick={() => {
                                setSelectedId(obj.id)
                                setDeleteShown(true)
                            }}/>
                        </Col>

                        <Col>
                            {obj.approval_status === 'WAITING' || obj.approval_status === 'APPROVED' ?
                                <FaTimes color={'red'} size={20} style={{cursor: 'pointer'}} onClick={() => {
                                    setSelectedId(obj.id)
                                    setConfirmationModalShown(true)
                                }}/> : null}
                        </Col>

                        <Col>
                            <RiPencilFill color={'#8295e0'} size={20}
                                          style={{cursor: 'pointer'}}
                                          onClick={() => history.push(`/customer/details/${obj.id}`)}/>
                        </Col>

                        <Col>
                            {obj.approval_status === 'WAITING' || obj.approval_status === 'REJECTED' ?
                                <FaCheck color={'#74cc66'} style={{cursor: 'pointer'}} size={20}
                                         onClick={() => approve(obj.id)}/> : null}
                        </Col>
                    </Row>
                    {/*<span>*/}
                    {/*    <FaTimes color={'red'} size={23} style={{marginLeft: 15}}/>*/}
                    {/*</span>*/}

                    {/*<span>*/}
                    {/*    <RiPencilFill color={'#8295e0'} size={23}*/}
                    {/*              style={{cursor: 'pointer', marginLeft: 15}}*/}
                    {/*              onClick={() => history.push('/customer/details')}/>*/}
                    {/*</span>*/}

                    {/*<span>*/}
                    {/*    <FaCheck color={'#74cc66'} size={20}/>*/}
                    {/*</span>*/}
                </td>
            </tr>
        )

        let filtersResult = {};

        if (filters.length !== 0) {
            let show = true;

            for (let idx = 0; idx < filters.length; idx++) {
                const filter = filters[idx];

                if (filtersResult[filter.type]) {
                    filtersResult[filter.type].filters.push(filter.value)
                } else {
                    filtersResult = {
                        ...filtersResult,
                        [filter.type]: {
                            filters: [filter.value],
                            found: false
                        }
                    }
                }
                // const filterType = filter.type;
                //
                //
                // if (filterType === 'Name' && filter.value !== obj.primary_name) {
                //     show = false;
                //     break;
                // }
                //
                // if (filterType === 'Currency' && filter.value !== CURRENCIES[obj.country_of_incorporation]) {
                //     show = false;
                //     break;
                // }
            }

            Object.keys(filtersResult).map(key => {
                filtersResult[key].filters.map(filter => {
                    if (key === 'Name' && filter === obj.primary_name) {
                        filtersResult[key].found = true
                    } else if (key === 'Currency' && filter === CURRENCIES[obj.country_of_incorporation]) {
                        filtersResult[key].found = true
                    }
                })
            })

            Object.keys(filtersResult).map(key => {
                if (!filtersResult[key].found) show = false
            })

            if (show) return customerRow
        } else return customerRow;
    }

    return (
        <AppContainer active={'Customers'}>
            <ConfirmationModal show={isConfirmationModalShown} onConfirm={() => reject(selectedId)}
                               noDelete
                               onCancel={() => setConfirmationModalShown(false)}
                               title={'Are you sure you want to reject this account?'}/>

            <ConfirmationModal show={isDeleteShown} onConfirm={() => {
                del(selectedId)
            }}
                               noDelete
                               onCancel={() => setDeleteShown(false)}
                               title={'Are you sure you want to delete this account?'}/>

            <Container style={{paddingBottom: 100}}>
                <Row>
                    <Col
                        style={{...styles.header, marginBottom: 50}}>
                        Customer List
                    </Col>
                </Row>

                <Row>
                    {
                        windowWidth < 768 ? null : sortBtn
                    }
                    <Col style={{
                        display: windowWidth < 768 ? 'block' : 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-start'
                    }}>
                        <div style={{
                            border: '1px #d4d4d4 solid',
                            borderRadius: 10,
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <FaSearch color={Palette.COLOR_PRIMARY} style={{marginRight: 20, marginLeft: 15}}/>

                            <InputBase
                                style={{fontSize: '.9em'}}
                                placeholder="Type keyword here..."
                                onChange={(e) => setSearchKeyword(e.target.value)}
                            />
                        </div>
                    </Col>

                    {
                        windowWidth < 768 ? sortBtn : null
                    }
                </Row>

                <div style={{
                    height: isFilterExpanded ? '100%' : 0,
                    opacity: isFilterExpanded ? 1 : 0,
                    transition: 'opacity .15s'
                }}>
                    <Row style={{marginTop: 20}}>
                        <Col xs={12} md={6}>

                            <div style={{color: Palette.COLOR_PRIMARY}}>
                                <Row>
                                    <Col xs={5} lg={4}>
                                        <FaFilter size={14} style={{marginRight: 23}}/>

                                        <span style={{fontWeight: '600', fontSize: '1.05em'}}>Filter By</span>
                                    </Col>

                                    <Col>
                                        <Row>
                                            <Col md={12} lg={6} style={{paddingLeft: 0, marginBottom: 10}}>
                                                <Select
                                                    value={null}
                                                    placeholder={'Name'}
                                                    onChange={(option) => addFilter({
                                                        type: 'Name',
                                                        value: option.value
                                                    })}
                                                    options={nameOptions}
                                                    styles={styles.select}
                                                    components={{DropdownIndicator, IndicatorSeparator: () => null}}
                                                />
                                            </Col>

                                            <Col md={12} lg={6} style={{paddingLeft: 0, marginBottom: 10}}>
                                                <Select
                                                    value={null}
                                                    placeholder={'Currency'}
                                                    options={currencyOptions}
                                                    onChange={(option) => addFilter({
                                                        type: 'Currency',
                                                        value: option.value
                                                    })}
                                                    styles={styles.select}
                                                    components={{DropdownIndicator, IndicatorSeparator: () => null}}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </Col>

                        {/*<Col>*/}

                        {/*    <Row>*/}
                        {/*        {windowWidth < 768 ? <Col xs={5}/> : null}*/}
                        {/*        <Col>*/}
                        {/*            <Row>*/}

                        {/*                <Col md={6} lg={4} style={{paddingLeft: 0, marginBottom: 10}}>*/}
                        {/*                    <Select*/}
                        {/*                        placeholder={'Bank Name'}*/}
                        {/*                        // defaultValue={optionsArr[0]}*/}
                        {/*                        options={[]}*/}
                        {/*                        styles={styles.select}*/}
                        {/*                        components={{DropdownIndicator, IndicatorSeparator: () => null}}*/}
                        {/*                    />*/}
                        {/*                </Col>*/}
                        {/*            </Row>*/}
                        {/*        </Col>*/}
                        {/*    </Row>*/}
                        {/*</Col>*/}
                    </Row>

                    <Row style={{marginTop: 10}}>
                        <Col style={{display: 'flex', flexWrap: 'wrap'}} xs={12} md={8}>
                            {
                                chosenSorterField ?
                                    <div style={{
                                        borderRadius: 50,
                                        backgroundColor: '#e6e7e8',
                                        paddingLeft: 20,
                                        paddingTop: 8,
                                        paddingBottom: 8,
                                        display: 'flex',
                                        alignItems: 'center',
                                        paddingRight: 10,
                                        marginRight: 10
                                    }}>
                                        {chosenSorterFieldLabel}
                                        <AiOutlineClose
                                            onClick={() => {
                                                setChosenSorterFieldLabel("")
                                                setChosenSorterField(null)
                                                setChosenSorterFieldOrder(1)
                                            }}
                                            style={{
                                                marginTop: 3, marginLeft: 10, cursor: 'pointer'
                                            }}
                                        />
                                    </div>
                                    :
                                    null
                            }

                            {
                                filters.map(
                                    filter => {
                                        return (
                                            <div style={{
                                                borderRadius: 50,
                                                backgroundColor: '#e6e7e8',
                                                paddingLeft: 20,
                                                paddingTop: 8,
                                                paddingBottom: 8,
                                                display: 'flex',
                                                alignItems: 'center',
                                                paddingRight: 10,
                                                marginRight: 10,
                                                marginTop: 10
                                            }}>
                                                {filter.type}: {filter.type === 'Customer' || filter.type === 'Status' ? filter.value.label : filter.type === 'Date' ? moment(filter.value.startDate).format('DD/MM/YYYY') + ' - ' + moment(filter.value.endDate).format('DD/MM/YYYY') : filter.value}
                                                <AiOutlineClose
                                                    onClick={() => deleteFilter(filter)}
                                                    style={{marginTop: 3, marginLeft: 10, cursor: 'pointer'}}/>
                                            </div>
                                        )
                                    }
                                )
                            }
                        </Col>

                        <Col style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
                            <a href={'#'}
                               style={{color: Palette.COLOR_PRIMARY, fontWeight: '600'}}
                               onClick={(e) => {
                                   e.preventDefault();
                                   setFilterExpanded(false)
                               }}>
                                Collapse

                                <FaCaretUp color={Palette.COLOR_PRIMARY} style={{marginLeft: 10}}/>
                            </a>
                        </Col>
                    </Row>

                    <Row style={{
                        width: '100%',
                        height: 1,
                        backgroundColor: '#878787',
                        marginLeft: 0,
                        marginTop: 15,
                        marginBottom: 30
                    }}/>
                </div>

                <Row style={{
                    marginLeft: 0,
                    marginRight: 0,
                    marginTop: 25,
                    width: '100%'
                }}>
                    <Table responsive>
                        <thead>
                        <tr style={{
                            backgroundColor: '#b8babf',
                            fontFamily: 'Roboto',
                            fontWeight: '500',
                            color: 'white',
                            textAlign: 'center'
                        }}>
                            <th>
                                #
                            </th>

                            <th>
                                Customer ID
                            </th>

                            <th>
                                Customer Name
                            </th>

                            <th>
                                Customer Type
                            </th>

                            <th>
                                Registration Date
                            </th>

                            <th>
                                Email Address
                            </th>

                            <th>
                                Actions
                            </th>
                        </tr>
                        </thead>

                        <tbody style={{textAlign: 'center'}}>

                        {
                            sortData(customers).map((obj, key) => {
                                return filterCustomer(obj, key)
                            })
                        }

                        </tbody>
                    </Table>
                </Row>

            </Container>
        </AppContainer>
    )
}
