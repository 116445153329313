import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import styles from "../styles";
import DefaultTextInput from "../reusables/DefaultTextInput";
import AppContainer from "../reusables/AppContainer";
import React, {useEffect, useState} from "react";
import DefaultCheckBox from "../reusables/DefaultCheckBox";
import Col from "react-bootstrap/Col";
import Palette from "../Palette";
import Table from "react-bootstrap/Table";
import {AiOutlineClose} from "react-icons/ai";
import Button from "@material-ui/core/Button";
import AdminDao from "../daos/AdminDao";
import util from "../util/util";
import {useHistory} from "react-router-dom";
import GlobalData from "../util/GlobalData";

export default function SignInPage() {

    const [errors, setErrors] = useState({});

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [rememberMe, setRememberMe] = useState(false)

    const history = useHistory()

    useEffect(() => {
        if (localStorage.token) {
            history.push("/dashboard")
        }

    }, [])

    const submit = async () => {
        const errorsTemp = {};
        setErrors(errorsTemp);

        if (!util.validateEmail(email)) errorsTemp["EMAIL"] = "Please enter a valid email";
        if (!password) errorsTemp["PASSWORD"] = 'Password cannot be blank';

        if (Object.keys(errorsTemp).length === 0) {
            try {

                let result = await AdminDao.login({email, password})

                if(rememberMe) localStorage.token = result.token;
                else sessionStorage.token = result.token;
                history.push("/dashboard")
            } catch (e) {

                console.log(e)
                let temp = {...errors}

                if (e.code === "CREDENTIAL_NOT_FOUND") {
                    temp["EMAIL"] = "Invalid Credential"
                    temp["PASSWORD"] = "Invalid Credential"
                } else if (e.code === 'ACCOUNT_LOCKED') {
                    temp["EMAIL"] = "Your account is locked"
                }

                setErrors(temp)
            }
        }
    }

    const onKeyPress = (ev) => {
        if (ev.key === 'Enter') {
            ev.preventDefault();

            submit()
        }
    }

    return (
        <AppContainer>
            <Container>
                <Row style={styles.header}>
                    Admin Sign In
                </Row>

                <DefaultTextInput
                    onKeyPress={onKeyPress}
                    onChange={(e) => setEmail(e.target.value)}
                    field={'EMAIL'} type={'email'} errors={errors}/>

                <DefaultTextInput
                    onKeyPress={onKeyPress}
                    onChange={(e) => setPassword(e.target.value)}
                    field={'PASSWORD'} type={'password'} errors={errors}/>

                <DefaultCheckBox
                    onChange={(e) => {
                        console.log("result", e)
                        setRememberMe(e)
                    }}
                    description={'Remember Me'}/>

                <Row style={{marginBottom: 5, marginTop: 20, display: 'flex', justifyContent: 'center'}}>
                    <Button onClick={() => submit()}
                            style={{...styles.button, paddingTop: 10, paddingBottom: 10}}>
                        Sign In
                    </Button>
                </Row>
            </Container>
        </AppContainer>
    )
}
