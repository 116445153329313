import React from "react";

const LineIndicator = ({title, width, value, color}) => {

    return (
        <>
            <p style={{fontFamily: 'NoirPro-Regular', lineHeight: 1.3, fontSize: 14, color: '#545454'}}>{title}</p>
            <div className="gx-line-indi-info">
                <div className={`gx-line-indi gx-bg-${color}`} style={{
                    width: Number.parseInt(width, 10) * 4
                }}/>

                <span className="gx-line-indi-count gx-ml-2" style={{fontFamily: 'NoirPro-Regular', lineHeight: 1.3, fontSize: 14, color: '#545454'}}>{value}</span>
            </div>
        </>

    );
};

export default LineIndicator;
