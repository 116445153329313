import httpRequest from "../util/httpRequest"

export default class CustomerDao {

    static getAll = async () => {
        return new Promise(
            await httpRequest.set('v1/customers', 'GET')
        )
    }

    static getStats = async () => {
        return new Promise(
            await httpRequest.set('v1/customers/stats', 'GET')
        )
    }

    static getCustomerInfo = async (id) => {
        return new Promise(
            await httpRequest.set(`v1/customer/${id}`, 'GET')
        )
    }

    static approve = async (id) => {
        return new Promise(
            await httpRequest.set(`v1/customer/${id}/approve`, 'POST')
        )
    }

    static reject = async (id) => {
        return new Promise(
            await httpRequest.set(`v1/customer/${id}/reject`, 'POST')
        )
    }

    static del = async (id) => {
        return new Promise(
            await httpRequest.set(`v1/customer/${id}/delete`, 'POST')
        )
    }

    static update = async (id, body) => {
        return new Promise(
            await httpRequest.set(`v1/customer/${id}`, 'PUT', JSON.stringify(body))
        )
    }

    static changePassword = async (id, body) => {
        return new Promise(
            await httpRequest.set(`v1/customer/${id}/change_password`, 'PUT', JSON.stringify(body))
        )
    }
}
