import Container from "react-bootstrap/Container";
import React from "react";
import Row from "react-bootstrap/Row";
import styles from "../styles";
import AppContainer from "../reusables/AppContainer";
import Button from "@material-ui/core/Button";

export default function PageNotFound() {
    return (
        <AppContainer>
            <Container style={{
                minHeight: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                marginTop: -150
            }}>
                <Row style={{
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    fontSize: '10em',
                    fontWeight: '600',
                    color: '#737373',
                    marginBottom: 10
                }}>
                    404
                </Row>

                <Row style={styles.subHeader}>
                    Oops, an error has occurred.<br/>Page not found!
                </Row>

                <Row style={{marginBottom: 5, marginTop: 10, display: 'flex', justifyContent: 'center'}}>
                    <Button style={{...styles.button, fontSize: '.85em'}}>
                        Go to Home
                    </Button>
                </Row>
            </Container>
        </AppContainer>
    )
}