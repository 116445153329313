import httpRequest from "../util/httpRequest"

export default class ComplainDao {
    static resolved = async (id) => {
        return new Promise(
            await httpRequest.set(`v1/complaint/${id}/resolved`, 'POST')
        )
    }

    static resolving = async (id) => {
        return new Promise(
            await httpRequest.set(`v1/complaint/${id}/resolving`, 'POST')
        )
    }

    static getAll = async () => {
        return new Promise(
            await httpRequest.set(`v1/complaints`, 'GET')
        )
    }
}