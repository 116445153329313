import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import React, {useEffect, useRef, useState} from "react";
import {AiOutlineClose} from "react-icons/ai";
import Button from "react-bootstrap/Button";
import styles from "../styles";
import moment from "moment";
import ORDER_STEPS from "../assets/values/ORDER_STEPS";

export default function ReceiptModal(props) {
    const {
        show,
        onClose,
        windowWidth,
        order
    } = props;

    const containerRef = useRef(null);
    const [containerWidth, setContainerWidth] = useState(0);
    const [isTableShown, setTableShown] = useState(false);

    const disableScrolling = () => {
        const x = window.scrollX;
        const y = window.scrollY;
        window.onscroll = function () {
            window.scrollTo(x, y);
        };
    }

    const enableScrolling = () => {
        window.onscroll = function () {
        };
    }

    useEffect(() => {
        if (show) disableScrolling()
        else enableScrolling()

        // setTableShown(false)
        //
        // setTimeout(() => {
        //     setContainerWidth(containerRef.current.clientWidth)
        //
        //     setTimeout(() => {
        //         setTableShown(true)
        //     }, 50)
        // }, 50)
    }, [show, windowWidth, containerRef])

    const renderOldBody = () => {
        return <><Row style={{
            width: '100%',
            backgroundColor: '#4f4f4f',
            height: 1,
            marginLeft: 0,
            marginRight: 0,
            marginTop: 30,
            marginBottom: 30
        }}/>

            <Row style={{width: '100%'}}>
                <Col style={{fontWeight: '600'}} xs={12} md={6}>
                    Bill from

                    <Row style={{marginTop: 6, marginLeft: 0}}>
                        Money Transfer Company, Ltd.
                    </Row>

                    <Row style={{marginLeft: 0, fontWeight: '400'}}>
                        money@transfer.com<br/>
                        30 Rock Street,<br/>
                        Indonesia 12345<br/>
                        Phone +6221-50123456
                    </Row>
                </Col>

                <Col style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    textAlign: windowWidth < 768 ? 'left' : 'right',
                    fontWeight: '600',
                    marginTop: windowWidth < 768 ? 30 : 0
                }}>
                    <Row style={{
                        display: 'flex',
                        justifyContent: windowWidth < 768 ? 'flex-start' : 'flex-end',
                        marginLeft: 0
                    }}>
                        Bill to
                    </Row>

                    <Row style={{
                        display: 'flex',
                        justifyContent: windowWidth < 768 ? 'flex-start' : 'flex-end',
                        marginLeft: 0
                    }}>
                        John Doe
                    </Row>

                    <Row style={{
                        display: 'flex',
                        justifyContent: windowWidth < 768 ? 'flex-start' : 'flex-end',
                        marginLeft: 0,
                        fontWeight: '400'
                    }}>
                        john@doe.com<br/>
                        Sudirman Street 15,<br/>
                        Indonesia 54321<br/>
                        Phone +6221-87654321
                    </Row>
                </Col>
            </Row>

            <Row style={{width: '100%', height: 1, marginLeft: 0}} ref={containerRef}/>

            <Row style={{
                marginLeft: 0,
                marginRight: 0,
                maxWidth: containerWidth,
                marginTop: 25,
                width: '100%'
            }}>
                <Table responsive style={{border: '2px solid #c0c0c0', display: isTableShown ? 'table' : 'none'}}>
                    <thead>
                    <tr style={{backgroundColor: '#dce1e7'}}>
                        <th style={{
                            fontWeight: '500',
                            border: '2px solid #c0c0c0',
                            borderWidth: '0px 0px 2px 0px'
                        }}>
                            #
                        </th>

                        <th style={{
                            fontWeight: '500',
                            border: '2px solid #c0c0c0',
                            borderWidth: '0px 0px 2px 0px'
                        }}>
                            Order ID
                        </th>

                        <th style={{
                            fontWeight: '500',
                            border: '2px solid #c0c0c0',
                            borderWidth: '0px 0px 2px 0px'
                        }}>
                            Transfer Amount
                        </th>

                        <th style={{
                            fontWeight: '500',
                            border: '2px solid #c0c0c0',
                            borderWidth: '0px 0px 2px 0px'
                        }}>
                            Service Fee
                        </th>

                        <th style={{
                            fontWeight: '500',
                            border: '2px solid #c0c0c0',
                            borderWidth: '0px 0px 2px 0px'
                        }}>
                            Total Payment Amount
                        </th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr>
                        <td style={{border: '1px solid #c0c0c0', borderWidth: '0px 0px 1px 0px'}}>1</td>
                        <td style={{border: '1px solid #c0c0c0', borderWidth: '0px 0px 1px 0px'}}>11111101</td>
                        <td style={{border: '1px solid #c0c0c0', borderWidth: '0px 0px 1px 0px'}}>IDR 14,950,000</td>
                        <td style={{border: '1px solid #c0c0c0', borderWidth: '0px 0px 1px 0px'}}>IDR 50,000</td>
                        <td style={{border: '1px solid #c0c0c0', borderWidth: '0px 0px 1px 0px'}}>IDR 15,000,000</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>11111102</td>
                        <td>IDR 10,050,000</td>
                        <td>IDR 50,000</td>
                        <td>IDR 10,100,000</td>
                    </tr>
                    </tbody>
                </Table>
            </Row>

            {/*<Row style={{marginLeft: 20, width: '100%'}}>*/}
            {/*    <span style={{fontWeight: '600', marginRight: 5}}>Exchange rate:</span> 1 SGD = 10,000*/}
            {/*</Row>*/}

            <Row style={{
                marginLeft: 20,
                marginBottom: 50,
                fontWeight: '600',
                marginRight: 10,
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end'
            }}>
                Grand Total: IDR 25,100,000
            </Row>
        </>
    }

    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                right: 0,
                width: '100%',
                minHeight: '100vh',
                zIndex: show ? 2 : -1,
                backgroundColor: '#00000059',
                opacity: show ? 1 : 0,
                transition: 'zIndex .2s',
                maxHeight: '100%',
                overflowY: 'scroll',
                paddingTop: 30,
                paddingBottom: 30,
            }}>
            <Container
                style={{minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 50}}>
                <Row style={{
                    backgroundColor: '#f7f7f7',
                    boxShadow: '0px 4px 5px 0px rgba(0,0,0,0.3)',
                    width: '100%',
                    maxWidth: 800,
                    borderRadius: 15,
                    paddingBottom: 50,
                }}>
                    <Row style={{
                        width: '100%',
                        marginTop: 30,
                        marginLeft: 15,
                        marginRight: 15,
                        marginBottom: 40
                    }}>
                        <Col xs={9}/>

                        <Col style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <AiOutlineClose
                                onClick={onClose}
                                style={{cursor: 'pointer'}} size={27}/>
                        </Col>
                    </Row>

                    <Row style={{
                        paddingTop: 20,
                        paddingLeft: 15,
                        paddingRight: 15,
                        backgroundColor: 'white',
                        border: '1px solid grey',
                        width: '100%',
                        marginLeft: 20,
                        marginRight: 20,
                        display: 'flex',
                        flexDirection: 'row'
                    }}>
                        <Col>
                            <Row style={{fontWeight: '600'}}>
                                Receipt
                            </Row>

                            <Row style={{fontWeight: '600'}}>
                                #{order.order_number}
                            </Row>
                        </Col>

                        <Col>
                            <Row style={{display: 'flex', justifyContent: 'flex-end', textAlign: 'right'}}>
                                            <span
                                                style={{
                                                    fontWeight: '600',
                                                    marginRight: 5
                                                }}>Order Status:</span> {ORDER_STEPS[order.current_order_step]}
                            </Row>

                            <Row style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                textAlign: 'right',
                                marginTop: 5
                            }}>
                                <span style={{fontWeight: '600', marginRight: 5}}> Order Date:</span>
                                {new moment(order.created_at).format("MMMM Do, YYYY")}
                            </Row>
                        </Col>

                        <Col md={12} style={{display: 'flex', justifyContent: 'center', paddingTop: 40, paddingBottom: 35}}>
                            <img src={order.receipt_image_url}/>
                        </Col>

                    </Row>

                    {/*<Row style={{*/}
                    {/*    width: '100%',*/}
                    {/*    maxWidth: 800,*/}
                    {/*    display: 'flex',*/}
                    {/*    justifyContent: 'flex-end',*/}
                    {/*    marginRight: 20*/}
                    {/*}}>*/}
                    {/*    <Button style={{...styles.button, marginTop : 20, marginBottom: 0}}>Print</Button>*/}
                    {/*</Row>*/}
                </Row>


            </Container>
        </div>
    )
}
