import httpRequest from "../util/httpRequest"

export default class OrderDAO {

    static getAllUnconfirmed = async () => {
        return new Promise(
            await httpRequest.set('v1/orders/ORDER_CREATED', 'GET')
        )
    }

    static getAllProcessing = async () => {
        return new Promise(
            await httpRequest.set('v1/orders/three_last_steps', 'GET')
        )
    }

    static rejectOrder = async (orderNumber) => {
        return new Promise(
            await httpRequest.set(`v1/order/${orderNumber}/change`, 'PUT',JSON.stringify({
                order_step : "ORDER_REJECTED"
            }))
        )
    }

    static rejectPayment = async (orderNumber) => {
        return new Promise(
            await httpRequest.set(`v1/order/${orderNumber}/change`, 'PUT',JSON.stringify({
                order_step : "PAYMENT_REJECTED"
            }))
        )
    }

    static acceptOrder = async (orderNumber) => {
        return new Promise(
            await httpRequest.set(`v1/order/${orderNumber}/approve_order`, 'POST',JSON.stringify({
                order_step : "WAITING_PAYMENT"
            }))
        )
    }

    static acceptPayment = async (orderNumber) => {
        return new Promise(
            await httpRequest.set(`v1/order/${orderNumber}/approve_payment`, 'POST',JSON.stringify({}))
        )
    }

    static processOrder = async (orderNumber) => {
        return new Promise(
            await httpRequest.set(`v1/order/${orderNumber}/process_order`, 'POST',JSON.stringify({}))
        )
    }

    static getAllNeedConfirmation = async () => {
        return new Promise(
            await httpRequest.set('v1/orders/second_mid_steps', 'GET')
        )
    }

    static getAllForReport = async () => {
        return new Promise(
            await httpRequest.set('v1/orders/ORDER_PROCESSED', 'GET')
        )
    }

    static updateRate = async (orderId, rate, fee) =>{
        return new Promise(
            await httpRequest.set(`v1/order/${orderId}/update_rate`, 'POST', JSON.stringify({
                rate : rate,
                fee: fee
            }))
        )
    }

    static getHistory = async (id) => {
        return new Promise(
            await httpRequest.set(`v1/order/${id}/history`, 'GET')
        )
    }

    static sawChange = async (id) => {
        return new Promise (
            await httpRequest.set(`v1/complaint/${id}/saw_change`, 'PUT')
        )
    }
}
