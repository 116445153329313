import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import styles from "../styles";
import DefaultTextInput from "../reusables/DefaultTextInput";
import DefaultSelectInput from "../reusables/DefaultSelectInput";
import AppContainer from "../reusables/AppContainer";
import React, {useEffect, useState} from "react";
import DefaultCheckBox from "../reusables/DefaultCheckBox";
import Col from "react-bootstrap/Col";
import Palette from "../Palette";
import CustomerDao from "../daos/CustomerDao";
import AdminDao from "../daos/AdminDao";
import Button from "@material-ui/core/Button";

export default function ForgotPasswordPage(props) {
    const [errors, setErrors] = useState({});
    const [newPassword, setNewPassword] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState(null);
    const [object, setObject] = useState({});
    useEffect(() => {
        if(props.match.params.object === 'customer') {
            CustomerDao.getCustomerInfo(props.match.params.id).then(res => setObject(res)).catch(err => console.log(err))
        } else if (props.match.params.object === 'admin') {
            AdminDao.getById(props.match.params.id).then(res => setObject(res)).catch(err => console.log(err))
        }
        console.log(props.match.params.object)
        console.log(props.match.params.id)
    }, [])

    const attemptSubmit = () => {
        const errorsTemp = {};
        setErrors(errorsTemp);

        if (newPassword !== confirmPassword) errorsTemp["RETYPE NEW PASSWORD"] = "Confirm password doesn't match";
        if (!newPassword) errorsTemp["NEW PASSWORD"] = 'New password cannot be blank';
        if (!confirmPassword) errorsTemp["RETYPE NEW PASSWORD"] = 'New password confirmation cannot be blank';
        if (Object.keys(errorsTemp).length === 0) {
            const body = {
                'new_password': newPassword,
            };

            if(props.match.params.object === 'customer') {
                CustomerDao.changePassword(props.match.params.id, {new_password: newPassword}).then(res => {
                    if(res.success) alert('Password has been updated!')
                }).catch(err => console.log(err))
            } else if (props.match.params.object === 'admin') {
                AdminDao.changeOtherAdminPass(props.match.params.id, {new_password: newPassword}).then(res => {
                    if(res.success) alert('Password has been updated!')
                }).catch(err => console.log(err))
            }


        }
        else setErrors(errorsTemp)
    }

    return (
        <AppContainer active={props.match.params.object === 'customer' ? 'Customers' : 'Accounts'}>
            <Container>
                <Row style={styles.header}>
                    {props.match.params.object === 'customer' ? 'Customer' : 'Admin'} Change Password
                </Row>

                <Row style={styles.subHeader}>
                    Enter a new password for {object.email}
                </Row>

                <DefaultTextInput field={'NEW PASSWORD'} type={'password'} errors={errors}
                                  onChange={event => setNewPassword(event.target.value)}/>

                <DefaultTextInput field={'RETYPE NEW PASSWORD'} type={'password'} errors={errors}
                                  onChange={event => setConfirmPassword(event.target.value)}/>

                <Row style={{marginBottom: 5, marginTop: 20, display: 'flex', justifyContent: 'center'}}>
                    <Button style={styles.button} onClick={attemptSubmit}>
                        Change
                    </Button>
                </Row>
            </Container>
        </AppContainer>
    )
}