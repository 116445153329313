import React, {useEffect, useState} from "react";
import AppContainer from "../reusables/AppContainer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import {Col} from "react-bootstrap";
import styles from "../styles";
import DefaultSelectInput from "../reusables/DefaultSelectInput";
import DefaultTextInput from "../reusables/DefaultTextInput";
import DefaultFilePicker from "../reusables/DefaultFilePicker";
import Button from "@material-ui/core/Button";
import moment from "moment";
import Palette from "../Palette";
import CustomerDao from "../daos/CustomerDao";
import UploadDao from "../daos/UploadDao";
import {Form} from "react-bootstrap"
import ImageViewModal from "../reusables/ImageViewModal";
import ReceiptModal from "../reusables/ReceiptModal";

export default function CustomerDetailPage(props) {
    const [file, setFile] = useState(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [errors, setErrors] = useState({});
    const [user, setUser] = useState({});
    const [errorMsg, setErrorMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);
    const [isUploading, setUploading] = useState(false);
    const [beneficialOwnerType, setBeneficialOwnerType] = useState(null);

    const [isImageModalShown, setIsImageModalShown] = useState(false)

    const [otherCorpCountryValue, setOtherCorpCountryValue] = useState(user.country_of_incorporation && !['Indonesia', 'Malaysia', 'Singapore'].includes(user.country_of_incorporation) ? user.country_of_incorporation : null);

    useEffect(() => {
        if (Object.keys(user).length === 0) getCustomerInfo()

        window.addEventListener('resize', handleResize)

        if (file) {
            setUploading(true)
            uploadId()
        }

        return () => window.removeEventListener('resize', handleResize)
    }, [file])

    const uploadId = () => {
        const formData = new FormData();

        formData.append('upload', file);

        UploadDao.uploadImage('INDIVIDUAL_ID', formData).then(res => {
            setUploading(false);

            const tempUser = {...user};
            tempUser.id_document_image_url = res.location;

            setUser(tempUser);
        }).catch(err => {
            const errorsTemp = {...errors};
            errorsTemp["Upload ID"] = 'An error occured';
            setErrors(errorsTemp);
            console.log(err)
        })
    }

    const getCustomerInfo = () => {
        CustomerDao.getCustomerInfo(props.match.params.id).then(res => {
            setBeneficialOwnerType(res.contact_person ? 'with-owner' : 'no-owner');

            if(res.country_of_incorporation && !['Indonesia', 'Malaysia', 'Singapore'].includes(res.country_of_incorporation)) {
                setOtherCorpCountryValue(res.country_of_incorporation);
                res.country_of_incorporation = 'Other'
            }

            setUser(res)
        }).catch(err => console.log(err));
    }

    const handleResize = () => {
        setWindowWidth(window.innerWidth)
    }

    const attemptUpdate = () => {
        const errorsTemp = {};
        setErrors(errorsTemp);
        setErrorMsg(null);
        setSuccessMsg(null)

        if (!user.primary_name) errorsTemp["FULL NAME"] = 'Full name cannot be blank';
        if (!user.secondary_name) errorsTemp["NICKNAME"] = 'Nickname cannot be blank';
        if (!user.address) errorsTemp["ADDRESS"] = 'Address cannot be blank';
        if (!user.id_number) errorsTemp["NO. KTP"] = 'No. KTP cannot be blank';
        if (!moment(user.date_of_incorporation).isValid() || moment(user.date_of_incorporation).isAfter(moment(new Date()))) errorsTemp["DATE OF BIRTH"] = `Please input a valid date prior to today`;
        if (!user.date_of_incorporation) errorsTemp["DATE OF BIRTH"] = 'Date of birth cannot be blank';
        if (!user.phone_number) errorsTemp["PHONE NUMBER"] = 'Phone number cannot be blank';
        if (!user.contact_person && beneficialOwnerType === 'with-owner') errorsTemp["BENEFICIAL OWNER"] = 'Beneficial owner cannot be blank';
        if (!user.business_nature) errorsTemp["JOB / BUSINESS NATURE"] = 'Job / business nature cannot be blank';
        if (!user.npwp_number) errorsTemp["No. NPWP"] = 'No. NPWP nature cannot be blank';
        if(user.country_of_incorporation === 'Other' && !otherCorpCountryValue) errorsTemp["NATIONALITY"] = 'Nationality cannot be blank';

        if (Object.keys(errorsTemp).length === 0) {
            delete user.created_at;
            delete user.modified_at;
            user.date_of_incorporation = moment(user.date_of_incorporation).format('YYYY-MM-DD')
            if(user.country_of_incorporation === 'Other') user.country_of_incorporation = otherCorpCountryValue
            CustomerDao.update(user.id, user).then(res => {
                if (res.success) {
                    setSuccessMsg('Customer has been updated successfully!')
                }
            }).catch(err => {
                setErrorMsg('An error occured');
            });

        } else {
            setErrorMsg('One or more errors occured. Please check the form again.')
        }
    }

    const approve = async (id) => {
        CustomerDao.approve(id).then(res => {
            if (res.success) {
                const userTemp = {...user};

                userTemp.approval_status = 'APPROVED';

                setUser(userTemp)
            }
        }).catch(err => console.log(err))
    }

    const reject = async (id) => {
        CustomerDao.reject(id).then(res => {
            if (res.success) {
                const userTemp = {...user};

                userTemp.approval_status = 'REJECTED';

                setUser(userTemp)
            }
        }).catch(err => console.log(err))
    }

    function getExtension(path) {
        const basename = path.split(/[\\/]/).pop(),  // extract file name from full path ...
            // (supports `\\` and `/` separators)
            pos = basename.lastIndexOf(".");       // get last position of `.`

        if (basename === "" || pos < 1)            // if file name is empty or ...
            return "";                             //  `.` not found (-1) or comes first (0)

        return basename.slice(pos + 1);            // extract extension ignoring `.`
    }

    return (
        <AppContainer active={'Customers'}>
            <Container style={{paddingBottom: 100}}>
                <Row>
                    <Col
                        style={{...styles.header, marginBottom: 50}}>
                        Customer Account Details
                    </Col>
                </Row>

                <DefaultSelectInput field={'ACCOUNT TYPE'}
                                    onChange={(option) => {
                                        const userTemp = {...user};

                                        userTemp.account_type = option.value === 'Corporate' ? 'BUSINESS' : option.value === 'Financial Institution' ? 'FINANCIAL' : 'INDIVIDUAL';

                                        setUser(userTemp)
                                    }}
                                    value={{
                                        label: user.account_type === 'INDIVIDUAL' ? 'Individual' : user.account_type === 'BUSINESS' ? 'Corporate' : user.account_type === 'FINANCIAL' ? 'Financial Institution' : user.account_type,
                                        value: user.account_type
                                    }}
                                    options={['Corporate', 'Financial Institution', 'Individual']}/>

                <DefaultTextInput field={'FULL NAME'}
                                  onChange={(event) => {
                                      const userTemp = {...user};

                                      userTemp.primary_name = event.target.value;

                                      setUser(userTemp)
                                  }}
                                  errors={errors}
                                  required value={user.primary_name}/>

                <DefaultTextInput field={'NICKNAME'}
                                  onChange={(event) => {
                                      const userTemp = {...user};

                                      userTemp.secondary_name = event.target.value;

                                      setUser(userTemp)
                                  }}
                                  errors={errors}
                                  required value={user.secondary_name}/>

                <DefaultTextInput field={'ADDRESS'} required
                                  onChange={(event) => {
                                      const userTemp = {...user};

                                      userTemp.address = event.target.value;

                                      setUser(userTemp)
                                  }}
                                  errors={errors}
                                  value={user.address}/>

                <DefaultTextInput field={'PLACE OF BIRTH'} required
                                  onChange={(event) => {
                                      const userTemp = {...user};

                                      userTemp.place_of_incorporation = event.target.value;

                                      setUser(userTemp)
                                  }}
                                  errors={errors}
                                  value={user.place_of_incorporation}/>


                <DefaultTextInput field={'DATE OF BIRTH'} type={'date'}
                                  errors={errors}
                                  required
                                  onChange={(event) => {
                                      const errorsTemp = {};
                                      setErrors(errorsTemp)
                                      if (event.target.value && (!moment(event.target.value).isValid() || moment(event.target.value).isAfter(moment(new Date())))) {
                                          errorsTemp["DATE OF BIRTH"] = `Please input a valid date prior to today`;
                                          setErrors(errorsTemp)
                                      }

                                      const userTemp = {...user};

                                      userTemp.date_of_incorporation = event.target.value;

                                      setUser(userTemp)
                                  }}
                                  value={
                                      moment(user.date_of_incorporation).format('YYYY-MM-DD')
                                  }

                />

                <DefaultTextInput field={'NO. KTP'} type={'tel'}
                                  errors={errors}
                                  onChange={(event) => {
                                      const userTemp = {...user};

                                      if (!isNaN(event.target.value)) {
                                          userTemp.id_number = event.target.value
                                      } else {
                                          userTemp.id_number = event.target.value.replace(/\D/g, '')
                                      }

                                      setUser(userTemp)
                                  }}
                                  required value={user.id_number}/>

                <DefaultTextInput field={'PHONE NUMBER'} type={'tel'}
                                  errors={errors}
                                  onChange={(event) => {
                                      const userTemp = {...user};

                                      if (!isNaN(event.target.value)) {
                                          userTemp.phone_number = event.target.value
                                      } else {
                                          userTemp.phone_number = event.target.value.replace(/\D/g, '')
                                      }

                                      setUser(userTemp)
                                  }}
                                  required value={user.phone_number}/>


                <Row style={{marginBottom: 5}}>
                    <Col style={{display: 'flex', justifyContent: 'center'}}>
                        <Form.Group style={{width: 'inherit', maxWidth: 580}}>
                            <Form.Label
                                style={{
                                    marginBottom: 3,
                                    fontWeight: '700',
                                    fontSize: '.9em',
                                    color: '#a0a4a7'
                                }}>
                                BENEFICIAL OWNER *
                            </Form.Label>
                        </Form.Group>
                    </Col>
                </Row>

                <Row style={{marginTop: -30, marginBottom: -10}}>
                    <Col style={{display: 'flex', justifyContent: 'center'}}>
                        <Form.Group style={{width: 'inherit', maxWidth: 580}}>

                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <Row style={{width: '100%', marginLeft: 0}}>
                                    <Col xs={4} sm={3} style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        fontWeight: '700',
                                        fontSize: '.9em',
                                        color: '#a0a4a7',
                                    }}>
                                        <Form.Check
                                            type={'radio'}
                                            name={'beneficial-owner'}
                                            value={'with-owner'}
                                            checked={beneficialOwnerType === 'with-owner'}
                                            onChange={(event) => {
                                                setBeneficialOwnerType(event.target.value)
                                            }}
                                        />

                                        Yes,
                                    </Col>
                                    <Col style={{paddingRight: 0, paddingTop: 20}}>
                                        <Form.Control
                                            isInvalid={!!errors['BENEFICIAL OWNER']}
                                            type={'text'}
                                            onChange={(event) => {
                                                const userTemp = {...user};

                                                userTemp.contact_person = event.target.value

                                                setUser(userTemp)
                                            }}
                                            value={user.contact_person}
                                        />

                                        {errors['BENEFICIAL OWNER'] ?
                                            <Form.Control.Feedback type="invalid">
                                                {errors['BENEFICIAL OWNER']}
                                            </Form.Control.Feedback> : null
                                        }
                                    </Col>
                                </Row>
                            </div>

                        </Form.Group>
                    </Col>
                </Row>

                <Row style={{marginBottom: 5}}>
                    <Col style={{display: 'flex', justifyContent: 'center'}}>
                        <Form.Group style={{width: 'inherit', maxWidth: 580}}>

                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <Row style={{
                                    width: '100%',
                                    marginLeft: 0,
                                    fontWeight: '700',
                                    fontSize: '.9em',
                                    color: '#a0a4a7'
                                }}>

                                    <Col xs={4} sm={3} style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        fontWeight: '700',
                                        fontSize: '.9em',
                                        color: '#a0a4a7',
                                    }}>
                                        <Form.Check
                                            value={'no-owner'}
                                            type={'radio'}
                                            name={'beneficial-owner'}
                                            checked={beneficialOwnerType === 'no-owner'}
                                            onChange={(event) => {
                                                const userTemp = {...user};

                                                userTemp.contact_person = null

                                                setUser(userTemp)
                                                setBeneficialOwnerType(event.target.value)
                                            }}
                                        />

                                        No one
                                    </Col>
                                    <Col style={{paddingRight: 0, paddingTop: 20}}>
                                    </Col>
                                </Row>
                            </div>

                        </Form.Group>
                    </Col>
                </Row>
                {/*<DefaultTextInput field={'BENEFICIAL OWNER'}*/}
                {/*                  errors={errors}*/}
                {/*                  onChange={(event) => {*/}
                {/*                      const userTemp = {...user};*/}

                {/*                      userTemp.contact_person = event.target.value;*/}

                {/*                      setUser(userTemp)*/}
                {/*                  }}*/}
                {/*                  required value={user.contact_person}/>*/}

                <DefaultTextInput field={'JOB / BUSINESS NATURE'}
                                  errors={errors}
                                  onChange={(event) => {
                                      const userTemp = {...user};

                                      userTemp.business_nature = event.target.value;

                                      setUser(userTemp)
                                  }}
                                  required value={user.business_nature}/>

                <DefaultTextInput field={'NO. NPWP'} type={'tel'}
                                  errors={errors}
                                  onChange={(event) => {
                                      const userTemp = {...user};

                                      userTemp.npwp_number = event.target.value;

                                      setUser(userTemp)
                                  }}
                                  required value={user.npwp_number}/>

                <DefaultSelectInput field={'GENDER'} required
                                    onChange={(option) => {
                                        const userTemp = {...user};

                                        userTemp.gender = option.value;

                                        setUser(userTemp)
                                    }}
                                    options={['Male', 'Female']}/>

                <DefaultTextInput field={'EMAIL ADDRESS'} required
                                  errors={errors}
                                  disabled value={user.email}/>

                <DefaultTextInput field={'CIF NUMBER'} required
                                  disabled
                                  errors={errors}
                                  value={user.cif_number}/>

                <DefaultSelectInput field={'NATIONALITY'} required
                                    errors={errors}
                                    onChange={(option) => {
                                        const userTemp = {...user};

                                        userTemp.country_of_incorporation = option.value;

                                        setUser(userTemp)
                                    }}
                                    value={user.country_of_incorporation ? {value: user.country_of_incorporation, label: user.country_of_incorporation} : null}
                                    options={['Indonesia', 'Malaysia', 'Singapore', 'Other']}/>

                {user.country_of_incorporation === 'Other' ? <DefaultTextInput value={otherCorpCountryValue}
                                                             onChange={(event) => {
                                                                 setOtherCorpCountryValue(event.target.value)
                                                             }}
                                                             placeholder={'Please fill in your country of incorporation'}
                                                             errors={errors}/> : null
                }

                <a
                    href={user.id_document_image_url && getExtension(user.id_document_image_url) === 'pdf' ? user.id_document_image_url : null} target={'_blank'}
                    onClick={()=>{
                    if (getExtension(user.id_document_image_url) !== 'pdf') setIsImageModalShown(true)
                }}>
                    <DefaultFilePicker
                        disabled={true}
                        url={user.id_document_image_url}
                        readOnly isUploading={isUploading} field={'ID'} required
                        file={!file ? {name: user.secondary_name + ' ID'} : file} setFile={setFile}
                        errors={errors}/>
                </a>


                <DefaultTextInput field={'DATE OF REGISTRATION'} type={'date'}
                                  errors={errors}
                                  required disabled value={moment(user.created_at).format('YYYY-MM-DD')}/>

                <>
                    <DefaultTextInput field={'PASSWORD'} type={'password'} value={'123456789'} disabled
                                      errors={errors}/>

                    <Row style={{marginBottom: 50}}>
                        <Col style={{display: 'flex', justifyContent: 'center'}}>
                            <div style={{
                                width: 'inherit',
                                maxWidth: 580,
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}>
                                <a href={`/customer/change-password/${user.id}`} style={{textDecorationLine: 'none'}}>
                                    <div style={{
                                        cursor: 'pointer',
                                        backgroundColor: '#dce1e5',
                                        color: '#3f9e59',
                                        paddingLeft: 30,
                                        paddingRight: 30,
                                        paddingTop: 2,
                                        paddingBottom: 2,
                                        borderRadius: 50
                                    }}>
                                        Change Password
                                    </div>
                                </a>
                            </div>
                        </Col>
                    </Row>
                </>

                <Row style={{marginBottom: 5}}>
                    <Col style={{display: 'flex', justifyContent: 'center'}}>
                        <Row style={{width: '100%', maxWidth: 580}}>
                            {user.approval_status === 'WAITING' ?
                                <Col sm={12} md={4} style={{
                                    order: windowWidth >= 768 ? 1 : 3,
                                    display: 'flex',
                                    justifyContent: windowWidth < 768 ? 'center' : 'flex-end'
                                }}>
                                    <Button style={{
                                        ...styles.button,
                                        width: windowWidth < 768 ? '100%' : 140,
                                        fontSize: '.95em',
                                        height: 45,
                                        color: Palette.COLOR_PRIMARY,
                                        backgroundColor: '#e6e6e6',
                                    }} onClick={() => reject(user.id)}>
                                        Reject
                                    </Button>
                                </Col> : null
                            }
                            {user.approval_status === 'WAITING' ?
                                <Col sm={12} md={4} style={{
                                    order: 2,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginBottom: windowWidth < 768 ? 20 : 0
                                }}>
                                    <Button style={{
                                        ...styles.button,
                                        width: windowWidth < 768 ? '100%' : 140,
                                        fontSize: '.95em',
                                        height: 45,
                                        color: Palette.COLOR_PRIMARY,
                                        backgroundColor: '#e6e6e6',
                                    }} onClick={() => approve(user.id)}>
                                        Accept
                                    </Button>
                                </Col> : null
                            }

                            <div style={{
                                fontSize: '80%',
                                textAlign: 'center',
                                color: errorMsg ? 'red' : 'green',
                                width: '100%',
                                marginBottom: 20
                            }}>
                                {errorMsg ? errorMsg : successMsg ? successMsg : null}
                            </div>

                            <Col sm={12} md={user.approval_status === 'WAITING' ? 4 : 12} style={{
                                order: windowWidth >= 768 ? 3 : 1,
                                display: 'flex',
                                justifyContent: windowWidth < 768 ? 'center' : user.approval_status === 'WAITING' ? 'flex-start' : 'center',
                                marginBottom: windowWidth < 768 ? 20 : 0,
                                width: '100%',
                            }}>
                                <Button style={{
                                    ...styles.button,
                                    width: windowWidth < 768 ? '100%' : 140,
                                    fontSize: '.95em',
                                    height: 45
                                }} onClick={attemptUpdate}>
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

            <ImageViewModal
                image_url={user.id_document_image_url}
                show={isImageModalShown} onClose={() => setIsImageModalShown(false)}
                windowWidth={windowWidth}
            />

        </AppContainer>
    )
}
