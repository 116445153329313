import AppContainer from "../reusables/AppContainer";
import React, {useEffect, useState} from "react";
import {Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip} from "recharts";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import WelcomeCard from "../reusables/WelcomeCard";
import SiteAudience from "../reusables/SiteAudience";
import SiteVisit from "../reusables/SiteVisit";
import Table from "react-bootstrap/Table";
import Palette from "../Palette";
import {RiPencilFill} from "react-icons/ri";
import {AiOutlineClose} from "react-icons/ai";
import Form from "react-bootstrap/Form";
import styles from "../styles";
import Button from "@material-ui/core/Button";
import ChangeMarginModal from "../reusables/ChangeMarginModal";
import BankAccountModal from "../reusables/BankAccountModal";
import CurrencyDAO from "../daos/CurrencyDAO";
import AccountInfoDAO from "../daos/AccountInfoDAO";
import CustomerDao from "../daos/CustomerDao";
import AdminDao from "../daos/AdminDao";
import DefaultCheckBox from "../reusables/DefaultCheckBox";
import {withStyles} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import BankDAO from "../daos/BankDAO";
import ManageBankModal from "../reusables/ManageBankModal";
import {FaTrash} from "react-icons/fa";
import ConfirmationModal from "../reusables/ConfirmationModal";
import AddCurrencyModal from "../reusables/AddCurrencyModal";
import {FaTrashRestoreAlt} from "react-icons/all";
import EditCurrencyModal from "../reusables/EditCurrencyModal";

export default function DashboardPage() {
    function thousandSeparator(x) {
        x = Math.round(x * 100) / 100
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const [isMarginBtnHovered, setMarginBtnHovered] = useState(false);
    const [isManageBtnHovered, setManageBtnHovered] = useState(false);
    const [isAddBtnHovered, setAddBtnHovered] = useState(false);
    const [isEditBtnHovered, setEditBtnHovered] = useState(false);
    const [isMarginModalShown, setMarginModalShown] = useState(false);
    const [isBankAccountModalShown, setBankAccountModalShown] = useState(false);

    const [forexRate, setForexRate] = useState([])

    const [activeForexRate, setActiveForexRate] = useState([])
    const [inActiveForexRate, setInActiveForexRate] = useState([])

    const [unfilteredForexRate, setUnfilteredForexRate] = useState([]);
    const [buyMargin, setBuyMargin] = useState(0)
    const [sellMargin, setSellMargin] = useState(0)
    const [fee, setFee] = useState(0)

    const [accountInfo, setAccountInfo] = useState({})
    const [isManageBankModalShown, setManageBankModalShown] = useState(false);
    const [selectedBank, setSelectedBank] = useState({});

    const [showDeletedForex, setShowDeletedForex] = useState(false)

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [dashboardData, setDashboardData] = useState({})

    const [weeklyData, setWeeklyData] = useState([])
    const [dailyData, setDailyData] = useState([])
    const [monthlyData, setMonthlyData] = useState([])
    const [availableCurrencies, setAvailableCurrencies] = useState([]);
    const [banks, setBanks] = useState([]);
    const [isConfirmationModalShown, setConfirmationModalShown] = useState(false);

    const [isAddCurrencyModalShown, setAddCurrencyModalShown] = useState(false)

    const [isEditCurrencyModalShown, setEditCurrencyModalShown] = useState(false)
    const [editedCurrency, setEditedCurrency] = useState({})

    useEffect(() => {
        window.addEventListener('resize', handleResize)

        getRates()
        getAccountInfo()
        getDashboardData()
        getBankList()

        CurrencyDAO.getAllAvailable().then(res => setAvailableCurrencies(res)).catch(err => console.log('curr err', err))

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    const getBankList = () => {
        BankDAO.getAll().then(res => {
            console.log('banks', res)
            setBanks(res)
        }).catch(err => console.log(err))
    }

    const handleResize = () => {
        console.log(window.innerWidth)
        setWindowWidth(window.innerWidth)
    }

    const getRates = async () => {
        let result = await CurrencyDAO.getAll();

        console.log('forex', result)

        setUnfilteredForexRate(result);

        let filteredResult = result.filter((obj) => {
            return obj.currency_code !== "IDR"
        })

        setForexRate(filteredResult)

        setActiveForexRate(filteredResult.filter((obj) => {
            return obj.active
        }))

        setInActiveForexRate(filteredResult.filter((obj) => {
            return !obj.active
        }))

        filteredResult.forEach((obj) => {
            setBuyMargin(obj.margin_buy)
            setSellMargin(obj.margin_sell)
            setFee(obj.fee)
        })
    }

    const getAccountInfo = async () => {
        let result = await AccountInfoDAO.getInfo();
        setAccountInfo(result)
    }

    const getDashboardData = async () => {
        let result = await AdminDao.dashboardData();

        setDashboardData(result)
        setWeeklyData(result.weeklyData)
        setMonthlyData(result.monthlyData)
        setDailyData(result.dailyData)
    }

    const GreenCheckbox = withStyles({
        root: {
            color: '#ababab',
        },
        checked: {
            color: Palette.COLOR_PRIMARY
        }
    })((props) => <Checkbox color="default" {...props} />);

    return (
        <AppContainer active={'Dashboard'}>
            {/*<div*/}
            {/*    style={{*/}
            {/*        position: 'fixed',*/}
            {/*        top: 0,*/}
            {/*        right: 0,*/}
            {/*        width: '100%',*/}
            {/*        minHeight: '100vh',*/}
            {/*        zIndex: 2,*/}
            {/*        backgroundColor: '#00000059',*/}
            {/*        opacity: 1,*/}
            {/*        transition: 'zIndex .2s'*/}
            {/*    }}>*/}
            {/*    <Container*/}
            {/*        style={{minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>*/}
            {/*        <Row style={{*/}
            {/*            backgroundColor: '#f5f5f5',*/}
            {/*            boxShadow: '0px 4px 5px 0px rgba(0,0,0,0.3)',*/}
            {/*            width: '100%',*/}
            {/*            maxWidth: 500,*/}
            {/*            borderRadius: 15,*/}
            {/*            paddingBottom: 20,*/}
            {/*            maxHeight: 500,*/}
            {/*            overflow: 'auto'*/}
            {/*        }}>*/}
            {/*            <Row style={{width: '100%', marginTop: 30, marginLeft: 15, marginRight: 15, marginBottom: 30}}>*/}
            {/*                <Col xs={9} style={{fontSize: '1.2em', fontFamily: 'Roboto', fontWeight: '400'}}>*/}
            {/*                    Manage Currency*/}
            {/*                </Col>*/}

            {/*                <Col style={{display: 'flex', justifyContent: 'flex-end'}}>*/}
            {/*                    <AiOutlineClose*/}
            {/*                        style={{cursor: 'pointer'}} size={27}/>*/}
            {/*                </Col>*/}
            {/*            </Row>*/}
            {/*            <Row style={{marginBottom: 5, maxWidth: 600, width: '100%'}}>*/}
            {/*                {availableCurrencies.map(availableCurrency => {*/}
            {/*                    let isActive = false;*/}

            {/*                    unfilteredForexRate.map(currency => {*/}
            {/*                        if(availableCurrency.code === currency.currency_code) isActive = true*/}
            {/*                        else if(availableCurrency.code === 'CNY' && currency.currency_code === 'RMB') isActive = true;*/}
            {/*                    })*/}

            {/*                    return (*/}
            {/*                        <Col xs={6} style={{display: 'flex', flexDirection: 'row', fontFamily: 'Roboto', fontWeight: '500', marginBottom: 20, justifyContent: 'center'}}>*/}
            {/*                            <GreenCheckbox checked={isActive} onChange={() => {*/}
            {/*                                if(isActive) {*/}
            {/*                                    CurrencyDAO.disable(availableCurrency.code).then(res => getRates()).catch(err => console.log(err))*/}
            {/*                                } else {*/}
            {/*                                    CurrencyDAO.enable(availableCurrency.code).then(res => getRates()).catch(err => console.log(err))*/}
            {/*                                }*/}
            {/*                                console.log(isActive)*/}
            {/*                                console.log(availableCurrency.code)*/}
            {/*                            }}/>*/}

            {/*                            <div style={{color: '#696969', marginTop: 8}}>*/}
            {/*                                {availableCurrency.code === 'CNY' ? 'RMB' : availableCurrency.code}*/}
            {/*                            </div>*/}
            {/*                        </Col>*/}
            {/*                    )*/}
            {/*                })}*/}
            {/*            </Row>*/}


            {/*        </Row>*/}
            {/*    </Container>*/}
            {/*</div>*/}

            <ConfirmationModal show={isConfirmationModalShown} onConfirm={() => {
                BankDAO.delete(selectedBank.id).then(res => {
                    alert('Bank has been deleted successfully!')
                    setConfirmationModalShown(false)
                    getBankList()
                }).catch(err => console.log(err))
            }}
                               onCancel={() => {
                                   setSelectedBank({})
                                   setConfirmationModalShown(false)
                               }}
                               title={'Are you sure you want to delete this bank?'}/>

            <ChangeMarginModal
                show={isMarginModalShown}
                buyMargin={buyMargin}
                sellMargin={sellMargin}
                fee={fee}
                onClose={() => {
                    setMarginModalShown(false)
                    getRates()
                }}/>

            <ManageBankModal show={isManageBankModalShown} onClose={() => {
                setSelectedBank({})
                setManageBankModalShown(false)
            }} refreshBankList={getBankList} selectedBank={selectedBank}/>

            <BankAccountModal
                accountInfo={accountInfo}
                show={isBankAccountModalShown}
                onClose={() => {
                    setBankAccountModalShown(false)
                    getAccountInfo()
                }}/>

            <Container fluid>
                <Row>
                    <Col span={24}>
                        <div className="gx-card">
                            <div className="gx-card-body">
                                <Row>
                                    <Col xl={3} md={6} style={{order: 1}}>
                                        <WelcomeCard
                                            unconfirmedOrders={dashboardData.unconfirmedOrders}
                                            pendingPayment={dashboardData.paymentApproval}
                                            processingOrders={dashboardData.processingOrder}
                                            unresolvedComplain={dashboardData.unresolvedComplain}
                                        />
                                    </Col>

                                    <Col xl={6} md={12} style={{order: windowWidth >= 1200 ? 2 : 3}}>
                                        <SiteVisit
                                            weeklyData={weeklyData}
                                            dailyData={dailyData}
                                            monthlyData={monthlyData}
                                            windowWidth={windowWidth}
                                        />
                                    </Col>

                                    <Col xl={3} md={6} style={{order: windowWidth >= 1200 ? 3 : 2}}>
                                        <SiteAudience/>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col span={24} lg={5} md={6} sm={12}>
                        <div className="gx-card">
                            <div className="gx-card-body">
                                <Row>
                                    <Col style={{fontWeight: '600', fontSize: '1.25em'}}>
                                        Forex Rate
                                    </Col>

                                    <Col style={{
                                        color: Palette.COLOR_PRIMARY,
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        fontWeight: '600',
                                        fontSize: '.9em',
                                        alignItems: 'center'
                                    }}>
                                        <div
                                            onClick={() => setMarginModalShown(true)}
                                            onMouseOver={() => setMarginBtnHovered(true)}
                                            onMouseLeave={() => setMarginBtnHovered(false)}
                                            style={{
                                                cursor: 'pointer',
                                                textDecorationLine: isMarginBtnHovered ? 'underline' : 'none',
                                                backgroundColor: isMarginBtnHovered ? '#f2f2f2' : 'transparent',
                                                paddingTop: 5,
                                                paddingBottom: 5,
                                                paddingLeft: 10,
                                                paddingRight: 10
                                            }}>
                                            Change Margin
                                        </div>

                                        <div
                                            onClick={() => setAddCurrencyModalShown(true)}
                                            onMouseOver={() => setManageBtnHovered(true)}
                                            onMouseLeave={() => setManageBtnHovered(false)}
                                            style={{
                                                cursor: 'pointer',
                                                textDecorationLine: isManageBtnHovered ? 'underline' : 'none',
                                                backgroundColor: isManageBtnHovered ? '#f2f2f2' : 'transparent',
                                                paddingTop: 5,
                                                paddingBottom: 5,
                                                paddingLeft: 10,
                                                paddingRight: 10,
                                                marginLeft: 10
                                            }}>
                                            + Add
                                        </div>
                                    </Col>
                                </Row>

                                <Table responsive style={{
                                    borderCollapse: "collapse",
                                    marginTop: 10
                                }} size={'sm'}>
                                    <thead>
                                    <tr style={{textAlign: 'center', borderBottom: '1px solid red'}}>
                                        <th style={{
                                            fontFamily: 'Roboto',
                                            fontWeight: '500',
                                            fontSize: '.9em',
                                            borderColor: '#594f4f',
                                            borderWidth: '0px 0px 0px 0px',
                                            color: 'grey',
                                        }}>
                                            Currency
                                        </th>

                                        <th style={{
                                            fontFamily: 'Roboto',
                                            fontWeight: '500',
                                            fontSize: '.9em',
                                            borderWidth: '0px 0px 0px 0px',
                                            color: 'grey'
                                        }}>
                                            Online Rate
                                        </th>

                                        <th style={{
                                            fontFamily: 'Roboto',
                                            fontWeight: '500',
                                            fontSize: '.9em',
                                            borderWidth: '0px 0px 0px 0px',
                                            color: 'grey'
                                        }}>
                                            Buy
                                        </th>

                                        <th style={{
                                            fontFamily: 'Roboto',
                                            fontWeight: '500',
                                            fontSize: '.9em',
                                            borderWidth: '0px 0px 0px 0px',
                                            color: 'grey'
                                        }}>
                                            Sell
                                        </th>

                                    </tr>
                                    </thead>

                                    <tbody>
                                    {activeForexRate.map((rate, idx) => {

                                        return (
                                            <tr style={{textAlign: 'center'}}>
                                                <td style={{
                                                    color: '#594f4f',
                                                    fontFamily: 'Roboto',
                                                    fontWeight: '500',
                                                    fontSize: '.9em',
                                                    borderColor: idx === 0 ? '#594f4f' : 'transparent'
                                                }}>
                                                    {rate.currency_code}
                                                </td>
                                                <td style={{
                                                    color: '#594f4f',
                                                    fontFamily: 'Roboto',
                                                    fontWeight: '500',
                                                    fontSize: '.9em',
                                                    borderColor: idx === 0 ? '#594f4f' : 'transparent'
                                                }}>{thousandSeparator(rate.rate)}</td>
                                                <td style={{
                                                    color: '#594f4f',
                                                    fontFamily: 'Roboto',
                                                    fontWeight: '500',
                                                    fontSize: '.9em',
                                                    borderColor: idx === 0 ? '#594f4f' : 'transparent'
                                                }}>{thousandSeparator(rate.buy_rate)}</td>
                                                <td style={{
                                                    color: '#594f4f',
                                                    fontFamily: 'Roboto',
                                                    fontWeight: '500',
                                                    fontSize: '.9em',
                                                    borderColor: idx === 0 ? '#594f4f' : 'transparent'
                                                }}>{thousandSeparator(rate.sell_rate)}</td>

                                                <td style={{
                                                    color: '#594f4f',
                                                    fontFamily: 'Roboto',
                                                    fontWeight: '500',
                                                    fontSize: '.9em',
                                                    borderColor: idx === 0 ? '#594f4f' : 'transparent'
                                                }}>
                                                    <RiPencilFill
                                                        color={'#8295e0'} size={18}
                                                        onClick={() => {
                                                            setEditCurrencyModalShown(true)
                                                            setEditedCurrency(rate)
                                                        }}
                                                        style={{cursor: 'pointer'}}/>

                                                    <FaTrash
                                                        color={'red'} size={13}
                                                        onClick={async () => {
                                                            let result = await CurrencyDAO.disable(rate.currency_code)
                                                            getRates()
                                                        }}
                                                        style={{cursor: 'pointer', marginLeft: 20}}/>
                                                    {/*<div*/}
                                                    {/*    onClick={() => setMarginModalShown(true)}*/}
                                                    {/*    style={{*/}
                                                    {/*        cursor: 'pointer',*/}
                                                    {/*        color: "red",*/}
                                                    {/*        paddingLeft: 10,*/}
                                                    {/*        paddingRight: 10*/}
                                                    {/*    }}>*/}
                                                    {/*    Remove*/}
                                                    {/*</div>*/}

                                                </td>
                                            </tr>
                                        )
                                    })}

                                    {showDeletedForex ? inActiveForexRate.map((rate, idx) => {

                                        return (
                                            <tr style={{textAlign: 'center'}}>
                                                <td style={{
                                                    color: '#594f4f',
                                                    fontFamily: 'Roboto',
                                                    fontWeight: '500',
                                                    fontSize: '.9em',
                                                    borderColor: 'transparent'
                                                }}>
                                                    {rate.currency_code}
                                                </td>
                                                <td style={{
                                                    color: '#594f4f',
                                                    fontFamily: 'Roboto',
                                                    fontWeight: '500',
                                                    fontSize: '.9em',
                                                    borderColor: 'transparent'

                                                }}>{thousandSeparator(rate.rate)}</td>
                                                <td style={{
                                                    color: '#594f4f',
                                                    fontFamily: 'Roboto',
                                                    fontWeight: '500',
                                                    fontSize: '.9em',
                                                    borderColor: 'transparent'

                                                }}>{thousandSeparator(rate.buy_rate)}</td>
                                                <td style={{
                                                    color: '#594f4f',
                                                    fontFamily: 'Roboto',
                                                    fontWeight: '500',
                                                    fontSize: '.9em',
                                                    borderColor: 'transparent'

                                                }}>{thousandSeparator(rate.sell_rate)}</td>

                                                <td style={{
                                                    color: '#594f4f',
                                                    fontFamily: 'Roboto',
                                                    fontWeight: '500',
                                                    fontSize: '.9em',
                                                    borderColor: 'transparent'

                                                }}>
                                                    <FaTrashRestoreAlt
                                                        color={Palette.COLOR_PRIMARY} size={13}
                                                        onClick={async () => {
                                                            let result = await CurrencyDAO.enable(rate.currency_code)
                                                            getRates()
                                                        }}
                                                        style={{cursor: 'pointer', marginLeft: 20}}/>

                                                </td>
                                            </tr>
                                        )
                                    }) : null}

                                    <tr>
                                        <td
                                            style={{
                                                borderColor: "transparent",
                                                width: "100%",
                                                fontWeight: "400",
                                                color: Palette.COLOR_PRIMARY,
                                                textAlign: "center"
                                            }}
                                            colSpan={5}>
                                            <b style={{
                                                cursor: "pointer",
                                            }} onClick={() => {
                                                setShowDeletedForex(!showDeletedForex)
                                            }}>
                                                {showDeletedForex ? "Hide" : "Show"} Deleted
                                            </b>
                                        </td>
                                    </tr>

                                    </tbody>
                                </Table>


                            </div>
                        </div>
                    </Col>

                    <Col span={24} lg={4} md={6} sm={12}>
                        <div className="gx-card">
                            <div className="gx-card-body">
                                <Row>
                                    <Col sm={1} md={2}/>

                                    <Col style={{
                                        textAlign: 'center',
                                        fontSize: '1.2em',
                                        fontFamily: 'Roboto',
                                        fontWeight: '400'
                                    }}>
                                        Bank account:
                                    </Col>

                                    <Col sm={1} md={2}>
                                        <RiPencilFill
                                            onClick={() => setBankAccountModalShown(true)}
                                            onMouseOver={() => setEditBtnHovered(true)}
                                            onMouseLeave={() => setEditBtnHovered(false)}
                                            color={Palette.COLOR_PRIMARY} size={30} style={{
                                            cursor: 'pointer',
                                            padding: 5,
                                            backgroundColor: isEditBtnHovered ? '#f2f2f2' : 'transparent'
                                        }}/>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col style={{
                                        textAlign: 'center',
                                        fontFamily: 'Roboto',
                                        fontWeight: '700',
                                        fontSize: '2.2em',
                                        color: Palette.COLOR_PRIMARY
                                    }}>
                                        {accountInfo.bank_account_number}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col style={{
                                        textAlign: 'center',
                                        fontFamily: 'Roboto',
                                        fontWeight: '500',
                                        fontSize: '1.5em',
                                        color: '#594f4f',
                                        marginTop: 10,
                                        lineHeight: 1.75
                                    }}>
                                        {accountInfo.account_name}<br/>
                                        {accountInfo.bank_name}
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>

                    <Col span={24} lg={3} md={6} sm={12}>
                        <div className="gx-card">
                            <div className="gx-card-body">
                                <Row>
                                    <Col style={{fontWeight: '600', fontSize: '1.25em'}}>
                                        Bank
                                    </Col>

                                    <Col style={{
                                        color: Palette.COLOR_PRIMARY,
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        fontWeight: '600',
                                        fontSize: '.9em',
                                        alignItems: 'center'
                                    }}>
                                        <div
                                            onMouseOver={() => setAddBtnHovered(true)}
                                            onMouseLeave={() => setAddBtnHovered(false)}
                                            onClick={() => setManageBankModalShown(true)}
                                            style={{
                                                cursor: 'pointer',
                                                textDecorationLine: isAddBtnHovered ? 'underline' : 'none',
                                                backgroundColor: isAddBtnHovered ? '#f2f2f2' : 'transparent',
                                                paddingTop: 5,
                                                paddingBottom: 5,
                                                paddingLeft: 10,
                                                paddingRight: 10,
                                                marginLeft: 10
                                            }}>
                                            + Add
                                        </div>
                                    </Col>
                                </Row>

                                <Table responsive style={{marginTop: 10}} size={'sm'}>
                                    <thead>
                                    <tr style={{textAlign: 'center'}}>
                                        <th style={{
                                            fontFamily: 'Roboto',
                                            fontWeight: '500',
                                            fontSize: '.9em',
                                            borderWidth: '0px 0px 0px 0px',
                                            color: 'grey',
                                        }}>
                                            Bank Name
                                        </th>


                                        <th style={{
                                            fontFamily: 'Roboto',
                                            fontWeight: '500',
                                            fontSize: '.9em',
                                            borderWidth: '0px 0px 0px 0px',
                                            color: 'grey'
                                        }}>
                                            Action
                                        </th>

                                    </tr>
                                    </thead>

                                    <tbody>
                                    {banks.map((bank, idx) => {
                                        if (!bank.active) return null;

                                        return (
                                            <tr style={{textAlign: 'center'}}>
                                                <td style={{
                                                    color: '#594f4f',
                                                    fontFamily: 'Roboto',
                                                    fontWeight: '500',
                                                    fontSize: '.9em',
                                                    borderColor: idx === 0 ? '#594f4f' : 'transparent'
                                                }}>
                                                    {bank.name}
                                                </td>

                                                <td style={{
                                                    color: '#594f4f',
                                                    fontFamily: 'Roboto',
                                                    fontWeight: '500',
                                                    fontSize: '.9em',
                                                    borderColor: idx === 0 ? '#594f4f' : 'transparent'
                                                }}>
                                                    <RiPencilFill color={'#8295e0'} size={18}
                                                                  onClick={() => {
                                                                      setSelectedBank(bank)
                                                                      setManageBankModalShown(true)
                                                                  }}
                                                                  style={{cursor: 'pointer'}}/>

                                                    <FaTrash color={'red'} size={13}
                                                             onClick={() => {
                                                                 setSelectedBank(bank)
                                                                 setConfirmationModalShown(true)
                                                             }}
                                                             style={{cursor: 'pointer', marginLeft: 20}}/>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </Col>
                </Row>

                <AddCurrencyModal
                    forexRates={forexRate}
                    show={isAddCurrencyModalShown}
                    onClose={() => {
                        setAddCurrencyModalShown(false)
                        getRates()
                    }}/>

                <EditCurrencyModal
                    currency={editedCurrency}
                    show={isEditCurrencyModalShown}
                    onClose={() => {
                        setEditCurrencyModalShown(false)
                        setEditedCurrency({})
                        getRates()
                    }}
                />

            </Container>
        </AppContainer>
    )
}
