import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "@material-ui/core/Button";
import styles from "../styles";
import AppContainer from "./AppContainer";
import {AiOutlineClose} from "react-icons/ai";
import OrderDAO from "../daos/OrderDAO";

export default function EditRateModal(props) {
    const {
        show,
        onClose,
        selectedOrder
    } = props;

    let [rate, setRate] = useState(0);
    let [fee, setFee] = useState(0);

    const disableScrolling = () => {
        const x = window.scrollX;
        const y = window.scrollY;
        window.onscroll = function () {
            window.scrollTo(x, y);
        };
    }

    const enableScrolling = () => {
        window.onscroll = function () {
        };
    }

    useEffect(() => {
        setRate(1/selectedOrder.currency_rate)
        setFee(selectedOrder.rupiah_fee)

        if (show) disableScrolling()
        else enableScrolling()
    }, [show, selectedOrder])

    const editRate = async () => {
        try {
            let result = await OrderDAO.updateRate(selectedOrder.order_number, 1/rate, fee);
            setRate(0)
            onClose()
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                right: 0,
                width: '100%',
                minHeight: '100vh',
                zIndex: show ? 2 : -1,
                backgroundColor: '#00000059',
                opacity: show ? 1 : 0,
                transition: 'zIndex .2s'
            }}>
            <Container
                style={{minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Row style={{
                    backgroundColor: '#f5f5f5',
                    boxShadow: '0px 4px 5px 0px rgba(0,0,0,0.3)',
                    width: '100%',
                    maxWidth: 360,
                    borderRadius: 15,
                    paddingBottom: 20
                }}>
                    <Row style={{width: '100%', marginTop: 30, marginLeft: 15, marginRight: 15, marginBottom: 40}}>
                        <Col xs={9} style={{fontSize: '1.2em', fontFamily: 'Roboto', fontWeight: '400'}}>
                            Edit Rate
                        </Col>

                        <Col style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <AiOutlineClose
                                style={{cursor: 'pointer'}} size={27} onClick={onClose}/>
                        </Col>
                    </Row>

                    <Row style={{width: '100%', marginLeft: 15, marginRight: 15, display: 'flex'}}>
                        <Col xs={5}
                             style={{
                                 height: '100%',
                                 display: 'flex',
                                 alignItems: 'center',
                                 marginLeft: 0,
                                 marginBottom: 10,
                                 fontFamily: 'Roboto',
                                 fontWeight: '400'
                             }}>
                            1 {selectedOrder.destination_currency} =
                        </Col>
                        <Col style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Form.Control
                                type={'tel'}
                                onChange={(e) => {
                                    if (!isNaN(e.target.value)) setRate(e.target.value)
                                    else setRate( e.target.value.replace(/\D/g, ''))
                                }}
                                value={rate}
                                style={{
                                    fontSize: "1em",
                                    borderColor: '#e6e6e6',
                                    // maxWidth: 100,
                                    fontFamily: 'Roboto',
                                    fontWeight: '400',
                                    textAlign: 'right',
                                }}/>

                            <span style={{
                                fontFamily: 'Roboto',
                                fontWeight: '400',
                                display: 'flex',
                                marginLeft: 5,
                                alignItems: 'center',
                                marginBottom: 10
                            }}>{selectedOrder.source_currency}</span>

                        </Col>
                    </Row>

                    <Row style={{width: '100%', marginLeft: 15, marginRight: 15, marginTop: 15, display: 'flex'}}>
                        <Col xs={5}
                             style={{
                                 height: '100%',
                                 display: 'flex',
                                 alignItems: 'center',
                                 marginLeft: 0,
                                 marginBottom: 10,
                                 fontFamily: 'Roboto',
                                 fontWeight: '400'
                             }}>
                            Fee
                        </Col>
                        <Col style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Form.Control
                                type={'tel'}
                                onChange={(e) => {
                                    if (!isNaN(e.target.value)) setFee(e.target.value)
                                    else setFee( e.target.value.replace(/\D/g, ''))
                                }}
                                value={fee}
                                style={{
                                    fontSize: "1em",
                                    borderColor: '#e6e6e6',
                                    // maxWidth: 100,
                                    fontFamily: 'Roboto',
                                    fontWeight: '400',
                                    textAlign: 'right',
                                }}/>

                            <span style={{
                                fontFamily: 'Roboto',
                                fontWeight: '400',
                                display: 'flex',
                                marginLeft: 5,
                                alignItems: 'center',
                                marginBottom: 10,
                            }}>{selectedOrder.source_currency}</span>

                        </Col>
                    </Row>


                    <Row style={{width: '100%', marginTop: 30}}>
                        <Col style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button
                                onClick={() => {
                                    editRate()
                                }}
                                style={{...styles.button}}>
                                Change
                            </Button>
                        </Col>
                    </Row>
                </Row>
            </Container>
        </div>
    )
}
