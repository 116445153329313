import httpRequest from "../util/httpRequest"

export default class CurrencyDAO {

    static getAll = async () => {
        return new Promise(
            await httpRequest.set('v1/currencies', 'GET')
        )
    }

    static setMargins = async (margins) => {
        return new Promise(
            await httpRequest.set('v1/currencies', 'PUT', JSON.stringify(margins))
        )
    }

    static getAllAvailable = async () => {
        return new Promise(
            await httpRequest.set('v1/currencies/all', 'GET')
        )
    }

    static disable = async (code) => {
        return new Promise(
            await httpRequest.set(`v1/currency/${code}`, 'DELETE')
        )
    }

    static enable = async (code) => {
        return new Promise(
            await httpRequest.set(`v1/currency/${code}/enable`, 'POST')
        )
    }

    static create = async (currency) => {
        return new Promise(
            await httpRequest.set(`v1/currencies`, 'POST', JSON.stringify(currency))
        )
    }

    static update = async (code,currency) => {
        return new Promise(
            await httpRequest.set(`v1/currency/${code}`, 'PUT', JSON.stringify(currency))
        )
    }

}
