import AppContainer from "../reusables/AppContainer";
import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import {Col} from "react-bootstrap";
import styles from "../styles";
import {FaAngleDown, FaCaretDown, FaCaretUp, FaFilter, FaSearch, FaTrashAlt} from "react-icons/fa";
import InputBase from "@material-ui/core/InputBase";
import Palette from "../Palette";
import Row from "react-bootstrap/Row";
import Select, {components} from "react-select";
import {AiOutlineClose} from "react-icons/ai";
import {MdSort} from "react-icons/md";
import OrderCard from "../reusables/OrderCard";
import Table from "react-bootstrap/Table";
import {RiPencilFill} from "react-icons/ri";
import {BsFillTrashFill} from "react-icons/bs";
import Button from "@material-ui/core/Button";
import AdminDao from "../daos/AdminDao";
import util from "../util/util";
import _ from "lodash";
import {useHistory} from "react-router-dom";
import ConfirmationModal from "../reusables/ConfirmationModal";
import moment from "moment";

export default function AccountsList() {
    const [accountIdOptions, setAccountIdOptions] = useState([]);
    const [emailOptions, setEmailOptions] = useState([]);
    const [nameOptions, setNameOptions] = useState([]);

    const history = useHistory()

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isFilterExpanded, setFilterExpanded] = useState(false)

    const [admins, setAdmins] = useState([])

    const [isDeleteConfirmationShown, setDeleteConfirmationShown] = useState(false);
    const [selectedId, setSelectedId] = useState(-1);

    const DropdownIndicator = props => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <FaAngleDown color={'black'}/>
                </components.DropdownIndicator>
            )
        );
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        getAll()
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    const getAll = async () => {
        let result = await AdminDao.getAll()

        const accountIdTemp = [];
        const emailTemp = [];
        const nameTemp = [];

        result.map(admin => {
            if (accountIdTemp.length === 0) accountIdTemp.push({
                value: util.idFormatter(admin.id, 3, "admin"),
                label: util.idFormatter(admin.id, 3, "admin"),
            });
            else {
                let found = false;

                accountIdTemp.map(accountId => {
                    if (JSON.stringify(accountId) === JSON.stringify({
                        value: util.idFormatter(admin.id, 3, "admin"),
                        label: util.idFormatter(admin.id, 3, "admin"),
                    })) found = true;
                })

                if (!found) accountIdTemp.push({value: util.idFormatter(admin.id, 3, "admin"), label: util.idFormatter(admin.id, 3, "admin")});
            }

            if (emailTemp.length === 0) emailTemp.push({
                value: admin.email,
                label: admin.email,
            });
            else {
                let found = false;

                emailTemp.map(email => {
                    if (JSON.stringify(email) === JSON.stringify({
                        value: admin.email,
                        label: admin.email,
                    })) found = true;
                })

                if (!found) emailTemp.push({value: admin.email, label: admin.email});
            }

            if (nameTemp.length === 0) nameTemp.push({
                value: admin.name,
                label: admin.name,
            });
            else {
                let found = false;

                nameTemp.map(name => {
                    if (JSON.stringify(name) === JSON.stringify({
                        value: admin.name,
                        label: admin.name,
                    })) found = true;
                })

                if (!found) nameTemp.push({value: admin.name, label: admin.name});
            }
        })

        setAccountIdOptions(accountIdTemp);
        setEmailOptions(emailTemp);
        setNameOptions(nameTemp);
        setAdmins(result)
        console.log(result)
    }

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    }


    const sorterFields = [
        {
            key: "id",
            label: "Account ID"
        },
        {
            key: "name",
            label: "Account Name",
        },
        {
            key: "privilege_level",
            label: "Account Role",
        }, {
            key: "email",
            label: "Email Address",
        }
    ];

    const formattedSorterFields = []

    sorterFields.forEach((obj, key) => {
        formattedSorterFields.push({
            value: key * 2,
            sorter: obj.key,
            label: obj.label + " ASC",
            order: 1,
        })
        formattedSorterFields.push({
            value: key * 2 + 1,
            sorter: obj.key,
            label: obj.label + " DESC",
            order: -1,
        })
    })

    const [chosenSorterField, setChosenSorterField] = useState(null)
    const [chosenSorterFieldLabel, setChosenSorterFieldLabel] = useState(null)
    const [chosenSorterFieldOrder, setChosenSorterFieldOrder] = useState(1)

    const sortData = (arr) => {


        if (chosenSorterField === null) {
            return arr
        }

        let tempArr = arr.map((obj) => {
            if (typeof obj[chosenSorterField] === "string" || obj[chosenSorterField] instanceof String) {
                let tempObj = {...obj}
                tempObj[chosenSorterField] = tempObj[chosenSorterField].toLowerCase()
                return tempObj
            } else {
                return obj
            }
        })
        console.log(tempArr)

        let orderedBy = _.orderBy(tempArr, [chosenSorterField], [chosenSorterFieldOrder === 1 ? 'asc' : 'desc'])

        return orderedBy

    }

    const sortBtn = (
        <Col xs={12} md={6} style={{marginTop: windowWidth < 768 ? 30 : 15}}>
            <a href={'#'}
               style={{
                   color: Palette.COLOR_PRIMARY,
                   fontWeight: '600',
                   opacity: !isFilterExpanded ? 1 : 0,
                   height: !isFilterExpanded ? '100%' : 0
               }}
               onClick={(e) => {
                   e.preventDefault();
                   setFilterExpanded(true)
               }}>
                Sort and filter

                <FaCaretDown color={Palette.COLOR_PRIMARY} style={{marginLeft: 10}}/>
            </a>

            <div style={{
                color: Palette.COLOR_PRIMARY,
                height: isFilterExpanded ? '100%' : 0,
                opacity: isFilterExpanded ? 1 : 0,
                transition: 'opacity .15s'
            }}>
                <Row>
                    <Col xs={5} lg={4}>
                        <MdSort size={22} style={{marginRight: 15}}/>

                        <span style={{fontWeight: '600', fontSize: '1.05em'}}>Sort By</span>
                    </Col>

                    <Col style={{paddingLeft: 0}} xs={7} lg={4}>
                        <Select
                            options={formattedSorterFields}
                            onChange={(obj, key) => {
                                console.log(obj, key)
                                setChosenSorterFieldOrder(obj.order)
                                setChosenSorterField(obj.sorter)
                                setChosenSorterFieldLabel(obj.label)
                            }}
                            styles={styles.select}
                            components={{DropdownIndicator, IndicatorSeparator: () => null}}
                        />
                    </Col>
                </Row>
            </div>
        </Col>
    );

    const deleteAdmin = async (id) => {
        try {
            let result = await AdminDao.deleteAdmin(id)
            console.log(result)
            getAll()

            setDeleteConfirmationShown(false)
        } catch (e) {
            console.log(e)
        }
    }

    const deleteFilter = (filter) => {
        let deletedIdx = -1;

        filters.map((aFilter, idx) => {
            if (aFilter === filter) deletedIdx = idx;
        })

        const filtersTemp = [...filters];

        filtersTemp.splice(deletedIdx, 1);

        setFilters(filtersTemp);
    }

    const [filters, setFilters] = useState([]);
    const addFilter = (filter) => {
        let found = false;

        filters.map((aFilter, idx) => {
            if (aFilter.type === filter.type && aFilter.value === filter.value) {
                const filtersTemp = [...filters];
                filtersTemp[idx] = filter;
                found = true;

                setFilters(filtersTemp)
            }
        })

        if (!found) {
            const filtersTemp = [...filters];
            filtersTemp.push(filter);
            setFilters(filtersTemp);
        }
    }

    const [searchKeyword, setSearchKeyword] = useState('');

    const filterAdmin = (obj, key) => {
        if (!obj.name.toLowerCase().includes(searchKeyword.toLowerCase())) return;

        const adminRow = (
            <tr style={{backgroundColor: key % 2 === 0 ? null : '#dce1e7'}}>
                <td>{key + 1}</td>
                <td>{util.idFormatter(obj.id, 3, "admin")}</td>
                <td>{obj.name}</td>
                <td>{obj.privilege_level === "7" ? "superadmin" : "admin"}</td>
                <td>{obj.email}</td>
                <td style={{minWidth: 160}}>

                    <Row style={{marginLeft: 10, marginRight: 10}}>
                        <Col>
                            <RiPencilFill
                                onClick={() => {
                                    console.log(obj.id)
                                    history.push("/account/update/" + obj.id)
                                }}
                                color={'#8295e0'} size={23}
                                style={{cursor: "pointer"}}
                            />
                        </Col>

                        <Col>
                            <FaTrashAlt
                                onClick={() => {
                                    setSelectedId(obj.id)
                                    setDeleteConfirmationShown(true)
                                    // console.log(obj.id)
                                    // history.push("/account/edit/"+obj.id)

                                }}
                                color={'#d64954'} size={20}
                                style={{cursor: "pointer"}}
                            />
                        </Col>

                    </Row>

                </td>
            </tr>
        )

        let filtersResult = {};
        const privilege = obj.privilege_level === "7" ? "superadmin" : "admin";

        if (filters.length !== 0) {
            let show = true;

            for (let idx = 0; idx < filters.length; idx++) {
                const filter = filters[idx];

                if (filtersResult[filter.type]) {
                    filtersResult[filter.type].filters.push(filter.value)
                } else {
                    filtersResult = {
                        ...filtersResult,
                        [filter.type]: {
                            filters: [filter.value],
                            found: false
                        }
                    }
                }
                // if (filterType === 'Email' && filter.value !== obj.email) {
                //     show = false;
                //     break;
                // }
                //
                // if (filterType === 'Account ID' && filter.value !==  util.idFormatter(obj.id, 3, "admin")) {
                //     show = false;
                //     break;
                // }
                //
                // if (filterType === 'Account Name' && filter.value !== obj.name) {
                //     show = false;
                //     break;
                // }
                //
                // if (filterType === 'Account Role' && filter.value !== privilege) {
                //     show = false;
                //     break;
                // }
            }

            Object.keys(filtersResult).map(key => {
                filtersResult[key].filters.map(filter => {
                    if (key === 'Email' && filter === obj.email) {
                        filtersResult[key].found = true
                    } else if (key === 'Account ID' && filter ===util.idFormatter(obj.id, 3, "admin")) {
                        filtersResult[key].found = true
                    } else if (key === 'Account Name' && filter === obj.name) {
                        filtersResult[key].found = true
                    } else if (key === 'Account Role' && filter === privilege) {
                        filtersResult[key].found = true
                    }
                })
            })

            Object.keys(filtersResult).map(key => {
                if (!filtersResult[key].found) show = false
            })

            if (show) return adminRow
        } else return adminRow;
    }

    return (
        <AppContainer active={'Accounts'}>
            <ConfirmationModal show={isDeleteConfirmationShown} onConfirm={() => deleteAdmin(selectedId)}
                               onCancel={() => setDeleteConfirmationShown(false)}
                               title={'Are you sure you want to delete this account?'}/>

            <Container style={{paddingBottom: 100}}>
                <Row>
                    <Col lg={3}/>
                    <Col
                        lg={6}
                        style={{...styles.header, marginBottom: 50}}>
                        Accounts List
                    </Col>

                    <Col sm={12} lg={3} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button
                            onClick={() => {
                                history.push("/account/add")
                            }}
                            style={{
                                ...styles.button,
                                width: 180,
                                fontSize: '.85em',
                                fontWeight: '600',
                                boxShadow: ' 0px 6px 5px 0px rgba(186,182,186,.8)',
                                marginBottom: 60,
                                height: 55
                            }}
                        >
                            Add New Account
                        </Button>
                    </Col>
                </Row>

                <Row>
                    {
                        windowWidth < 768 ? null : sortBtn
                    }
                    <Col style={{
                        display: windowWidth < 768 ? 'block' : 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-start'
                    }}>
                        <div style={{
                            border: '1px #d4d4d4 solid',
                            borderRadius: 10,
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <FaSearch color={Palette.COLOR_PRIMARY} style={{marginRight: 20, marginLeft: 15}}/>

                            <InputBase
                                style={{fontSize: '.9em'}}
                                placeholder="Type keyword here..."
                                onChange={(e) => setSearchKeyword(e.target.value)}
                            />
                        </div>
                    </Col>

                    {
                        windowWidth < 768 ? sortBtn : null
                    }
                </Row>

                <div style={{
                    height: isFilterExpanded ? '100%' : 0,
                    opacity: isFilterExpanded ? 1 : 0,
                    transition: 'opacity .15s'
                }}>
                    <Row style={{marginTop: 20}}>
                        <Col xs={12} md={6}>

                            <div style={{color: Palette.COLOR_PRIMARY}}>
                                <Row>
                                    <Col xs={5} lg={4}>
                                        <FaFilter size={14} style={{marginRight: 23}}/>

                                        <span style={{fontWeight: '600', fontSize: '1.05em'}}>Filter By</span>
                                    </Col>

                                    <Col>
                                        <Row>
                                            <Col md={12} lg={6} style={{paddingLeft: 0, marginBottom: 10}}>
                                                <Select
                                                    placeholder={'Account ID'}
                                                    value={null}
                                                    options={accountIdOptions}
                                                    onChange={(option) => addFilter({type: 'Account ID', value: option.value})}
                                                    styles={styles.select}
                                                    components={{DropdownIndicator, IndicatorSeparator: () => null}}
                                                />
                                            </Col>

                                            <Col md={12} lg={6} style={{paddingLeft: 0, marginBottom: 10}}>
                                                <Select
                                                    placeholder={'Account Name'}
                                                    options={nameOptions}
                                                    value={null}
                                                    onChange={(option) => addFilter({type: 'Account Name', value: option.value})}
                                                    styles={styles.select}
                                                    components={{DropdownIndicator, IndicatorSeparator: () => null}}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </Col>

                        <Col>

                            <Row>
                                {windowWidth < 768 ? <Col xs={5}/> : null}
                                <Col>
                                    <Row>

                                        <Col md={6} lg={4} style={{paddingLeft: 0, marginBottom: 10}}>
                                            <Select
                                                placeholder={'Account Role'}
                                                value={null}
                                                options={[
                                                    {label: 'superadmin', value: 'superadmin'},
                                                    {label: 'admin', value: 'admin'}
                                                ]}
                                                onChange={(option) => addFilter({type: 'Account Role', value: option.value})}
                                                styles={styles.select}
                                                components={{DropdownIndicator, IndicatorSeparator: () => null}}
                                            />
                                        </Col>

                                        <Col md={6} lg={4} style={{paddingLeft: 0, marginBottom: 10}}>
                                            <Select
                                                placeholder={'Email Address'}
                                                value={null}
                                                onChange={(option) => addFilter({type: 'Email', value: option.value})}
                                                options={emailOptions}
                                                styles={styles.select}
                                                components={{DropdownIndicator, IndicatorSeparator: () => null}}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row style={{marginTop: 10}}>
                        <Col style={{display: 'flex', flexWrap: 'wrap'}} xs={12} md={8}>

                            {
                                chosenSorterField ?
                                    <div style={{
                                        borderRadius: 50,
                                        backgroundColor: '#e6e7e8',
                                        paddingLeft: 20,
                                        paddingTop: 8,
                                        paddingBottom: 8,
                                        display: 'flex',
                                        alignItems: 'center',
                                        paddingRight: 10,
                                        marginRight: 10
                                    }}>
                                        {chosenSorterFieldLabel}
                                        <AiOutlineClose
                                            onClick={() => {
                                                setChosenSorterFieldLabel("")
                                                setChosenSorterField(null)
                                                setChosenSorterFieldOrder(1)
                                            }}
                                            style={{
                                                marginTop: 3, marginLeft: 10, cursor: 'pointer'
                                            }}
                                        />
                                    </div>
                                    :
                                    null
                            }
                            {
                                filters.map(
                                    filter => {
                                        return (
                                            <div style={{
                                                borderRadius: 50,
                                                backgroundColor: '#e6e7e8',
                                                paddingLeft: 20,
                                                paddingTop: 8,
                                                paddingBottom: 8,
                                                display: 'flex',
                                                alignItems: 'center',
                                                paddingRight: 10,
                                                marginRight: 10,
                                                marginTop: 10
                                            }}>
                                                {filter.type}: {filter.value}
                                                <AiOutlineClose
                                                    onClick={() => deleteFilter(filter)}
                                                    style={{marginTop: 3, marginLeft: 10, cursor: 'pointer'}}/>
                                            </div>
                                        )
                                    }
                                )
                            }
                        </Col>

                        <Col style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
                            <a href={'#'}
                               style={{color: Palette.COLOR_PRIMARY, fontWeight: '600'}}
                               onClick={(e) => {
                                   e.preventDefault();
                                   setFilterExpanded(false)
                               }}>
                                Collapse

                                <FaCaretUp color={Palette.COLOR_PRIMARY} style={{marginLeft: 10}}/>
                            </a>
                        </Col>
                    </Row>

                    <Row style={{
                        width: '100%',
                        height: 1,
                        backgroundColor: '#878787',
                        marginLeft: 0,
                        marginTop: 15,
                        marginBottom: 30
                    }}/>
                </div>

                <Row style={{
                    marginLeft: 0,
                    marginRight: 0,
                    marginTop: 25,
                    width: '100%'
                }}>
                    <Table responsive>
                        <thead>
                        <tr style={{
                            backgroundColor: '#b8babf',
                            fontFamily: 'Roboto',
                            fontWeight: '500',
                            color: 'white',
                            textAlign: 'center'
                        }}>
                            <th>
                                #
                            </th>

                            <th>
                                Account ID
                            </th>

                            <th>
                                Account Name
                            </th>

                            <th>
                                Account Role
                            </th>

                            <th>
                                Email Address
                            </th>

                            <th>
                                Action
                            </th>
                        </tr>
                        </thead>

                        <tbody style={{textAlign: 'center'}}>


                        {
                            sortData(admins).map((obj, key) => {
                                return filterAdmin(obj,key)
                            })
                        }

                        </tbody>
                    </Table>
                </Row>

            </Container>
        </AppContainer>
    )
}
