import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import React, {useEffect, useRef, useState} from "react";
import {AiOutlineClose} from "react-icons/ai";
import Button from "react-bootstrap/Button";
import styles from "../styles";
import moment from "moment";
import ORDER_STEPS from "../assets/values/ORDER_STEPS";

export default function ImageViewModal(props) {
    const {
        show,
        onClose,
        windowWidth,
        order,
        image_url
    } = props;

    const containerRef = useRef(null);
    const [containerWidth, setContainerWidth] = useState(0);
    const [isTableShown, setTableShown] = useState(false);

    const disableScrolling = () => {
        const x = window.scrollX;
        const y = window.scrollY;
        window.onscroll = function () {
            window.scrollTo(x, y);
        };
    }

    const enableScrolling = () => {
        window.onscroll = function () {
        };
    }

    useEffect(() => {
        if (show) disableScrolling()
        else enableScrolling()

        // setTableShown(false)
        //
        // setTimeout(() => {
        //     setContainerWidth(containerRef.current.clientWidth)
        //
        //     setTimeout(() => {
        //         setTableShown(true)
        //     }, 50)
        // }, 50)
    }, [show, windowWidth, containerRef])

    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                right: 0,
                width: '100%',
                minHeight: '100vh',
                zIndex: show ? 2 : -1,
                backgroundColor: '#00000059',
                opacity: show ? 1 : 0,
                transition: 'zIndex .2s',
                maxHeight: '100%',
                overflowY: 'scroll',
                paddingTop: 30,
                paddingBottom: 30,
            }}>
            <Container
                style={{minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 50}}>
                <Row style={{
                    backgroundColor: '#f7f7f7',
                    boxShadow: '0px 4px 5px 0px rgba(0,0,0,0.3)',
                    width: '100%',
                    maxWidth: 800,
                    borderRadius: 15,
                    paddingBottom: 50,
                }}>
                    <Row style={{
                        width: '100%',
                        marginTop: 30,
                        marginLeft: 15,
                        marginRight: 15,
                        marginBottom: 40
                    }}>
                        <Col xs={9}/>

                        <Col style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <AiOutlineClose
                                onClick={onClose}
                                style={{cursor: 'pointer'}} size={27}/>
                        </Col>
                    </Row>

                    <Row style={{
                        paddingTop: 20,
                        paddingLeft: 15,
                        paddingRight: 15,
                        backgroundColor: 'white',
                        border: '1px solid grey',
                        width: '100%',
                        marginLeft: 20,
                        marginRight: 20,
                        display: 'flex',
                        flexDirection: 'row'
                    }}>

                        <Col md={12} style={{display: 'flex', justifyContent: 'center', paddingTop: 40, paddingBottom: 35}}>
                            <img
                                style={{
                                    width : "100%"
                                }}
                                src={image_url}/>
                        </Col>

                    </Row>

                    {/*<Row style={{*/}
                    {/*    width: '100%',*/}
                    {/*    maxWidth: 800,*/}
                    {/*    display: 'flex',*/}
                    {/*    justifyContent: 'flex-end',*/}
                    {/*    marginRight: 20*/}
                    {/*}}>*/}
                    {/*    <Button style={{...styles.button, marginTop : 20, marginBottom: 0}}>Print</Button>*/}
                    {/*</Row>*/}
                </Row>


            </Container>
        </div>
    )
}
