import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Palette from "../Palette";
import {AiOutlineArrowsAlt, AiOutlineArrowUp, AiOutlineHome, AiOutlineMenu, AiOutlineLogout} from "react-icons/ai";
import {FaSearchDollar} from "react-icons/fa";
import {RiAccountCircleFill, RiAccountCircleLine, RiUserReceivedLine} from "react-icons/ri";
import {MdKeyboardArrowDown, MdKeyboardArrowUp} from "react-icons/md";
import styles from "../styles";
import Dropdown from "react-bootstrap/Dropdown";
import {IoIosPaper} from "react-icons/io";
import {CgFileDocument} from "react-icons/cg";
import {BiEdit} from "react-icons/bi";
import {HiOutlineUserGroup} from "react-icons/hi";
import GlobalData from "../util/GlobalData";

export default function AppContainer(props) {
    const {active, activeSubMenu} = props;

    const history = useHistory();

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isBurgerIconHovered, setBurgerIconHovered] = useState(false);
    const [isDrawerOverlayShown, setDrawerOverlayShown] = useState(false)
    const [isDrawerShown, setDrawerShown] = useState(false);
    const [clickedSubMenu, setClickedSubMenu] = useState(false);

    const [menus, setMenus] = useState({
        'Dashboard': {
            url: '/dashboard',
            icon:
                <AiOutlineHome style={styles.iconDrawer}/>
        },
        'Orders': {
            icon: <RiUserReceivedLine style={styles.iconDrawer}/>,
            isExpand: false,
            subMenus:
                {
                    'Process Orders': {onClick: () => history.push('/orders')},
                    'Complains': {onClick: () => history.push('/complains')},
                    'Report': {onClick: () => history.push('/report')}
                }
        },
        // 'Report': {
        //     url: '/report',
        //     icon: <CgFileDocument style={styles.iconDrawer}/>
        // },
        'Logs': {
            icon: <BiEdit style={styles.iconDrawer}/>,
            url: '/logs',
        },
        'Accounts': {
            icon: <RiAccountCircleLine style={styles.iconDrawer}/>,
            url: "/accounts"
        },
        'Customers': {
            icon: <HiOutlineUserGroup style={styles.iconDrawer}/>,
            url: "/customers"
        },
        'Logout': {
            icon: <AiOutlineLogout style={styles.iconDrawer}/>,
            onClick: () => {
                localStorage.clear();
                sessionStorage.clear();
                history.push("/")
            }
        }
    });

    const CustomToggle = React.forwardRef(({children, onClick}, ref) => (
        <a
            href="#"
            ref={ref}
            onMouseOver={() => setHoveredMenu(children)}
            onMouseLeave={() => setHoveredMenu(null)}
            style={{
                boxShadow: 'none',
                borderWidth: 0,
                color: children === hoveredMenu || children === active ? Palette.COLOR_PRIMARY : '#594f4f',
                fontWeight: '600',
            }}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
        </a>
    ));

    const [hoveredMenu, setHoveredMenu] = useState(null);

    useEffect(() => {
        if (!localStorage.token && !sessionStorage.token) {
            history.push("/")
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const handleResize = () => {
        if (window.innerWidth >= 768) hideDrawer()

        setWindowWidth(window.innerWidth);
    }

    const disableScrolling = () => {
        const x = window.scrollX;
        const y = window.scrollY;
        window.onscroll = function () {
            window.scrollTo(x, y);
        };
    }

    const enableScrolling = () => {
        window.onscroll = function () {
        };
    }

    const showDrawer = () => {
        disableScrolling();

        setDrawerOverlayShown(true)

        setTimeout(
            () => {
                setDrawerShown(true)
            }, 50
        )
    }

    const hideDrawer = () => {
        enableScrolling()

        setDrawerShown(false)

        setTimeout(
            () => {
                setDrawerOverlayShown(false)
            }, 500
        )
    }


    return (
        <>
            <div
                style={{
                    position: 'fixed',
                    top: 0,
                    right: 0,
                    width: '100%',
                    minHeight: '100vh',
                    zIndex: isDrawerOverlayShown ? 2 : -1,
                    backgroundColor: '#00000059',
                    opacity: isDrawerOverlayShown ? 1 : -1,
                    transition: 'zIndex .5s'
                }} onClick={hideDrawer}
            >
                <div style={{
                    position: 'fixed',
                    top: 0,
                    right: isDrawerShown ? 0 : -300,
                    width: 300,
                    minHeight: '100vh',
                    zIndex: 20,
                    backgroundColor: 'white',
                    paddingTop: 50,
                    paddingLeft: 30,
                    transition: 'right .5s'
                }} onClick={(e) => e.stopPropagation()}>
                    <div style={{textAlign: 'center', marginLeft: -30}}>
                        <img src={'/alisan-remit-logo.jpg'} style={{width: 80}}/>
                    </div>

                    <div style={{
                        height: 1,
                        backgroundColor: '#e8e8e8',
                        marginLeft: -30,
                        marginTop: 30,
                        marginBottom: 30
                    }}/>

                    {
                        Object.keys(menus).map(key => {
                            const menu = menus[key];

                            return (
                                <>
                                    <a href={menu.url ? menu.url : '#'} style={{textDecorationLine: 'none'}}>
                                        <div
                                            style={{
                                                cursor: 'pointer',
                                                fontWeight: '600',
                                                marginBottom: 35,
                                                color: hoveredMenu === key || active === key ? Palette.COLOR_PRIMARY : '#a6a6a6',
                                                textDecorationLine: hoveredMenu === key ? 'underline' : 'none'
                                            }}
                                            onClick={(e) => {
                                                if (!menu.url) e.preventDefault();

                                                if (menu.onClick) {
                                                    return menu.onClick()
                                                }

                                                if (menu.subMenus) {
                                                    const menuTemp = {...menus};

                                                    menuTemp[key].isExpand = !menu.isExpand;

                                                    setMenus(menuTemp)
                                                }
                                            }}
                                            onMouseOver={() => setHoveredMenu(key)}
                                            onMouseLeave={() => setHoveredMenu(null)}>
                                            {menu.isExpand ?
                                                <MdKeyboardArrowUp
                                                    style={{opacity: menu.subMenus ? 1 : 0, marginRight: 5}}/> :
                                                <MdKeyboardArrowDown
                                                    style={{opacity: menu.subMenus ? 1 : 0, marginRight: 5}}/>}
                                            {menu.icon}
                                            {key}

                                        </div>
                                        {menu.isExpand ?
                                            <div style={{marginTop: 30}}>
                                                {
                                                    menu.subMenus ? Object.keys(menu.subMenus).map(key => {
                                                        return (
                                                            <div style={{
                                                                cursor: 'pointer',
                                                                fontWeight: '600',
                                                                marginBottom: 35,
                                                                color: hoveredMenu === key || activeSubMenu === key ? Palette.COLOR_PRIMARY : '#a6a6a6'
                                                            }} onMouseOver={() => setHoveredMenu(key)}
                                                                 onMouseLeave={() => setHoveredMenu(null)}
                                                                 onClick={menu.subMenus[key].onClick}
                                                            >
                                                                <MdKeyboardArrowUp
                                                                    style={{opacity: 0, marginRight: 5}}/>
                                                                <RiAccountCircleFill
                                                                    style={{
                                                                        marginRight: 13,
                                                                        opacity: 0,
                                                                        fontSize: 21
                                                                    }}/>
                                                                {key}
                                                            </div>
                                                        )
                                                    }) : null
                                                }
                                            </div> : null
                                        }
                                    </a>
                                </>
                            )
                        })
                    }
                </div>
            </div>

            <div style={{width: '100%', paddingBottom: 150}}>
                <div style={{
                    position: 'fixed',
                    width: 'inherit',
                    maxWidth: 'inherit',
                    backgroundColor: 'white',
                    zIndex: 1
                }}>
                    <Row style={{
                        boxShadow: '0 0 4px 4px rgba(0, 0, 0, 0.08)',
                        paddingTop: 25,
                        paddingBottom: 22
                    }}>
                        <Col md={12} xs={3}/>
                        <Col md={3} xs={6} style={{textAlign: 'center'}}>
                            <a href={'/dashboard'}>
                                <img src={'/alisan-remit-logo.jpg'} style={{width: 80, cursor: 'pointer'}}/>
                            </a>
                        </Col>
                        <Col md={9} xs={3} style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            paddingRight: 20
                        }}>
                            {!active ? null :
                                windowWidth > 1250 ?
                                    <Row>
                                        {
                                            Object.keys(menus).map(key => {
                                                return (
                                                    <Col style={{marginRight: 50}}>
                                                        {
                                                            menus[key].subMenus ?
                                                                <Dropdown
                                                                    onSelect={(eventKey, event) => {
                                                                        if (menus[key].subMenus[eventKey].onClick) menus[key].subMenus[eventKey].onClick()
                                                                    }}>
                                                                    <Dropdown.Toggle
                                                                        id="dropdown-custom-components"
                                                                        as={CustomToggle}
                                                                    >
                                                                        {key}
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu className="super-colors">
                                                                        {
                                                                            Object.keys(menus[key].subMenus).map(
                                                                                (subMenu, idx) => {
                                                                                    if (idx !== Object.keys(menus[key].subMenus).length - 1) {
                                                                                        return (
                                                                                            <Dropdown.Item
                                                                                                eventKey={subMenu}
                                                                                                onMouseDown={() => setClickedSubMenu(subMenu)}
                                                                                                style={{
                                                                                                    backgroundColor: clickedSubMenu === subMenu || activeSubMenu === subMenu ? Palette.COLOR_PRIMARY : 'transparent',
                                                                                                    color: clickedSubMenu === subMenu || activeSubMenu === subMenu ? 'white' : '#8a8a8a',
                                                                                                    fontWeight: '500',
                                                                                                    textAlign: 'center',
                                                                                                    border: '1px solid #bababa',
                                                                                                    borderWidth: '0px 0px 1px 0px',
                                                                                                    paddingBottom: 10,
                                                                                                    paddingTop: 8
                                                                                                }}>{subMenu}</Dropdown.Item>
                                                                                        )
                                                                                    } else {
                                                                                        return (
                                                                                            <Dropdown.Item
                                                                                                eventKey={subMenu}
                                                                                                onMouseDown={() => setClickedSubMenu(subMenu)}
                                                                                                style={{
                                                                                                    backgroundColor: clickedSubMenu === subMenu || activeSubMenu === subMenu ? Palette.COLOR_PRIMARY : 'transparent',
                                                                                                    color: clickedSubMenu === subMenu || activeSubMenu === subMenu ? 'white' : '#8a8a8a',
                                                                                                    fontWeight: '500',
                                                                                                    textAlign: 'center',
                                                                                                    paddingTop: 10,
                                                                                                    paddingBottom: 8
                                                                                                }}>{subMenu}</Dropdown.Item>
                                                                                        )
                                                                                    }
                                                                                }
                                                                            )
                                                                        }


                                                                    </Dropdown.Menu>
                                                                </Dropdown> :
                                                                <a href={menus[key].url ? menus[key].url : '#'}
                                                                   onMouseOver={() => setHoveredMenu(key)}
                                                                   onMouseLeave={() => setHoveredMenu(null)}
                                                                   onClick={() => {
                                                                       if (menus[key].onClick) {
                                                                           return menus[key].onClick()
                                                                       }
                                                                   }}
                                                                   style={{
                                                                       color: key === hoveredMenu || key === active ? Palette.COLOR_PRIMARY : '#594f4f',
                                                                       fontWeight: '600'
                                                                   }}>
                                                                    {key}
                                                                </a>
                                                        }

                                                    </Col>
                                                )
                                            })
                                        }
                                    </Row> :
                                    <Row>
                                        <Col onMouseOver={() => setBurgerIconHovered(true)}
                                             onMouseLeave={() => setBurgerIconHovered(false)}
                                             onClick={showDrawer}
                                             style={{
                                                 marginRight: 25,
                                                 cursor: 'pointer',
                                                 backgroundColor: isBurgerIconHovered ? '#f2f2f2' : 'white',
                                                 padding: 8
                                             }}
                                        >
                                            <AiOutlineMenu size={25} color={'#575757'}/>
                                        </Col>
                                    </Row>
                            }
                        </Col>
                    </Row>
                </div>
            </div>

            {
                props.children
            }
        </>
    )
}
