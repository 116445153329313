import AppContainer from "../reusables/AppContainer";
import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import {Col} from "react-bootstrap";
import styles from "../styles";
import {FaAngleDown, FaCaretDown, FaCaretUp, FaFilter, FaSearch} from "react-icons/fa";
import InputBase from "@material-ui/core/InputBase";
import Palette from "../Palette";
import Row from "react-bootstrap/Row";
import Select, {components} from "react-select";
import {AiOutlineClose} from "react-icons/ai";
import {MdSort} from "react-icons/md";
import OrderCard from "../reusables/OrderCard";
import Table from "react-bootstrap/Table";
import DateRangePicker from "../reusables/DateRangePicker";
import AdminDao from "../daos/AdminDao";
import util from "../util/util";
import moment from "moment";
import _ from "lodash"

export default function LogsPage() {
    const [accountIdOptions, setAccountIdOptions] = useState([]);
    const [actionOptions, setActionOptions] = useState([]);
    const [logIdOptions, setLogIdOptions] = useState([]);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isFilterExpanded, setFilterExpanded] = useState(false)
    const [isDateRangeHovered, setDateRangeHovered] = useState(false);
    const [isDateRangePickerShown, setDateRangePickerShown] = useState(false);

    const [logs, setLogs] = useState([])

    const DropdownIndicator = props => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <FaAngleDown color={'black'}/>
                </components.DropdownIndicator>
            )
        );
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        getLogs()
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    const getLogs = async () => {
        let result = await AdminDao.logs()

        console.log(result)

        const accountIdTemp = [];
        const actionTemp = [];
        const logIdTemp = [];

        result.map(log => {
            if (accountIdTemp.length === 0) accountIdTemp.push({
                value: util.idFormatter(log.admin_id, 3, "admin"),
                label: util.idFormatter(log.admin_id, 3, "admin")
            });
            else {
                let found = false;

                accountIdTemp.map(accountId => {
                    if (JSON.stringify(accountId) === JSON.stringify({
                        value: util.idFormatter(log.admin_id, 3, "admin"),
                        label: util.idFormatter(log.admin_id, 3, "admin")
                    })) found = true;
                })

                if (!found) accountIdTemp.push({
                    value: util.idFormatter(log.admin_id, 3, "admin"),
                    label: util.idFormatter(log.admin_id, 3, "admin"),
                });
            }

            if (actionTemp.length === 0) actionTemp.push({
                value: log.action_name,
                label: log.action_name
            });
            else {
                let found = false;

                actionTemp.map(action => {
                    if (JSON.stringify(action) === JSON.stringify({
                        value: log.action_name,
                        label: log.action_name
                    })) found = true;
                })

                if (!found) actionTemp.push({value: log.action_name, label: log.action_name});
            }

            if (logIdTemp.length === 0) logIdTemp.push({
                value: util.idFormatter(log.id, 4),
                label: util.idFormatter(log.id, 4)
            });
            else {
                let found = false;

                logIdTemp.map(logId => {
                    if (JSON.stringify(logId) === JSON.stringify({
                        value: util.idFormatter(log.id, 4),
                        label: util.idFormatter(log.id, 4)
                    })) found = true;
                })

                if (!found) logIdTemp.push({value: util.idFormatter(log.id, 4), label: util.idFormatter(log.id, 4)});
            }
        })

        setAccountIdOptions(accountIdTemp);
        setActionOptions(actionTemp);
        setLogIdOptions(logIdTemp);
        setLogs(result)
    }

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    }

    const sorterFields = [{
        key: "id",
        label: "Log ID"
    }, {
        key: "timestamp",
        label: "Time",
    }, {
        key: "admin_id",
        label: "Account ID",
    }]

    const formattedSorterFields = []

    sorterFields.forEach((obj, key) => {
        formattedSorterFields.push({
            value: key * 2,
            sorter: obj.key,
            label: obj.label + " ASC",
            order: 1,
        })
        formattedSorterFields.push({
            value: key * 2 + 1,
            sorter: obj.key,
            label: obj.label + " DESC",
            order: -1,
        })
    })

    const [chosenSorterField, setChosenSorterField] = useState(null)
    const [chosenSorterFieldLabel, setChosenSorterFieldLabel] = useState(null)
    const [chosenSorterFieldOrder, setChosenSorterFieldOrder] = useState(1)

    const sortData = (arr) => {


        if (chosenSorterField === null) {
            return arr
        }

        let tempArr = arr.map((obj) => {
            if (typeof obj[chosenSorterField] === "string" || obj[chosenSorterField] instanceof String) {
                let tempObj = {...obj}
                tempObj[chosenSorterField] = tempObj[chosenSorterField].toLowerCase()
                return tempObj
            } else {
                return obj
            }
        })
        console.log(tempArr)

        let orderedBy = _.orderBy(tempArr, [chosenSorterField], [chosenSorterFieldOrder === 1 ? 'asc' : 'desc'])

        return orderedBy

    }

    const sortBtn = (
        <Col xs={12} md={6} style={{marginTop: windowWidth < 768 ? 30 : 15}}>
            <a href={'#'}
               style={{
                   color: Palette.COLOR_PRIMARY,
                   fontWeight: '600',
                   opacity: !isFilterExpanded ? 1 : 0,
                   height: !isFilterExpanded ? '100%' : 0
               }}
               onClick={(e) => {
                   e.preventDefault();
                   setFilterExpanded(true)
               }}>
                Sort and filter

                <FaCaretDown color={Palette.COLOR_PRIMARY} style={{marginLeft: 10}}/>
            </a>

            <div style={{
                color: Palette.COLOR_PRIMARY,
                height: isFilterExpanded ? '100%' : 0,
                opacity: isFilterExpanded ? 1 : 0,
                transition: 'opacity .15s'
            }}>
                <Row>
                    <Col xs={5} lg={4}>
                        <MdSort size={22} style={{marginRight: 15}}/>

                        <span style={{fontWeight: '600', fontSize: '1.05em'}}>Sort By</span>
                    </Col>

                    <Col style={{paddingLeft: 0}} xs={7} lg={4}>
                        <Select
                            // defaultValue={optionsArr[0]}
                            options={formattedSorterFields}
                            onChange={(obj, key) => {
                                console.log(obj, key)
                                setChosenSorterFieldOrder(obj.order)
                                setChosenSorterField(obj.sorter)
                                setChosenSorterFieldLabel(obj.label)
                            }}
                            styles={styles.select}
                            components={{DropdownIndicator, IndicatorSeparator: () => null}}
                        />
                    </Col>
                </Row>
            </div>
        </Col>
    );

    const deleteFilter = (filter) => {
        let deletedIdx = -1;

        filters.map((aFilter, idx) => {
            if (aFilter === filter) deletedIdx = idx;
        })

        const filtersTemp = [...filters];

        filtersTemp.splice(deletedIdx, 1);

        setFilters(filtersTemp);
    }

    const [filters, setFilters] = useState([]);
    const addFilter = (filter) => {
        let found = false;

        filters.map((aFilter, idx) => {
            console.log(aFilter)

            if (aFilter.type === filter.type && aFilter.value === filter.value) {
                const filtersTemp = [...filters];
                filtersTemp[idx] = filter;
                setFilters(filtersTemp)

                found = true;
            }
        })

        if (!found) {
            const filtersTemp = [...filters];
            filtersTemp.push(filter);
            setFilters(filtersTemp);
        }
    }

    const [searchKeyword, setSearchKeyword] = useState('');

    const filterLogs = (obj, idx) => {
        if (!obj.action_detail.toLowerCase().includes(searchKeyword.toLowerCase())) return;

        const logRow = (
            <tr key={idx} style={{backgroundColor: idx % 2 === 0 ? null : '#dce1e7'}}>
                <td>{idx + 1}</td>
                <td>{util.idFormatter(obj.id, 4)}</td>
                <td>{new moment(obj.timestamp).format("YYYY-MM-DD HH:mm:ss")}</td>

                <td>{util.idFormatter(obj.admin_id, 3, "admin")}</td>
                <td>{obj.action_detail}</td>
            </tr>
        )
        let filtersResult = {};

        if (filters.length !== 0) {
            let show = true;

            for (let idx = 0; idx < filters.length; idx++) {
                const filter = filters[idx];

                if (filtersResult[filter.type]) {
                    filtersResult[filter.type].filters.push(filter.value)
                } else {
                    filtersResult = {
                        ...filtersResult,
                        [filter.type]: {
                            filters: [filter.value],
                            found: false
                        }
                    }
                }
                // if (filterType === 'Date' && !moment(obj.timestamp).isBetween(filter.value.startDate, filter.value.endDate, 'date', '[]')) {
                //     show = false;
                //     break;
                // } else if (filterType === 'Account ID' && filter.value !== util.idFormatter(obj.admin_id, 3, "admin")) {
                //     show = false;
                //     break;
                // } else if (filterType === 'Action' && filter.value !== obj.action_name) {
                //     show = false;
                //     break;
                // } else if (filterType === 'Log ID' && filter.value !== util.idFormatter(obj.id, 4)) {
                //     show = false;
                //     break;
                // }
            }

            Object.keys(filtersResult).map(key => {
                filtersResult[key].filters.map(filter => {
                    if (key === 'Date' && moment(obj.timestamp).isBetween(filter.startDate, filter.endDate, 'date', '[]')) {
                        filtersResult[key].found = true
                    } else if (key === 'Account ID' && filter === util.idFormatter(obj.admin_id, 3, "admin")) {
                        filtersResult[key].found = true
                    } else if (key === 'Action' && filter === obj.action_name) {
                        filtersResult[key].found = true
                    } else if (key === 'Log ID' && filter === util.idFormatter(obj.id, 4)) {
                        filtersResult[key].found = true
                    }
                })
            })

            Object.keys(filtersResult).map(key => {
                if (!filtersResult[key].found) show = false
            })


            if (show) return logRow
        } else return logRow;
    }

    return (
        <AppContainer active={'Logs'}>
            <Container style={{paddingBottom: 100}}>
                <DateRangePicker show={isDateRangePickerShown} onClose={() => setDateRangePickerShown(false)}
                                 onDateChanged={(dateRange) => {
                                     console.log(dateRange)
                                     addFilter({type: 'Date', value: dateRange[0]})
                                 }}/>
                <Row>

                    <Col
                        lg={12}
                        style={styles.header}>
                        Activity Logs
                    </Col>

                </Row>

                <Row>
                    {
                        windowWidth < 768 ? null : sortBtn
                    }
                    <Col style={{
                        display: windowWidth < 768 ? 'block' : 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-start'
                    }}>
                        <div style={{
                            border: '1px #d4d4d4 solid',
                            borderRadius: 10,
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <FaSearch color={Palette.COLOR_PRIMARY} style={{marginRight: 20, marginLeft: 15}}/>

                            <InputBase
                                style={{fontSize: '.9em'}}
                                placeholder="Type keyword here..."
                                onChange={(e) => setSearchKeyword(e.target.value)}
                            />
                        </div>
                    </Col>

                    {
                        windowWidth < 768 ? sortBtn : null
                    }
                </Row>

                <div style={{
                    height: isFilterExpanded ? '100%' : 0,
                    opacity: isFilterExpanded ? 1 : 0,
                    transition: 'opacity .15s'
                }}>
                    <Row style={{marginTop: 20}}>
                        <Col xs={12} md={6}>

                            <div style={{color: Palette.COLOR_PRIMARY}}>
                                <Row>
                                    <Col xs={5} lg={4}>
                                        <FaFilter size={14} style={{marginRight: 23}}/>

                                        <span style={{fontWeight: '600', fontSize: '1.05em'}}>Filter By</span>
                                    </Col>

                                    <Col>
                                        <Row>
                                            <Col md={12} lg={6} style={{paddingLeft: 0, marginBottom: 10}}>
                                                <div
                                                    onMouseOver={() => setDateRangeHovered(true)}
                                                    onMouseLeave={() => setDateRangeHovered(false)}
                                                    onClick={() => setDateRangePickerShown(true)}
                                                    style={{
                                                        border: `1px ${isDateRangeHovered ? '#bababa' : '#e6e6e6'} solid`,
                                                        width: '100%',
                                                        paddingTop: 6,
                                                        paddingBottom: 6,
                                                        borderRadius: 4,
                                                        color: '#4f4f4f',
                                                        paddingLeft: 10,
                                                        display: 'flex', alignItems: 'center',
                                                        paddingRight: 9
                                                    }}>
                                                    <span style={{flex: 1, color: 'grey', cursor: 'default'}}>
                                                        Date Range
                                                    </span>

                                                    <FaAngleDown color={'black'}/>
                                                </div>
                                            </Col>

                                            <Col md={12} lg={6} style={{paddingLeft: 0, marginBottom: 10}}>
                                                <Select
                                                    placeholder={'Log ID'}
                                                    onChange={(option) => addFilter({
                                                        type: 'Log ID',
                                                        value: option.value
                                                    })}
                                                    value={null}
                                                    options={logIdOptions}
                                                    styles={styles.select}
                                                    components={{DropdownIndicator, IndicatorSeparator: () => null}}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </Col>

                        <Col>

                            <Row>
                                {windowWidth < 768 ? <Col xs={5}/> : null}
                                <Col>
                                    <Row>

                                        <Col md={6} lg={4} style={{paddingLeft: 0, marginBottom: 10}}>
                                            <Select
                                                value={null}
                                                placeholder={'Account ID'}
                                                onChange={(option) => addFilter({
                                                    type: 'Account ID',
                                                    value: option.value
                                                })}
                                                options={accountIdOptions}
                                                styles={styles.select}
                                                components={{DropdownIndicator, IndicatorSeparator: () => null}}
                                            />
                                        </Col>

                                        <Col md={6} lg={4} style={{paddingLeft: 0, marginBottom: 10}}>
                                            <Select
                                                value={null}
                                                placeholder={'Action'}
                                                onChange={(option) => addFilter({type: 'Action', value: option.value})}
                                                options={actionOptions}
                                                styles={styles.select}
                                                components={{DropdownIndicator, IndicatorSeparator: () => null}}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row style={{marginTop: 10}}>
                        <Col style={{display: 'flex', flexWrap: 'wrap'}} xs={12} md={8}>

                            {
                                chosenSorterField ?
                                    <div style={{
                                        borderRadius: 50,
                                        backgroundColor: '#e6e7e8',
                                        paddingLeft: 20,
                                        paddingTop: 8,
                                        paddingBottom: 8,
                                        display: 'flex',
                                        alignItems: 'center',
                                        paddingRight: 10,
                                        marginRight: 10
                                    }}>
                                        {chosenSorterFieldLabel}
                                        <AiOutlineClose
                                            onClick={() => {
                                                setChosenSorterFieldLabel("")
                                                setChosenSorterField(null)
                                                setChosenSorterFieldOrder(1)
                                            }}
                                            style={{
                                                marginTop: 3, marginLeft: 10, cursor: 'pointer'
                                            }}
                                        />
                                    </div>
                                    :
                                    null
                            }
                            {
                                filters.map(
                                    filter => {
                                        return (
                                            <div style={{
                                                borderRadius: 50,
                                                backgroundColor: '#e6e7e8',
                                                paddingLeft: 20,
                                                paddingTop: 8,
                                                paddingBottom: 8,
                                                display: 'flex',
                                                alignItems: 'center',
                                                paddingRight: 10,
                                                marginRight: 10,
                                                marginTop: 10
                                            }}>
                                                {filter.type}: {filter.type === 'Customer' || filter.type === 'Status' ? filter.value.label : filter.type === 'Date' ? moment(filter.value.startDate).format('DD/MM/YYYY') + ' - ' + moment(filter.value.endDate).format('DD/MM/YYYY') : filter.value}
                                                <AiOutlineClose
                                                    onClick={() => deleteFilter(filter)}
                                                    style={{marginTop: 3, marginLeft: 10, cursor: 'pointer'}}/>
                                            </div>
                                        )
                                    }
                                )
                            }

                        </Col>

                        <Col style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
                            <a href={'#'}
                               style={{color: Palette.COLOR_PRIMARY, fontWeight: '600'}}
                               onClick={(e) => {
                                   e.preventDefault();
                                   setFilterExpanded(false)
                               }}>
                                Collapse

                                <FaCaretUp color={Palette.COLOR_PRIMARY} style={{marginLeft: 10}}/>
                            </a>
                        </Col>
                    </Row>

                    <Row style={{
                        width: '100%',
                        height: 1,
                        backgroundColor: '#878787',
                        marginLeft: 0,
                        marginTop: 15,
                        marginBottom: 30
                    }}/>
                </div>

                <Row style={{
                    marginLeft: 0,
                    marginRight: 0,
                    marginTop: 25,
                    width: '100%'
                }}>
                    <Table responsive>
                        <thead>
                        <tr style={{
                            backgroundColor: '#b8babf',
                            fontFamily: 'Roboto',
                            fontWeight: '500',
                            color: 'white'
                        }}>
                            <th>
                                #
                            </th>

                            <th>
                                Log ID
                            </th>

                            <th>
                                Time
                            </th>

                            <th>
                                Account ID
                            </th>

                            <th>
                                Action
                            </th>
                        </tr>
                        </thead>

                        <tbody>
                        {
                            sortData(logs).map((obj, key) => {
                                return filterLogs(obj, key)
                            })
                        }

                        </tbody>
                    </Table>
                </Row>

            </Container>
        </AppContainer>
    )
}
