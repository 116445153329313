import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "@material-ui/core/Button";
import styles from "../styles";
import AppContainer from "./AppContainer";
import React, {useEffect, useState} from "react";
import {AiOutlineClose} from "react-icons/ai";
import CurrencyDAO from "../daos/CurrencyDAO";
import DefaultTextInput from "./DefaultTextInput";

export default function ChangeMarginModal(props) {

    let [buyMargin, setBuyMargin] = useState(0)
    let [sellMargin, setSellMargin] = useState(0)
    let [fee, setFee] = useState(0);

    const {
        show,
        onClose
    } = props;

    const disableScrolling = () => {
        const x = window.scrollX;
        const y = window.scrollY;
        window.onscroll = function () {
            window.scrollTo(x, y);
        };
    }

    const enableScrolling = () => {
        window.onscroll = function () {
        };
    }

    useEffect(() => {
        if (show) disableScrolling()
        else enableScrolling()
    }, [show])

    useEffect(() => {
        setBuyMargin(props.buyMargin * 100)
        setSellMargin(props.sellMargin * 100)
        setFee(props.fee)
    }, [props.buyMargin, props.sellMargin, props.fee])

    const submit = async () => {

        try {
            let result = await CurrencyDAO.setMargins({
                margin_buy: buyMargin / 100,
                margin_sell: sellMargin / 100,
                fee
            })
            console.log(result)
            onClose()

        } catch (e) {
            console.log(e)
        }
    }

    const onKeyPress = (ev) => {
        if (ev.key === 'Enter') {
            ev.preventDefault();

            submit()
        }
    }

    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                right: 0,
                width: '100%',
                minHeight: '100vh',
                zIndex: show ? 2 : -1,
                backgroundColor: '#00000059',
                opacity: show ? 1 : 0,
                transition: 'zIndex .2s'
            }}>
            <Container
                style={{minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Row style={{
                    backgroundColor: '#f5f5f5',
                    boxShadow: '0px 4px 5px 0px rgba(0,0,0,0.3)',
                    width: '100%',
                    maxWidth: 360,
                    borderRadius: 15,
                    paddingBottom: 20
                }}>
                    <Row style={{width: '100%', marginTop: 30, marginLeft: 15, marginRight: 15, marginBottom: 40}}>
                        <Col xs={9} style={{fontSize: '1.2em', fontFamily: 'Roboto', fontWeight: '400'}}>
                            Margin
                        </Col>

                        <Col style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <AiOutlineClose
                                style={{cursor: 'pointer'}} size={27} onClick={onClose}/>
                        </Col>
                    </Row>

                    <Row style={{width: '100%', marginLeft: 15, marginRight: 15}}>
                        <Col xs={6} style={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: 0,
                            marginBottom: 10,
                            fontFamily: 'Roboto',
                            fontWeight: '400'
                        }}>
                            Buy Rate Margin
                        </Col>
                        <Col style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Form.Control
                                type={'tel'}
                                onKeyPress={onKeyPress}
                                value={buyMargin}
                                onChange={(e) => {
                                    if (e.target.value.length === 2 && buyMargin.toString().charAt(0) === "0") {
                                        setBuyMargin(e.target.value.toString().substr(e.target.value.length - 1))
                                    } else {
                                        if (!isNaN(e.target.value)) {
                                            if (e.target.value <= 100) {
                                                setBuyMargin(e.target.value)
                                            } else {
                                                alert('Margin can only be filled with value from 0 to 100')
                                                setBuyMargin(e.target.value.replace(/.$/,''))
                                            }
                                        } else setBuyMargin(e.target.value.replace(/\D/g, ''))
                                    }
                                }}
                                style={{
                                    fontSize: "1em",
                                    borderColor: '#e6e6e6',
                                    maxWidth: 100,
                                    textAlign: 'center',
                                    color: 'grey',
                                    fontFamily: 'Roboto',
                                    fontWeight: '500'
                                }}/>
                        </Col>
                    </Row>

                    <Row style={{width: '100%', marginLeft: 15, marginRight: 15, marginTop: 15}}>
                        <Col xs={6} style={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: 0,
                            marginBottom: 10,
                            fontFamily: 'Roboto',
                            fontWeight: '400'
                        }}>
                            Sell Rate Margin
                        </Col>
                        <Col style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Form.Control
                                type={'tel'}
                                onKeyPress={onKeyPress}
                                value={sellMargin}
                                onChange={(e) => {
                                    if (e.target.value.length === 2 && sellMargin.toString().charAt(0) === "0") {
                                        setSellMargin(e.target.value.toString().substr(e.target.value.length - 1))
                                    } else {
                                        if (!isNaN(e.target.value)) {
                                            if (e.target.value <= 100) {
                                                setSellMargin(e.target.value)
                                            } else {
                                                alert('Margin can only be filled with value from 0 to 100')
                                                setSellMargin(e.target.value.replace(/.$/,''))
                                            }
                                        } else setSellMargin(e.target.value.replace(/\D/g, ''))
                                    }
                                }}
                                style={{
                                    fontSize: "1em",
                                    borderColor: '#e6e6e6',
                                    maxWidth: 100,
                                    textAlign: 'center',
                                    color: 'grey',
                                    fontFamily: 'Roboto',
                                    fontWeight: '500'
                                }}/>
                        </Col>
                    </Row>

                    <Row style={{width: '100%', marginLeft: 15, marginRight: 15, marginTop: 15}}>
                        <Col xs={6} style={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: 0,
                            marginBottom: 10,
                            fontFamily: 'Roboto',
                            fontWeight: '400'
                        }}>
                            Fee
                        </Col>
                        <Col style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Form.Control
                                type={'tel'}
                                onKeyPress={onKeyPress}
                                value={fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                onChange={(e) => {
                                    if (e.target.value.length === 1 && e.target.value === "0") {
                                        setFee('')
                                    } else {
                                        if (!isNaN(e.target.value)) setFee(e.target.value)
                                        else setFee(e.target.value.replace(/\D/g, ''))
                                    }
                                }}
                                style={{
                                    fontSize: "1em",
                                    borderColor: '#e6e6e6',
                                    maxWidth: 100,
                                    textAlign: 'center',
                                    color: 'grey',
                                    fontFamily: 'Roboto',
                                    fontWeight: '500'
                                }}/>
                        </Col>
                    </Row>

                    <Row style={{width: '100%', marginTop: 30}}>
                        <Col style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button onClick={() => {
                                submit()
                            }} style={{...styles.button}}>
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </Row>
            </Container>
        </div>
    )
}
