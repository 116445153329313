import Container from "react-bootstrap/Container";
import React, {useRef, useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";

export default function DefaultFilePicker(props) {
    const acceptedExtensions = ['jpg', 'jpeg', 'png', 'pdf'];
    const inputFile = useRef(null);
    const {field, required, file, setFile, isUploading, errors} = props;
    const error = errors[field];
    return (
        <>
            <input type='file' id='file' ref={inputFile} style={{display: 'none'}} onChange={(e) => {
                const extension = e.target.files[0].name.split('.')[e.target.files[0].name.split('.').length - 1];
                let isAccepted = false;

                acceptedExtensions.map(acceptedExtension => {
                    if (acceptedExtension.toLowerCase() === extension.toLowerCase()) isAccepted = true;
                })

                console.log(e.target.files[0]);
                if (!isAccepted) alert(`Please upload only ${acceptedExtensions.toString().replace(/\,/gi, '/').toUpperCase()} extension file`)
                else setFile(e.target.files[0])
            }}/>

            <Row style={{marginBottom: 5}}>
                <Col style={{display: 'flex', justifyContent: 'center'}}>
                    <Form.Group style={{width: 'inherit', maxWidth: 580}}>
                        <Form.Label
                            style={{
                                marginBottom: 6,
                                fontWeight: '700',
                                fontSize: '.9em',
                                color: '#a0a4a7'
                            }}>{field} {required ? ' *' : null}</Form.Label>
                        <div onClick={() => {
                            if(!props.disabled){
                                if(!props.readOnly) inputFile.current.click()
                                else window.open(props.url, '_self');
                            }
                        }}
                             style={{display: 'flex', alignItems: 'flex-start'}}>
                            <div style={{cursor: 'pointer', backgroundColor: '#dce1e5', color: '#3f9e59', paddingLeft: 30, paddingRight: 30, paddingTop: 2, paddingBottom: 2, borderRadius: 50}}>
                                {isUploading ?
                                    <>
                                        Uploading

                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            style={{marginLeft: 10}}
                                        />
                                    </> :
                                    <>
                                        {file ? file.name : 'UPLOAD'}
                                    </>
                                }
                            </div>
                        </div>

                        {error ? <span style={{marginLeft: '1rem', marginTop: '.25rem', fontSize: '80%', width: '100%', color: '#DC3545'}}>{error}</span> : null}
                    </Form.Group>
                </Col>
            </Row>
        </>
    )
}
