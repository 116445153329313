import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import {Col} from "react-bootstrap";
import Button from "@material-ui/core/Button";
import styles from "../styles";
import AppContainer from "./AppContainer";
import React from "react";

export default function ConfirmationModal (props) {
    const {show, title, onCancel, onConfirm, noDelete} = props;

    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                right: 0,
                width: '100%',
                minHeight: '100vh',
                zIndex: show ? 2 : -1,
                backgroundColor: '#00000059',
                opacity: show ? 1 : 0,
                transition: 'zIndex .2s'
            }}>
            <Container
                style={{minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Row style={{
                    backgroundColor: '#f5f5f5',
                    boxShadow: '0px 4px 5px 0px rgba(0,0,0,0.3)',
                    width: '100%',
                    maxWidth: 400,
                    borderRadius: 15,
                    paddingBottom: 40
                }}>
                    <Row style={{width: '100%', marginTop: 40, marginLeft: 15, marginRight: 15, marginBottom: 15}}>
                        <Col style={{fontSize: '1.2em', textAlign: 'center', fontFamily: 'Roboto', fontWeight: '400'}}>
                            {title}
                        </Col>
                    </Row>

                    <Row style={{width: '100%', marginTop: 40, marginBottom: 15, marginLeft: 15, marginRight: 15}}>
                        <Col style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button style={{...styles.button, backgroundColor: 'white', color: '#a3a3a3', border: `1px solid #757575`}} onClick={onCancel}>
                                No, Cancel
                            </Button>
                        </Col>

                        <Col>
                            <Button style={{...styles.button, backgroundColor: '#d45d5d', color: 'white'}} onClick={onConfirm}>
                                Yes {noDelete ? '' : ', Delete'}
                            </Button>
                        </Col>
                    </Row>
                </Row>
            </Container>
        </div>
    )
}