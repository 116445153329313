import React, {useEffect, useState} from "react";
import {Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis} from "recharts";
import moment from "moment";
import Palette from "../Palette";
import {FaCaretDown} from "react-icons/all";
import {Row} from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DefaultSelectInput from "./DefaultSelectInput";
import Col from "react-bootstrap/Col";

const siteVisit = [
    {name: '14234', thisYear: 0, lastYear: 0},
    {name: '2', thisYear: 0, lastYear: 1},
    {name: '3', thisYear: 5, lastYear: 2},
    {name: '4', thisYear: 10, lastYear: 0},
    {name: '5', thisYear: 4, lastYear: 1},
    {name: '6', thisYear: 16, lastYear: 3},
    {name: '7', thisYear: 5, lastYear: 1},
    {name: '8', thisYear: 11, lastYear: 5},
    {name: '9', thisYear: 6, lastYear: 2},
    {name: '10', thisYear: 11, lastYear: 3},
    {name: '11', thisYear: 30, lastYear: 2},
    {name: '12', thisYear: 10, lastYear: 1},
    {name: '13', thisYear: 13, lastYear: 0},
    {name: '14', thisYear: 4, lastYear: 2},
];

const PERIOD = {
    TODAY: 'Today',
    WEEKLY: 'Weekly',
    MONTHLY: 'Monthly'
}

const PERIOD_OPTIONS = []

Object.keys(PERIOD).map(key => {
    PERIOD_OPTIONS.push(PERIOD[key])
})

const SiteVisit = (props) => {


    const [chosenData, setChosenData] = useState([])
    const [periodicity, setPeriodicity] = useState(PERIOD.TODAY);

    // console.log(chosenData)
    // console.log(props.monthlyData)

    let formattedData = [...props.dailyData]


    switch (periodicity) {

        case PERIOD.TODAY:
            //fetch today's chart
            formattedData = props.dailyData
            break;

        case PERIOD.WEEKLY:
            //fetch weekly's chart
            formattedData = props.weeklyData
            break;

        case PERIOD.MONTHLY:
            //fetch monthly's chart
            formattedData = props.monthlyData
            break;
    }

    // console.log(formattedData)

    for (let wd of formattedData) {

        wd.name = new moment(wd.date).format("MMM DD YYYY")

        switch (periodicity) {

            case PERIOD.TODAY:
                //fetch today's chart
                wd.name = new moment(wd.date).format("MMM DD YYYY")
                break;

            case PERIOD.WEEKLY:
                //fetch weekly's chart
                wd.name = new moment(wd.date, "w").format("WW")
                break;

            case PERIOD.MONTHLY:
                //fetch monthly's chart
                wd.name = new moment(wd.date, "MM").format("MMM")
                break;
        }

        wd["Incoming Request"] = wd.request
        wd["Accepted Request"] = wd.accepted

    }


    const onChangePeriodicity = (periodicity) =>{

        // console.log("case " + periodicity)
        setPeriodicity(periodicity.value)
    }

    return (
        <div className="gx-site-dash gx-pr-xl-5 gx-pt-3 gx-pt-xl-0 gx-pt-xl-2">

            <Row style={{paddingLeft: 0, paddingRight: 0}}>
                <Col style={{display: 'flex', alignItems: 'center', marginTop: 15}} xs={7} md={props.windowWidth >= 1200 && props.windowWidth <= 1485? 9 : 10}>
                    <h6 className="gx-text-uppercase gx-mb-2 gx-mb-xl-4"
                        style={{fontSize: 12, fontFamily: 'NoirPro-Light', color: '#262626'}}>Order Trends</h6>
                </Col>

                <Col>
                    <DefaultSelectInput
                        style={{width : 800}}
                        options={PERIOD_OPTIONS}
                        value={{value: periodicity, label: periodicity}}
                        onChange={(option) => onChangePeriodicity(option)}
                        alternative/>
                </Col>
            </Row>
            <ResponsiveContainer width="100%" height={140}>
                <AreaChart data={formattedData}
                           margin={{top: 10, right: 0, left: -20, bottom: 0}}>
                    <Tooltip/>
                    <XAxis dataKey="name"/>
                    <CartesianGrid horizontal={false} strokeDasharray="3 3"/>
                    <Area type='monotone' label={"Incoming Request"} dataKey='Incoming Request' fillOpacity={1}
                          stroke='#038FDE' fill='#038FDE'/>
                    <Area type='monotone' label={"Accepted Request"} dataKey={'Accepted Request'} fillOpacity={1}
                          stroke='#FE9E15' fill='#FE9E15'/>
                </AreaChart>
            </ResponsiveContainer>
        </div>
    );

}

export default SiteVisit;

