import React from 'react';
import {BrowserRouter, Switch, Route} from "react-router-dom"
import './App.css';
import SignInPage from "./pages/SignInPage";
import DashboardPage from "./pages/DashboardPage";
import OrdersPage from "./pages/OrdersPage";
import ReportPage from "./pages/ReportPage";
import PageNotFound from "./pages/PageNotFound";
import LogsPage from "./pages/LogsPage";
import AccountPage from "./pages/AccountPage";
import ManageAdmin from "./pages/ManageAdmin";
import CustomerPage from "./pages/CustomerPage";
import CustomerDetailPage from "./pages/CustomerDetailPage";
import ChangePasswordPage from "./pages/ChangePasswordPage";
import ComplaintPage from "./pages/ComplaintPage";

function App() {
    return (
        <>
            <BrowserRouter basename="/">
                <Switch>
                    <Route exact path="/" component={SignInPage}/>
                    <Route exact path="/dashboard" component={DashboardPage}/>
                    <Route exact path="/orders" component={OrdersPage}/>
                    <Route exact path="/report" component={ReportPage}/>
                    <Route exact path="/logs" component={LogsPage}/>
                    <Route exact path="/complains" component={ComplaintPage}/>
                    <Route exact path="/accounts" component={AccountPage}/>
                    <Route exact path="/customers" component={CustomerPage}/>
                    <Route exact path="/customer/details/:id" component={CustomerDetailPage}/>
                    <Route exact path="/account/:action/:id" component={ManageAdmin}/>
                    <Route exact path="/account/:action" component={ManageAdmin}/>
                    <Route exact path="/:object/change-password/:id" component={ChangePasswordPage}/>
                    <Route exact path={"*"} component={PageNotFound}/>
                </Switch>
            </BrowserRouter>
        </>
    );
}

export default App;
