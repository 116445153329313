import AppContainer from "../reusables/AppContainer";
import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import {Col} from "react-bootstrap";
import styles from "../styles";
import {FaAngleDown, FaCaretDown, FaCaretUp, FaFilter, FaSearch} from "react-icons/fa";
import InputBase from "@material-ui/core/InputBase";
import Palette from "../Palette";
import Row from "react-bootstrap/Row";
import Select, {components} from "react-select";
import {AiOutlineClose} from "react-icons/ai";
import {MdSort} from "react-icons/md";
import OrderCard from "../reusables/OrderCard";
import DateRangePicker from "../reusables/DateRangePicker";
import OrderDAO from "../daos/OrderDAO";
import util from "../util/util";
import ORDER_STEPS from "../assets/values/ORDER_STEPS";
import _ from "lodash";
import moment from "moment";
import DefaultTextInput from "../reusables/DefaultTextInput";
import TrackOrderModal from "../reusables/TrackOrderModal";

export default function ReportPage() {
    const amountRangeOptions = [
        {
            label: '< 1000',
            value: {max: 999}
        },
        {
            label: '1000 - 2000',
            value: {min: 1000, max: 1999}
        },
        {
            label: '> 2000',
            value: {min: 2000}
        },
        {
            label: 'Custom',
            value: {}
        }
    ]

    const [customersOptions, setCustomersOptions] = useState([]);
    const [currenciesOptions, setCurrenciesOptions] = useState([]);
    const [filteredRange, setFilteredRange] = useState({min: 0, max: 0});
    const [isAmountRangeCustom, setAmountRangeCustom] = useState(false);
    const [isTrackOrderModalShown, setTrackOrderModalShown] = useState(false);
    const [trackedOrders, setTrackedOrders] = useState([]);

    const deleteFilter = (filter) => {
        let deletedIdx = -1;

        filters.map((aFilter, idx) => {
            if(aFilter.type === 'Amount Range') {
                setAmountRangeCustom(false)

                setFilteredRange({min: 0, max: 0})
            }
            if (aFilter === filter) deletedIdx = idx;
        })

        const filtersTemp = [...filters];

        filtersTemp.splice(deletedIdx, 1);

        setFilters(filtersTemp);
    }

    const [filters, setFilters] = useState([]);
    const addFilter = (filter) => {
        let found = false;

        filters.map((aFilter, idx) => {
            console.log(aFilter)

            if (aFilter.type === filter.type && (aFilter.value === filter.value || filter.value.label === 'Custom')) {
                if (filter.value.label !== 'Custom') {
                    const filtersTemp = [...filters];
                    filtersTemp[idx] = filter;
                    setFilters(filtersTemp)
                }

                found = true;
            }
        })

        if (!found) {
            const filtersTemp = [...filters];
            filtersTemp.push(filter);
            setFilters(filtersTemp);
        }
    }

    const [searchKeyword, setSearchKeyword] = useState('');

    const filterData = (obj) => {
        if (!obj.order_number.toLowerCase().includes(searchKeyword.toLowerCase())) return;

        const reportCard = (
            <OrderCard
                order={obj}
                windowWidth={windowWidth}
                statusBtn={'Track Order'}
                negative={obj.current_order_step === 'PAYMENT_REJECTED' || obj.current_order_step === 'TRANSACTION_FAILED'}
                status={ORDER_STEPS[obj.current_order_step]}
                name={'Order #' + obj.order_number}
                amount={obj.destination_currency + " " + util.thousandSeparator(obj.destination_amount)}
                onSubmit={() => {
                    OrderDAO.getHistory(obj.order_number).then(res => {
                        setTrackedOrders(res);
                        setTrackOrderModalShown(true)
                    }).catch(err => console.log(err));
                }}
            />
        )

        if (filters.length !== 0) {
            let show = true;
            let filtersResult = {};

            for (let idx = 0; idx < filters.length; idx++) {
                const filter = filters[idx];

                if(filtersResult[filter.type]) {
                    filtersResult[filter.type].filters.push(filter.value)
                } else {
                    filtersResult = {
                        ...filtersResult,
                        [filter.type]: {
                            filters: [filter.value],
                            found: false
                        }
                    }
                }
            }

            Object.keys(filtersResult).map(key => {
                filtersResult[key].filters.map(filter => {
                   if(key === 'Currency' && obj.destination_currency === filter) {
                       filtersResult[key].found = true
                   } else if(key === 'Date' && moment(obj.created_at).isBetween(filter.startDate, filter.endDate, 'date', '[]')) {
                       filtersResult[key].found = true
                   } else if(key === 'Customer' && filter.value === obj.recipient.id) {
                       filtersResult[key].found = true
                   } else if(key === 'Amount Range') {
                       if(!filter.value.min && filter.value.max && obj.destination_amount <= filter.value.max) {
                           filtersResult[key].found = true
                       } else if(filter.value.min && !filter.value.max && filter.value.min <= obj.destination_amount) {
                           filtersResult[key].found = true
                       } else if(filter.value.min <= obj.destination_amount && obj.destination_amount <= filter.value.max){
                           filtersResult[key].found = true
                       }
                   }
                })
            })

            Object.keys(filtersResult).map(key => {
                if(!filtersResult[key].found) show = false
            })

            if (show) return reportCard
        } else return reportCard;
    }

    const [chosenSorterField, setChosenSorterField] = useState(null)
    const [chosenSorterFieldLabel, setChosenSorterFieldLabel] = useState(null)
    const [chosenSorterFieldOrder, setChosenSorterFieldOrder] = useState(1)

    const sorterFields = [
        {
            key: "destination_currency",
            label: "Currency",
        }, {
            key: "created_at",
            label: "Report Date",
        }
    ]

    const formattedSorterFields = []

    sorterFields.forEach((obj, key) => {
        formattedSorterFields.push({
            value: key * 2,
            sorter: obj.key,
            label: obj.label + " ASC",
            order: 1,
        })
        formattedSorterFields.push({
            value: key * 2 + 1,
            sorter: obj.key,
            label: obj.label + " DESC",
            order: -1,
        })
    })

    const sortData = (arr) => {
        if (chosenSorterField === null) {
            return arr
        }

        let tempArr = arr.map((obj) => {
            if (typeof obj[chosenSorterField] === "string" || obj[chosenSorterField] instanceof String) {
                let tempObj = {...obj}
                // tempObj[chosenSorterField] = tempObj[chosenSorterField].toLowerCase()
                return tempObj
            } else {
                return obj
            }
        })

        let orderedBy = _.orderBy(tempArr, [chosenSorterField], [chosenSorterFieldOrder === 1 ? 'asc' : 'desc'])

        return orderedBy

    }

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isFilterExpanded, setFilterExpanded] = useState(false)
    const [isDateRangeHovered, setDateRangeHovered] = useState(false);
    const [isDateRangePickerShown, setDateRangePickerShown] = useState(false);

    const [reports, setReports] = useState([])
    const [isReportApiInvoked, setReportApiInvoked] = useState(false);
    const DropdownIndicator = props => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <FaAngleDown color={'black'}/>
                </components.DropdownIndicator>
            )
        );
    };

    useEffect(() => {
        filters.map(filter => {
            if (filter.type === 'Amount Range' && filter.value.label === 'Custom') {
                setAmountRangeCustom(true)
            }
        })

        window.addEventListener('resize', handleResize);
        if(!isReportApiInvoked) getReports()
        return () => window.removeEventListener('resize', handleResize)
    }, [filters])

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    }

    const getReports = async () => {
        setReportApiInvoked(true)

        try {
            let result = await OrderDAO.getAllForReport();

            const customersTemp = [];
            const currenciesTemp = [];

            result.map(order => {
                if (customersTemp.length === 0) customersTemp.push({
                    value: order.recipient.id,
                    label: order.recipient.name
                });
                else {
                    let found = false;

                    customersTemp.map(customer => {
                        if (JSON.stringify(customer) === JSON.stringify({
                            value: order.recipient.id,
                            label: order.recipient.name
                        })) found = true;
                    })

                    if (!found) customersTemp.push({value: order.recipient.id, label: order.recipient.name});
                }

                if (currenciesTemp.length === 0) currenciesTemp.push({
                    value: order.destination_currency,
                    label: order.destination_currency
                });
                else {
                    let found = false;

                    currenciesTemp.map(currency => {
                        if (JSON.stringify(currency) === JSON.stringify({
                            value: order.destination_currency,
                            label: order.destination_currency
                        })) found = true;
                    })

                    if (!found) currenciesTemp.push({value: order.destination_currency, label: order.destination_currency});
                }
            })

            setCurrenciesOptions(currenciesTemp)
            setCustomersOptions(customersTemp)

            setReports(result)
        } catch (e) {
            console.log(e)
        }

    }

    const sortBtn = (
        <Col xs={12} md={6} style={{marginTop: windowWidth < 768 ? 30 : 15}}>
            <a href={'#'}
               style={{
                   color: Palette.COLOR_PRIMARY,
                   fontWeight: '600',
                   opacity: !isFilterExpanded ? 1 : 0,
                   height: !isFilterExpanded ? '100%' : 0
               }}
               onClick={(e) => {
                   e.preventDefault();
                   setFilterExpanded(true)
               }}>
                Sort and filter

                <FaCaretDown color={Palette.COLOR_PRIMARY} style={{marginLeft: 10}}/>
            </a>

            <div style={{
                color: Palette.COLOR_PRIMARY,
                height: isFilterExpanded ? '100%' : 0,
                opacity: isFilterExpanded ? 1 : 0,
                transition: 'opacity .15s'
            }}>
                <Row>
                    <Col xs={5} lg={4}>
                        <MdSort size={22} style={{marginRight: 15}}/>

                        <span style={{fontWeight: '600', fontSize: '1.05em'}}>Sort By</span>
                    </Col>

                    <Col style={{paddingLeft: 0}} xs={7} lg={4}>
                        <Select
                            // defaultValue={optionsArr[0]}
                            options={formattedSorterFields}
                            onChange={(obj, key) => {
                                setChosenSorterFieldOrder(obj.order)
                                setChosenSorterField(obj.sorter)
                                setChosenSorterFieldLabel(obj.label)
                            }}
                            styles={styles.select}
                            components={{DropdownIndicator, IndicatorSeparator: () => null}}
                        />
                    </Col>
                </Row>
            </div>
        </Col>
    );

    return (
        <AppContainer active={'Orders'} activeSubMenu={'Report'}>
            <Container style={{paddingBottom: 100}}>
                <TrackOrderModal show={isTrackOrderModalShown} onClose={() => setTrackOrderModalShown(false)}
                                 orders={trackedOrders}/>

                <DateRangePicker show={isDateRangePickerShown} onClose={() => setDateRangePickerShown(false)}  onDateChanged={(dateRange) => {
                    addFilter({type: 'Date', value: dateRange[0]})
                }}/>
                <Row>

                    <Col
                        lg={12}
                        style={styles.header}>
                        Report
                    </Col>

                </Row>

                <Row>
                    {
                        windowWidth < 768 ? null : sortBtn
                    }
                    <Col style={{
                        display: windowWidth < 768 ? 'block' : 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-start'
                    }}>
                        <div style={{
                            border: '1px #d4d4d4 solid',
                            borderRadius: 10,
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <FaSearch color={Palette.COLOR_PRIMARY} style={{marginRight: 20, marginLeft: 15}}/>

                            <InputBase
                                style={{fontSize: '.9em'}}
                                placeholder="Type keyword here..."
                                onChange={(e) => setSearchKeyword(e.target.value)}
                            />
                        </div>
                    </Col>

                    {
                        windowWidth < 768 ? sortBtn : null
                    }
                </Row>

                <div style={{
                    height: isFilterExpanded ? '100%' : 0,
                    opacity: isFilterExpanded ? 1 : 0,
                    transition: 'opacity .15s'
                }}>
                    <Row style={{marginTop: 20}}>
                        <Col xs={12} md={6}>

                            <div style={{color: Palette.COLOR_PRIMARY}}>
                                <Row>
                                    <Col xs={5} lg={4}>
                                        <FaFilter size={14} style={{marginRight: 23}}/>

                                        <span style={{fontWeight: '600', fontSize: '1.05em'}}>Filter By</span>
                                    </Col>

                                    <Col>
                                        <Row>
                                            <Col md={12} lg={6} style={{paddingLeft: 0, marginBottom: 10}}>
                                                <div
                                                    onMouseOver={() => setDateRangeHovered(true)}
                                                    onMouseLeave={() => setDateRangeHovered(false)}
                                                    onClick={() => setDateRangePickerShown(true)}
                                                    style={{
                                                        border: `1px ${isDateRangeHovered ? '#bababa' : '#bababa'} solid`,
                                                        width: '100%',
                                                        paddingTop: 6,
                                                        paddingBottom: 6,
                                                        borderRadius: 4,
                                                        color: '#4f4f4f',
                                                        paddingLeft: 10,
                                                        display: 'flex', alignItems: 'center',
                                                        paddingRight: 9
                                                    }}>
                                                    <span style={{flex: 1, color: 'grey', cursor: 'default'}}>
                                                        Date Range
                                                    </span>

                                                    <FaAngleDown color={'black'}/>
                                                </div>
                                            </Col>

                                            <Col md={12} lg={6} style={{paddingLeft: 0, marginBottom: 10}}>
                                                <Select
                                                    placeholder={'Currency'}
                                                    value={null}
                                                    onChange={(option) => addFilter({type: 'Currency', value: option.value})}
                                                    options={currenciesOptions}
                                                    styles={styles.select}
                                                    components={{DropdownIndicator, IndicatorSeparator: () => null}}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </Col>

                        <Col>

                            <Row>
                                {windowWidth < 768 ? <Col xs={5}/> : null}
                                <Col>
                                    <Row>

                                        <Col md={6} lg={4} style={{paddingLeft: 0, marginBottom: 10}}>
                                            <Select
                                                placeholder={'Customer'}
                                                value={null}
                                                onChange={(option) => addFilter({type: 'Customer', value: option})}
                                                options={customersOptions}
                                                styles={styles.select}
                                                components={{DropdownIndicator, IndicatorSeparator: () => null}}
                                            />
                                        </Col>

                                        {/*<Col md={6} lg={4} style={{paddingLeft: 0, marginBottom: 10}}>*/}
                                        {/*    <Select*/}
                                        {/*        placeholder={'Category'}*/}
                                        {/*        // defaultValue={optionsArr[0]}*/}
                                        {/*        options={[]}*/}
                                        {/*        styles={styles.select}*/}
                                        {/*        components={{DropdownIndicator, IndicatorSeparator: () => null}}*/}
                                        {/*    />*/}
                                        {/*</Col>*/}

                                        <Col md={6} lg={4} style={{paddingLeft: 0, marginBottom: 10}}>
                                            <Select
                                                placeholder={'Amount Range'}
                                                onChange={(option) => addFilter({type: 'Amount Range', value: option})}
                                                value={null}
                                                options={amountRangeOptions}
                                                styles={styles.select}
                                                components={{DropdownIndicator, IndicatorSeparator: () => null}}
                                            />

                                            {isAmountRangeCustom ?
                                                <DefaultTextInput
                                                    style={{marginTop: 12}}
                                                    placeholder={'Minimum Amount'}
                                                    errors={[]}
                                                    value={filteredRange.min}
                                                    onChange={(event) => {
                                                        const filtersTemp = [...filters];

                                                        filtersTemp.map((filter, idx) => {
                                                            if(filter.type === 'Amount Range' && filter.value.label === 'Custom') {
                                                                filtersTemp[idx].value.value.min = parseFloat(event.target.value.replace(/,/g, ''));
                                                            }
                                                        })

                                                        setFilters(filtersTemp);

                                                        const filteredRangeTemp = {...filteredRange};

                                                        if(event.target.value.length === 1 && event.target.value === "0") {
                                                            filteredRangeTemp.min = '';
                                                        } else {
                                                            filteredRangeTemp.min = event.target.value.replace(/\D/g, '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                                        }

                                                        setFilteredRange(filteredRangeTemp)
                                                    }}
                                                /> : null
                                            }

                                            {isAmountRangeCustom ?
                                                <DefaultTextInput
                                                    style={{marginTop: -10}}
                                                    placeholder={'Maximum Amount'}
                                                    errors={[]}
                                                    value={filteredRange.max}
                                                    onChange={(event) => {
                                                        const filtersTemp = [...filters];

                                                        filtersTemp.map((filter, idx) => {
                                                            if(filter.type === 'Amount Range' && filter.value.label === 'Custom') {
                                                                filtersTemp[idx].value.value.max = parseFloat(event.target.value.replace(/,/g, ''));
                                                            }
                                                        })

                                                        setFilters(filtersTemp);

                                                        const filteredRangeTemp = {...filteredRange};

                                                        if(event.target.value.length === 1 && event.target.value === "0") {
                                                            filteredRangeTemp.max = '';
                                                        } else {
                                                            filteredRangeTemp.max = event.target.value.replace(/\D/g, '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                                        }

                                                        setFilteredRange(filteredRangeTemp)
                                                    }}
                                                /> : null
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row style={{marginTop: 10}}>
                        <Col style={{display: 'flex', flexWrap: 'wrap'}} xs={12} md={8}>
                            {
                                chosenSorterField ?
                                    <div style={{
                                        borderRadius: 50,
                                        backgroundColor: '#e6e7e8',
                                        paddingLeft: 20,
                                        paddingTop: 8,
                                        paddingBottom: 8,
                                        display: 'flex',
                                        alignItems: 'center',
                                        paddingRight: 10,
                                        marginRight: 10,
                                        marginTop: 10
                                    }}>
                                        {chosenSorterFieldLabel}
                                        <AiOutlineClose
                                            onClick={() => {
                                                setChosenSorterFieldLabel("")
                                                setChosenSorterField(null)
                                                setChosenSorterFieldOrder(1)
                                            }}
                                            style={{
                                                marginTop: 3, marginLeft: 10, cursor: 'pointer'
                                            }}
                                        />
                                    </div>
                                    :
                                    null
                            }

                            {
                                filters.map(
                                    filter => {
                                        return (
                                            <div style={{
                                                borderRadius: 50,
                                                backgroundColor: '#e6e7e8',
                                                paddingLeft: 20,
                                                paddingTop: 8,
                                                paddingBottom: 8,
                                                display: 'flex',
                                                alignItems: 'center',
                                                paddingRight: 10,
                                                marginRight: 10,
                                                marginTop: 10
                                            }}>
                                                {filter.type}: {filter.type === 'Customer' || filter.type === 'Amount Range' || filter.type === 'Status' ? filter.value.label : filter.type === 'Date' ? moment(filter.value.startDate).format('DD/MM/YYYY') + ' - ' + moment(filter.value.endDate).format('DD/MM/YYYY') : filter.value}
                                                <AiOutlineClose
                                                    onClick={() => deleteFilter(filter)}
                                                    style={{marginTop: 3, marginLeft: 10, cursor: 'pointer'}}/>
                                            </div>
                                        )
                                    }
                                )
                            }
                        </Col>

                        <Col style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
                            <a href={'#'}
                               style={{color: Palette.COLOR_PRIMARY, fontWeight: '600'}}
                               onClick={(e) => {
                                   e.preventDefault();
                                   setFilterExpanded(false)
                               }}>
                                Collapse

                                <FaCaretUp color={Palette.COLOR_PRIMARY} style={{marginLeft: 10}}/>
                            </a>
                        </Col>
                    </Row>

                    <Row style={{
                        width: '100%',
                        height: 1,
                        backgroundColor: '#878787',
                        marginLeft: 0,
                        marginTop: 15,
                        marginBottom: 30
                    }}/>
                </div>

                <>
                    {
                        sortData(reports).map((obj,key) => {
                            return filterData(obj)
                        })
                    }

                    {/*<OrderCard*/}
                    {/*    noBtn*/}
                    {/*    windowWidth={windowWidth}*/}
                    {/*    status={'Transaction completed'}*/}
                    {/*    name={'Order # 1010102'} amount={'MYR 500,000'}/>*/}

                    {/*<OrderCard*/}
                    {/*    noBtn*/}
                    {/*    windowWidth={windowWidth}*/}
                    {/*    status={'Transaction completed'}*/}
                    {/*    name={'Order # 1010101'} amount={'SGD 5,000'}/>*/}

                    {/*<OrderCard*/}
                    {/*    noBtn*/}
                    {/*    negative*/}
                    {/*    windowWidth={windowWidth}*/}
                    {/*    status={'Transaction failed'}*/}
                    {/*    name={'Order # 1010102'} amount={'MYR 500,000'}/>*/}

                    </>
            </Container>
        </AppContainer>
    )
}
