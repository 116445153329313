import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import React, {useState} from "react";
import {withStyles} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import Palette from "../Palette";

export default function DefaultCheckBox(props) {
    const {description, right} = props;
    const [isChecked, setChecked] = useState(false);

    const GreenCheckbox = withStyles({
        root: {
            color: '#ababab',
        },
        checked: {
            color: Palette.COLOR_PRIMARY
        }
    })((props) => <Checkbox color="default" {...props} />);

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Row style={{marginBottom: 5, maxWidth: 600, width: '100%'}}>
                <Col>
                    <div
                        style={{
                            width: 'inherit',
                            marginLeft: -13,
                            fontWeight: '600',
                            color: '#a0a4a7',
                            display: 'flex',
                            alignItems: 'flex-start'
                        }}>
                        <GreenCheckbox checked={isChecked}
                                       onChange={() => {
                                           props.onChange(!isChecked)
                                           setChecked(!isChecked)
                                       }}
                        />

                        <div style={{color: '#696969', marginTop: 8}}>
                            {description}
                        </div>
                    </div>
                </Col>

                {right}
            </Row>
        </div>
    )
}
