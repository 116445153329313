import React, {useEffect, useState} from "react";

import LineIndicator from "./LineIndicator";
import CustomerDao from "../daos/CustomerDao";

const SiteAudience = () => {

    const [registeredUserStats, setRegisteredUserStats] = useState([])
    const [allCount, setAllCount] = useState(0)

    useEffect(()=>{
        getUserStats()
    },[])

    const getUserStats = async () => {
        let result = await CustomerDao.getStats();
        console.log(result)

        let tempCount = 0

        result.forEach((obj,key)=>{
            tempCount += obj.count
        })
        console.log("all count is " + tempCount)
        setAllCount(tempCount)

        setRegisteredUserStats(result)

    }

    let colors = [
        "cyan",
        "geekblue",
        "orange"
    ]

    return (
        <div className="gx-site-dash gx-mb-2 gx-pt-3 gx-pt-sm-0 gx-pt-xl-2">
            <h6 className="gx-text-uppercase gx-mb-2 gx-mb-sm-4" style={{fontSize: 12, fontFamily: 'NoirPro-Light', color: '#262626'}}>USERS REGISTERED</h6>
            <ul className="gx-line-indicator">
                {
                    registeredUserStats.map((obj,key)=>{
                        return <li>
                            <LineIndicator
                                color={colors[key%3]}
                                width={(obj.count / allCount*100) + "%"}
                                title={obj.account_type}
                                value={(Math.floor(obj.count / allCount * 100)) + "%"}/>
                        </li>
                    })
                }
            </ul>
        </div>
    )
};
export default SiteAudience;
