import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "@material-ui/core/Button";
import styles from "../styles";
import AppContainer from "./AppContainer";
import React, {useEffect, useState} from "react";
import {AiOutlineClose} from "react-icons/ai";
import CurrencyDAO from "../daos/CurrencyDAO";
import DefaultTextInput from "./DefaultTextInput";
import Select, {components} from "react-select";
import {FaAngleDown} from "react-icons/fa";
import util from "../util/util";

export default function EditCurrencyModal(props) {

    // let [currencyCode, setCurrencyCode] = useState('')
    let [buyMargin, setBuyMargin] = useState(0)
    let [sellMargin, setSellMargin] = useState(0)
    let [fee, setFee] = useState(0);

    // let [onlineRate, setOnlineRate] = useState(0)
    let [minimumTransaction, setMinimumTransaction] = useState(0);

    let [errorMessage, setErrorMessage] = useState("")

    const {
        show,
        onClose
    } = props;

    const disableScrolling = () => {
        const x = window.scrollX;
        const y = window.scrollY;
        window.onscroll = function () {
            window.scrollTo(x, y);
        };
    }

    const enableScrolling = () => {
        window.onscroll = function () {
        };
    }

    useEffect(() => {
        console.log("this is called", props.currency?.margin_buy)

        setBuyMargin(props.currency?.margin_buy * 100)
        setSellMargin(props.currency?.margin_sell * 100)
        setFee(props.currency?.fee)
        setMinimumTransaction(props.currency?.minimum_transaction)
        // let [buyMargin, setBuyMargin] = useState(0)
        // let [sellMargin, setSellMargin] = useState(0)
        // let [fee, setFee] = useState(0);

    }, [props.currency])

    useEffect(() => {
        if (show) disableScrolling()
        else enableScrolling()
    }, [show])

    // useEffect(() => {
    //     setBuyMargin(0)
    //     setSellMargin(0)
    //     setFee(0)
    //
    // }, [props.show])

    const submit = async () => {

        setErrorMessage("")

        if(isNaN(buyMargin)){
            return setErrorMessage("Please enter a valid buy margin")
        }

        if(isNaN(sellMargin)){
            return setErrorMessage("Please enter a valid sell margin")
        }

        if(isNaN(fee)){
            return setErrorMessage("Please enter a valid fee")
        }

        try {
            let result = await CurrencyDAO.update(props.currency?.currency_code, {
                rate : props.currency?.rate,
                margin_buy : buyMargin / 100,
                margin_sell : sellMargin / 100,
                fee : fee,
                minimum_transaction : minimumTransaction
            })
            console.log(result)
            onClose()

        } catch (e) {
            console.log(e)
        }
    }

    const onKeyPress = (ev) => {
        if (ev.key === 'Enter') {
            ev.preventDefault();

            submit()
        }
    }

    const DropdownIndicator = props => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <FaAngleDown color={'black'}/>
                </components.DropdownIndicator>
            )
        );
    };

    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                right: 0,
                width: '100%',
                minHeight: '100vh',
                zIndex: show ? 2 : -1,
                backgroundColor: '#00000059',
                opacity: show ? 1 : 0,
                transition: 'zIndex .2s'
            }}>
            <Container
                style={{minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Row style={{
                    backgroundColor: '#f5f5f5',
                    boxShadow: '0px 4px 5px 0px rgba(0,0,0,0.3)',
                    width: '100%',
                    maxWidth: 360,
                    borderRadius: 15,
                    paddingBottom: 20
                }}>
                    <Row style={{width: '100%', marginTop: 30, marginLeft: 15, marginRight: 15, marginBottom: 40}}>
                        <Col xs={9} style={{fontSize: '1.2em', fontFamily: 'Roboto', fontWeight: '400'}}>
                            Edit Currency
                        </Col>

                        <Col style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <AiOutlineClose
                                style={{cursor: 'pointer'}} size={27} onClick={onClose}/>
                        </Col>
                    </Row>

                    <Row style={{width: '100%', marginLeft: 15, marginRight: 15}}>
                        <Col xs={6} style={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: 0,
                            marginBottom: 10,
                            fontFamily: 'Roboto',
                            fontWeight: '400'
                        }}>
                            Currency Code
                        </Col>
                        <Col style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <div style={{
                                width : 100,
                                textAlign : "center"
                            }}>
                                {props.currency?.currency_code}
                            </div>

                            {/*<Form.Control*/}
                            {/*    type={'tel'}*/}
                            {/*    onKeyPress={onKeyPress}*/}
                            {/*    value={buyMargin}*/}
                            {/*    onChange={(e) => {*/}

                            {/*    }}*/}
                            {/*    style={{*/}
                            {/*        fontSize: "1em",*/}
                            {/*        borderColor: '#e6e6e6',*/}
                            {/*        maxWidth: 100,*/}
                            {/*        textAlign: 'center',*/}
                            {/*        color: 'grey',*/}
                            {/*        fontFamily: 'Roboto',*/}
                            {/*        fontWeight: '500'*/}
                            {/*    }}/>*/}
                        </Col>
                    </Row>

                    <Row style={{width: '100%', marginLeft: 15, marginRight: 15}}>
                        <Col xs={6} style={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: 0,
                            marginBottom: 10,
                            fontFamily: 'Roboto',
                            fontWeight: '400'
                        }}>
                            Online Rate
                        </Col>
                        <Col style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <div
                                style={{
                                    fontSize: "1em",
                                    borderColor: '#e6e6e6',
                                    maxWidth: 100,
                                    textAlign: 'center',
                                    color: 'grey',
                                    fontFamily: 'Roboto',
                                    fontWeight: '500',
                                    width : 100
                                }}>
                                <div style={{
                                    width : 100,
                                    textAlign : "center"
                                }}>
                                    {props.currency?.rate?.toFixed(6)}
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row style={{width: '100%', marginLeft: 15, marginRight: 15, marginTop: 15}}>
                        <Col xs={6} style={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: 0,
                            marginBottom: 10,
                            fontFamily: 'Roboto',
                            fontWeight: '400'
                        }}>
                            Buy Margin
                        </Col>
                        <Col style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Form.Control
                                type={'tel'}
                                onKeyPress={onKeyPress}
                                value={buyMargin}
                                onChange={(e) => {
                                    setBuyMargin(e.target.value)
                                }}
                                placeholder={0}
                                style={{
                                    fontSize: "1em",
                                    borderColor: '#e6e6e6',
                                    maxWidth: 100,
                                    textAlign: 'center',
                                    color: 'grey',
                                    fontFamily: 'Roboto',
                                    fontWeight: '500'
                                }}/>
                        </Col>
                    </Row>

                    <Row style={{width: '100%', marginLeft: 15, marginRight: 15, marginTop: 15}}>
                        <Col xs={6} style={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: 0,
                            marginBottom: 10,
                            fontFamily: 'Roboto',
                            fontWeight: '400'
                        }}>
                            Sell Margin
                        </Col>
                        <Col style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Form.Control
                                type={'tel'}
                                onKeyPress={onKeyPress}
                                value={sellMargin}
                                onChange={(e) => {
                                    setSellMargin(e.target.value)
                                }}
                                style={{
                                    fontSize: "1em",
                                    borderColor: '#e6e6e6',
                                    maxWidth: 100,
                                    textAlign: 'center',
                                    color: 'grey',
                                    fontFamily: 'Roboto',
                                    fontWeight: '500'
                                }}/>
                        </Col>
                    </Row>

                    <Row style={{width: '100%', marginLeft: 15, marginRight: 15, marginTop: 15}}>
                        <Col xs={6} style={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: 0,
                            marginBottom: 10,
                            fontFamily: 'Roboto',
                            fontWeight: '400'
                        }}>
                            Fee
                        </Col>
                        <Col style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Form.Control
                                type={'tel'}
                                onKeyPress={onKeyPress}
                                value={fee}
                                onChange={(e) => {
                                    setFee(e.target.value)
                                }}
                                style={{
                                    fontSize: "1em",
                                    borderColor: '#e6e6e6',
                                    maxWidth: 100,
                                    textAlign: 'center',
                                    color: 'grey',
                                    fontFamily: 'Roboto',
                                    fontWeight: '500'
                                }}/>
                        </Col>
                    </Row>

                    <Row style={{width: '100%', marginLeft: 15, marginRight: 15, marginTop: 15}}>
                        <Col xs={6} style={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: 0,
                            marginBottom: 10,
                            fontFamily: 'Roboto',
                            fontWeight: '400'
                        }}>
                            Minimum Transaction
                        </Col>
                        <Col style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Form.Control
                                type={'tel'}
                                onKeyPress={onKeyPress}
                                value={minimumTransaction}
                                onChange={(e) => {
                                    setMinimumTransaction(e.target.value)
                                }}
                                style={{
                                    fontSize: "1em",
                                    borderColor: '#e6e6e6',
                                    maxWidth: 100,
                                    textAlign: 'center',
                                    color: 'grey',
                                    fontFamily: 'Roboto',
                                    fontWeight: '500'
                                }}/>
                        </Col>
                    </Row>

                    <Row style={{width: '100%', marginLeft: 15, marginRight: 15, marginTop: 15}}>
                        <Col xs={12} style={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: 0,
                            marginBottom: 10,
                            fontFamily: 'Roboto',
                            fontWeight: '400',
                            color : "red"
                        }}>
                            {errorMessage}
                        </Col>
                    </Row>


                    <Row style={{width: '100%', marginTop: 30}}>
                        <Col style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button onClick={() => {
                                submit()
                            }} style={{...styles.button}}>
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </Row>
            </Container>
        </div>
    )
}
